import { AppBar, Box, CircularProgress, Container, Hidden, makeStyles, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useMqXS } from '../../../../utils/Utils';
import AKonfigPDFButton from '../../../PDF/PDF';
import BackToList from '../../../UI/ProductSelectorActionButtons/BackToList';
import ConfirmMaterial from '../../../UI/ProductSelectorActionButtons/ConfirmMaterial';
import InfoLegend from '../../../UI/ProductSelectorActionButtons/InfoLegend';
import ToggleAllSpecs from '../../../UI/ProductSelectorActionButtons/ToggleAllSpecs';
import SpecsList from './SpecsList/SpecsList';

const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: `1px solid ${theme.palette.standard.main}`,
        position: "sticky",
        backgroundColor: "white", 
        
        
    },
    divider: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
       
    },
    progressPDF: {
        color: theme.palette.accent.main
    }
}));

const ProductDetails = (props) => {

    const styles = useStyles();
    const mq_xs = useMqXS();

    const handleClickBack = () => {
        props.backToList();
    }

    const handleAllSpecs = () => {
        props.toggleAllSpecs();
    }

    const handleClickSelect = () => {
        props.selectMaterial();
    }

    const listGoto = useSelector(state => {
        return state.productSelector.lists.goTo.data;
    });

    const material_selected = useSelector(state => {
        return state.productSelector.material;
    });

    const material_data = listGoto.filter(m => m.material === material_selected.code).pop();

    return (
        <div>

            <Hidden smDown>
                <AppBar classes={{root: styles.appBar}} color="transparent" elevation={0} position="fixed">
                    <Toolbar position="relative">
                        <Container maxWidth="lg" disableGutters={mq_xs} >
                            <Box display={'flex'} alignItems={'center'}>
                            <Box mr={1}>
                                <BackToList backToList={handleClickBack} />
                            </Box>
                            <Box mx={1}>
                                <Typography color="primary" variant="body1">
                                    {props.materialPDFData.code} &middot; {props.materialPDFData.description}
                                    {props.listType === 'goTo'
                                        ?   <span> &middot; Qty max: {material_data?.qty_max} &middot; Lead time: {material_data?.lead_time}</span>
                                        :   null
                                    }
                                </Typography>
                            </Box>
                            {props.listType !== 'customer'
                                ?   <Box mx={1}>
                                        <ConfirmMaterial confirmMaterial={handleClickSelect} />
                                    </Box>
                                :   null
                            }
                            <Box mx={1}>
                            <ToggleAllSpecs 
                                allSpecs={props.allSpecs}
                                toggleAllSpecs={handleAllSpecs}
                            />
                            </Box>
                            <Box mx={1}>
                            {props.materialPDFData.specsList
                                ? <AKonfigPDFButton type="mobile" data={props.materialPDFData} />
                                : <CircularProgress classes={{root: styles.progressPDF}} />
                            }
                            </Box>
                            <Box mx={1}>
                                <InfoLegend />
                            </Box>
                            </Box>
                        </Container>
                    </Toolbar>
                </AppBar>
            </Hidden>

            <Hidden mdUp>
                <AppBar classes={{root: styles.appBar}} color="transparent" elevation={0} position="relative">
                    <Toolbar>
                        <Typography color="primary" variant="body1">
                            {props.materialPDFData.code} &middot; {props.materialPDFData.description}
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Hidden>

            <Container maxWidth="lg" disableGutters={mq_xs}>
                <SpecsList 
                    allSpecs={props.allSpecs}
                    data={props.materialPDFData.specsList}
                />
            </Container>
        </div>
    )
}

export default ProductDetails;