import { Box, Card, CardActionArea, CardActions, CardContent, makeStyles, Typography } from '@material-ui/core';
import { HistoryOutlined, PersonOutlineOutlined } from '@material-ui/icons';
import React from 'react';
import { priceFormatter, useMqXS } from '../../../../utils/Utils';
import { useHistory } from 'react-router';


const useStyles = makeStyles(theme => ({
    ProductCard: {
        border: `1px solid ${theme.palette.primary25.main}`
    },
    CardActions: {
        borderTop: `1px solid ${theme.palette.primary25.main}`,
        backgroundColor: theme.palette.default25.main,
        flexDirection: 'column',
        alignContent: 'flex-start',
        alignItems: 'flex-start'

    }
}));

const ArchiveListItem = (props) => {

    const styles = useStyles();
    const mq_xs = useMqXS();
    const history = useHistory();

    const handleClick = () => {
        history.push('/configuration/' + props.data.rdre + '/' + props.data.size + '/summary/' + props.data.id + '/archive')
    }

    const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
        timeZone: "Europe/Rome",
    };

    const username = props.data.user_created_on.split('@');


    return (
        <Card elevation={2} classes={{ root: styles.ProductCard }}>
            <CardActionArea onClick={handleClick}>
                <Box py={4} borderBottom={'1px solid #e5e5e5'} display={'flex'} justifyContent={'center'}>

                    <img
                        alt={props.data.description}
                        style={{
                            height: '10vh',
                            backgroundSize: 'contain'
                        }}
                        src={`./products_images/${props.data.rdre}.jpg`}
                        title={props.data.description}
                    />
                </Box>
                <CardContent>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h6" color="primary">
                            {props.data.name}
                        </Typography>
                    </div>
                    <Box mt={mq_xs ? 0 : 1}>
                        <Typography
                            variant="body1"
                            color={"inherit"}
                        >
                            {props?.data?.generated_code?.replace(/%/g, '')}
                        </Typography>
                        {props.data.material
                            ? <Typography
                                variant="body1"
                                color={"inherit"}
                            >
                                {props.data.material}
                            </Typography>
                            : null
                        }
                        <Typography
                            variant="body1"
                            color={"inherit"}
                        >
                            {priceFormatter(props.data.final_price, true, true)}
                        </Typography>
                    </Box>
                </CardContent>
                <CardActions classes={{ root: styles.CardActions }}>
                    <Box display={'flex'} alignItems={'center'}>
                        <PersonOutlineOutlined fontSize="small" />
                        <Typography variant="subtitle2">{username[0]}</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} m={0}>
                        <HistoryOutlined fontSize="small" />
                        <Typography variant="subtitle2"> {new Intl.DateTimeFormat("en-GB", options).format(new Date(props.data.last_edit))}</Typography>
                    </Box>
                </CardActions>
            </CardActionArea>
        </Card>
    )

}

export default ArchiveListItem