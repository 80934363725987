import React from 'react'

const LayoutCountryFilter = (props) => {

    return (
        <>


        
            {props.country_filter.indexOf(props.country) > -1

                ?      null
                :   <>
                        {props.children}
                    </>

            } 
   
        </>
    )
}

export default LayoutCountryFilter