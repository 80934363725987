import React, { useState, useEffect } from "react";
import { Box, Container, Card, CardContent } from "@material-ui/core";
import SectionTitle from "../../../../../../../components/UI/SectionTitle/SectionTitle";
import { useMqXS } from "../../../../../../../utils/Utils";
import clsx from "clsx";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { SET_CUSTOMER_DIRECT_FLAG } from "../../../../../../../store/actions/actions";

import {
    makeStyles,
    Typography,
    TextField,
    Grid,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { SET_EXISTING_CODE } from "../../../../../../../store/actions/actions";
import { Alert } from "@material-ui/lab";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: "40%",
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: "30px 35px",
    },
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    icon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
        },
    },
    checkedIcon: {
        backgroundColor: "#137cbd",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
            content: '""',
        },
        "input:hover ~ &": {
            backgroundColor: "#106ba3",
        },
    },
}));

// Inspired by blueprintjs
function StyledRadio({ value, ...props }) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
            checked={props.checked}
            value={value}
        />
    );
}

const FlagDirect = ({ readonly }) => {

    const mq_xs = useMqXS();
    const dispatch = useDispatch();
    const flagFromRedux = useSelector((state) => {
        return state?.configuration?.discount?.is_direct;
    });
    console.log('flagFromRedux', flagFromRedux);
    const [radioValue, setRadioValue] = useState(true);



    // console.log(existingCode);
    // console.log("verifica", radioValue)



    // useEffect(() => {
    //     if (!readonly) {
    //         if (
    //             materialSelected?.description &&
    //             materialSelected?.description === modelCode?.replace(/%/g, "")
    //         ) {
    //             //console.log('entrooos');
    //             if (existingCodeFromRedux === "") {
    //                 // console.log("verifica", radioValue)
    //                 dispatch({ type: SET_EXISTING_CODE, value: materialSelected?.code });
    //                 setExistingCode(materialSelected?.code);
    //             } else {
    //                 setExistingCode(existingCodeFromRedux);
    //             }
    //             setRadioValue("existing");
    //         } else {
    //             setRadioValue("new");
    //             setExistingCode("");
    //             dispatch({ type: SET_EXISTING_CODE, value: "" });
    //         }
    //     }
    // }, [materialSelected, readonly]);

    useEffect(() => {


        setRadioValue(flagFromRedux);


    }, [flagFromRedux]);

    return (
        <Container disableGutters={!mq_xs}>
            <Box py={2} px={mq_xs ? 0 : 1}>

                <Box mt={2}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={4}>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    style={{ flexDirection: "row", flexWrap: "nowrap" }}
                                    value={radioValue}
                                    onChange={(e) => {
                                        setRadioValue(JSON.parse(e.target.value));
                                        dispatch({ type: SET_CUSTOMER_DIRECT_FLAG, value: JSON.parse(e.target.value) });
                                    }}
                                    aria-label="flag"
                                    name="customized-radios"
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<StyledRadio />}
                                        label="Direct"
                                        disabled={readonly}
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<StyledRadio />}
                                        label="Indirect"
                                        disabled={readonly}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};

export default FlagDirect;
