import { Box, Card, CardContent, Grid, Hidden, makeStyles, Typography } from '@material-ui/core';
import { HistoryOutlined, LinkOutlined, PersonOutlineOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useSelector } from 'react-redux';
import { formatPriceValue, useMqXS } from '../../../../../utils/Utils';
import ErrorBoundary from '../../../../../components/UI/ErrorBoundary/ErrorBoundary';
import LoadingBar from '../../../../../components/UI/LoadingBar/LoadingBar';

const useStyles = makeStyles(theme => ({
    Title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    Image: {
        width: theme.spacing(30),
        [theme.breakpoints.down("sm")]: {
            width: theme.spacing(20)
        },
        backgroundSize: "contain",
        margin: theme.spacing(1),
    }
}));

const SummaryHeader = ({ isMobile, archiveInfo, finalPrice, loading, error, archive, product, model_code, params, project }) => {



    const mq_xs = useMqXS();
    const styles = useStyles();

    const configuration_name = useSelector(state => {
        return state?.configuration?.product?.name
    });


    const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
        timeZone: "Europe/Rome",
    };

    const username = archiveInfo?.user_created_on
        ? archiveInfo?.user_created_on?.split('@')
        : null

    return (
        <Box p={mq_xs ? 2 : 4}>
            {username
                ? <Box mb={1}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box display={'flex'} alignItems={'center'}>
                            <PersonOutlineOutlined />
                            <Typography
                                color="inherit"
                                variant={mq_xs ? "body2" : "body1"}
                            >
                                {username[0]}
                            </Typography>
                        </Box>
                        <Box display={'flex'} alignItems={'center'}>
                            <HistoryOutlined />
                            <Typography
                                color="inherit"
                                variant={mq_xs ? "body2" : "body1"}
                            >
                                {new Intl.DateTimeFormat("en-GB", options).format(new Date(archiveInfo?.last_edit))}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                : null
            }
            <Box mb={mq_xs ? 3 : 6} px={2} py={1} classes={{ root: styles.Title }}>
                <Typography
                    align="center"
                    color="inherit"
                    variant={mq_xs ? "body1" : "h5"}
                >
                    {configuration_name
                        ? configuration_name
                        : 'Your configuration'
                    }
                </Typography>
            </Box>
            <Grid container spacing={mq_xs ? 2 : 4}>
                <Grid item md={4} xs={12}>

                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <img
                            alt={product?.product_name}
                            className={styles.Image}
                            src={`./products_images/${params?.rdre}.jpg`}
                            title={product?.product_name}
                        />
                    </Box>

                </Grid>
                <Grid item md={4} xs={12}>
                    <Box mb={2}>
                        <Typography component="p" variant="body2" color="initial">
                            Model code
                        </Typography>
                        <Typography component="p" variant="body1" color="primary">
                            {model_code?.replace(/%/g, '')}
                        </Typography>
                    </Box>
                    {product?.material
                        ? <Box mb={2}>
                            <Box>
                                <Typography component="p" variant="body2" color="initial">
                                    Material
                                </Typography>
                                <Typography component="p" variant="body1" color="primary">
                                    {product?.material}
                                </Typography>
                            </Box>
                            {!archive
                                ? (model_code !== product?.material_desc)
                                    ? <Box mt={1}>
                                        <Alert severity="warning">
                                            <Typography variant="body1" component="p" color="inherit">
                                                this code will be removed if you update the configuration
                                            </Typography>
                                        </Alert>
                                    </Box>
                                    : null
                                : null
                            }
                        </Box>
                        : null
                    }
                    <Box mb={2}>
                        <Typography component="p" variant="body2" color="initial">
                            Family
                        </Typography>
                        <Typography component="p" variant="body1" color="primary">
                            {product?.family}
                        </Typography>
                    </Box>
                    <Box mb={2}>
                        <Typography component="p" variant="body2" color="initial">
                            Product
                        </Typography>
                        <Typography component="p" variant="body1" color="primary">
                            {product?.product_name}{product?.operating_mode} &middot; {product?.series}
                        </Typography>
                    </Box>
                    <Box mb={2}>
                        <Typography component="p" variant="body2" color="initial">
                            Size
                        </Typography>
                        <Typography component="p" variant="body1" color="primary">
                            {params?.size}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={isMobile ? 12 : 4}>
                    <Box mb={2}>
                        <Card
                            elevation={0}
                            style={{ backgroundColor: "#002b49", color: "#fff" }}
                        >
                            <CardContent
                                style={{
                                    height: isMobile ? 'auto' : "160px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: isMobile ? '8px' : ''
                                }}
                            >
                                <Box>
                                    <Typography align="center" variant="h6">
                                        Final price
                                    </Typography>
                                    <ErrorBoundary error={error}>
                                        {loading
                                            ? <LoadingBar light />
                                            : <Typography align="center" variant="h5">
                                                {formatPriceValue(finalPrice)}
                                            </Typography>
                                        }
                                    </ErrorBoundary>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Alert severity="warning">
                        The price information provided by AKonfig portal does not constitute a binding quotation but has to be intended as a mere indication of price, without commitment to follow up on technical and / or economic services. The sales contract will be considered valid and effective only under the conditions reported on the order confirmation issued by Bosch Rexroth S.p.A.
                    </Alert>
                </Grid>
                {project
                    ? <Grid item xs={12}>
                        <Box className="d-flex align-center">
                            <Box className="d-flex" mr={1}>
                                <LinkOutlined color="primary" />
                            </Box>
                            <Typography variant="body1" color="primary">
                                {project}
                            </Typography>
                        </Box>
                    </Grid>
                    : null
                }
            </Grid>
        </Box>
    )

}

export default SummaryHeader;