import { Typography, Box, useTheme } from '@material-ui/core';
import React from 'react';
import { useCheckAuthorization, useMqXS } from '../../../utils/Utils';
import { useCountry } from '../../../utils/useCountry';


const AuthorizationTitle = () => {

    const theme = useTheme();
    const mq_xs = useMqXS();
    const country = useCountry();
    const auth_level = useCheckAuthorization(country);

    let authorization = '';

    switch (auth_level) {
        case 0:
            authorization = 'None';
            break;
        case 1:
            authorization = 'Cluster manager';
            break;
        case 2:
            authorization = 'SLM1 o PM';
            break;
        case 3:
            authorization = 'Branch';
            break;
        case 4:
            authorization = 'Manager';
            break;
        case 5:
            authorization = 'Internal Sales';
            break;
        case 6:
            authorization = 'Sales Eng';
            break;
        case 7:
            authorization = 'Sales Mngr';
            break;
        case 8:
            authorization = 'General Mngr';
            break;
        default:
            authorization = 'Branch';
            break;
    }

    return (
        <Box
            py={1}
            px={2}
            style={{
                backgroundColor: theme.palette.secondary.main
            }}
        >
            <Typography variant={mq_xs ? "body1" : "h6"} color="primary">
                {mq_xs
                    ? "Auth: "
                    : "Authorization: "
                }
                {authorization}
            </Typography>
        </Box>
    )

}

export default AuthorizationTitle;