
import { Box, Card, CardContent, Table, TableBody, TableRow, TableCell, Typography, makeStyles } from '@material-ui/core';
import SectionTitle from '../../../UI/SectionTitle/SectionTitle';
import { checkValue, countryFilters, formatPercentage, formatPriceValue, useCheckAuthorization, useMqXS } from '../../../../utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react'
import { UPDATE_ADMIN_VIEW } from '../../../../store/actions/actions';
import { Alert } from "@material-ui/lab";
import LayoutCountryFilter from '../../../UI/LayoutCustomer/LayoutCountryFilter';


const useStyles = makeStyles(theme => ({
	FirstColumn: {
		width: '50%',
		borderRight: `1px solid ${theme.palette.primary25.main}`,
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1)
		}
	},
	TableCell: {
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1)
		}
	}
}));

const AdminView = ({ country, mergedResults }) => {

	const dispatch = useDispatch();
	const mq_xs = useMqXS();
	const styles = useStyles();


	const [discountedPrice, setDiscountedPrice] = useState(0);
	const base_price = useSelector(state => {
		return state?.configuration?.product?.base_price
	});

	const sales_net_price = useSelector(state => {
		return state.configuration?.calculatedDiscount?.sales_net_price
	});
	const extra_discounted_price = useSelector(state => {
		return state.configuration?.calculatedDiscount?.extra_discounted_price
	});

	const discount_perc_rdre = useSelector(state => {
		return state?.configuration?.discount?.discount_perc_rdre
	});



	const cluster_manager_limit_price = useSelector(state => {
		return state?.configuration?.discount?.cluster_manager_limit_price
	});

	//DCIT Limit
	const product_manager_limit_price = useSelector(state => {
		return state?.configuration?.discount?.product_manager_limit_price
	});

	//Margine IT per calcolo
	const margin_perc_country_unit = useSelector(state => {
		return state?.configuration?.discount?.margin_perc_country_unit
	});

	const sdd = useSelector(state => {
		return state?.configuration?.discount?.sdd
	});

	const markup = useSelector(state => {
		return state?.configuration?.discount?.markup
	});

	const grid_cost = useSelector(state => {
		return state?.configuration?.discount?.grid_cost
	});

	const factor = useSelector(state => {
		return state?.configuration?.discount?.factor
	});

	const tp_calc = useSelector(state => {
		return state?.configuration?.discount?.tp_calc
	});

	const margin_it = useSelector(state => {
		return state?.configuration?.discount?.margin_it
	});

	const margin_perc = useSelector(state => {
		return state?.configuration?.discount?.margin_perc
	});

	const p_min = useSelector(state => {
		return state?.configuration?.discount?.p_min
	});





	const country_filters = countryFilters;

	const updateAdminView = useCallback((objData) => dispatch({ type: UPDATE_ADMIN_VIEW, value: objData.value }), [dispatch]);

	// const listGross = storeSpecsSelected.filter(spec => spec.price > 0 && spec.is_gross);
	// const listAccGross = storeAccessoriesSelected.filter(acc => acc.price > 0 && acc.is_gross);
	const listGross = mergedResults.filter(e => e.value && e?.value?.price > 0 && e?.value?.is_gross)
	useEffect(() => {

		if (!extra_discounted_price) {
			setDiscountedPrice(sales_net_price)
		} else {
			setDiscountedPrice(extra_discounted_price)
		}

	}, [sales_net_price]);

	useEffect(() => {

		const totalGross = base_price + listGross.reduce((sum, val) => sum + +val?.value?.price, 0)

		// + listGross.reduce((sum, val) => sum + +val.price, 0)
		// + listAccGross.reduce((sum, val) => sum + +val.price, 0);

		const margin_it = Math.round((1 - margin_perc_country_unit) * 10000) / 10000;
		const factor = Math.round((1 - discount_perc_rdre) * 10000) / 10000;
		const grid_cost = Math.round((totalGross * factor) * 100) / 100;
		const sdd = Math.round(((totalGross * factor) / margin_it) * 100) / 100;
		const tp_calc = sales_net_price * margin_it;
		const markup = (discountedPrice / grid_cost) - 1;
		const p_min = Math.floor(cluster_manager_limit_price - 0.01);
		const margin_perc = (discountedPrice - grid_cost) / discountedPrice;

		const body = {
			margin_it: checkValue(margin_it),
			factor: checkValue(factor),
			grid_cost: checkValue(grid_cost),
			sdd: checkValue(sdd),
			tp_calc: checkValue(tp_calc),
			markup: checkValue(markup),
			p_min: checkValue(p_min),
			margin_perc: checkValue(margin_perc)
		}

		updateAdminView({ value: body });

	}, [discountedPrice, cluster_manager_limit_price, updateAdminView]);

	console.log('tiritera', extra_discounted_price, sales_net_price, margin_it);

	return (
		<div style={{ width: '100%' }}>


			<Box py={2} px={mq_xs ? 0 : 2}>

				<SectionTitle title="Admin View" />

				<Card elevation={0} style={{ border: '2px solid #d1dce3', borderRadius: 0 }}>
					<CardContent>
						<Box mt={2}>
							<LayoutCountryFilter
								country={country}
								country_filter={country_filters}
							>
								<Table>
									<TableBody>
										<TableRow>
											<TableCell classes={{ root: styles.FirstColumn }}>
												<Typography variant="body1" color="primary">
													SDD Price
												</Typography>
											</TableCell>
											<TableCell classes={{ root: styles.TableCell }}>
												<Typography
													variant="body1" color="primary">
													{formatPriceValue(sdd)}
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell classes={{ root: styles.FirstColumn }}>
												<Typography variant="body1" color="primary">
													Markup %
												</Typography>
											</TableCell>
											<TableCell classes={{ root: styles.TableCell }}>
												<Typography variant="body1" color="primary">
													{formatPercentage(markup)}
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell classes={{ root: styles.FirstColumn }}>
												<Typography variant="body1" color="primary">
													DCIT Limit
												</Typography>
											</TableCell>
											<TableCell classes={{ root: styles.TableCell }}>
												<Typography variant="body1" color="primary">
													{formatPriceValue(product_manager_limit_price)}
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell classes={{ root: styles.FirstColumn }}>
												<Typography variant="body1" color="primary">
													Costo griglia BU-DCIT
												</Typography>
											</TableCell>
											<TableCell classes={{ root: styles.TableCell }}>
												<Typography variant="body1" color="primary">
													{formatPriceValue(grid_cost)}
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell classes={{ root: styles.FirstColumn }}>
												<Typography variant="body1" color="primary">
													Factor
												</Typography>
											</TableCell>
											<TableCell classes={{ root: styles.TableCell }}>
												<Typography
													variant="body1" color="primary">{formatPercentage(factor)}
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell classes={{ root: styles.FirstColumn }}>
												<Typography variant="body1" color="primary">
													Perc sconto %
												</Typography>
											</TableCell>
											<TableCell classes={{ root: styles.TableCell }}>
												<Typography variant="body1" color="primary">
													{formatPercentage(discount_perc_rdre)}
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell classes={{ root: styles.FirstColumn }}>
												<Typography variant="body1" color="primary">
													TP Calcolato
												</Typography>
											</TableCell>
											<TableCell classes={{ root: styles.TableCell }}>
												<Typography variant="body1" color="primary">
													{formatPriceValue(tp_calc)}
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell classes={{ root: styles.FirstColumn }}>
												<Typography variant="body1" color="primary">
													{country === 'IT'
														? 'Margine IT'
														: 'Transfer price discount'
													}
												</Typography>
											</TableCell>
											<TableCell classes={{ root: styles.TableCell }}>
												<Typography variant="body1" color="primary">
													{formatPercentage(margin_it)}
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell classes={{ root: styles.FirstColumn }}>
												<Typography variant="body1" color="primary">
													{country === 'IT'
														? 'Margine IT per calcolo'
														: 'Transfer price factor'
													}
												</Typography>
											</TableCell>
											<TableCell classes={{ root: styles.TableCell }}>
												<Typography variant="body1" color="primary">
													{formatPercentage(margin_perc_country_unit)}
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell classes={{ root: styles.FirstColumn }}>
												<Typography variant="body1" color="primary">
													Margin %
												</Typography>
											</TableCell>
											<TableCell classes={{ root: styles.TableCell }}>
												<Typography variant="body1" color="primary">
													{formatPercentage(margin_perc)}
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell classes={{ root: styles.FirstColumn }}>
												<Typography variant="body1" color="primary">
													PMIN
												</Typography>
											</TableCell>
											<TableCell classes={{ root: styles.TableCell }}>
												<Typography variant="body1" color="primary">
													{formatPriceValue(p_min)}
												</Typography>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</LayoutCountryFilter>
							<LayoutCountryFilter
								country={country}
								country_filter={['IT']}
							>
								<Table>
									<TableBody>
										<TableRow>
											<TableCell classes={{ root: styles.FirstColumn }}>
												<Typography variant="body1" color="primary">
													{country === 'IT'
														? 'Margine IT'
														: 'Transfer price discount'
													}
												</Typography>
											</TableCell>
											<TableCell classes={{ root: styles.TableCell }}>
												<Typography variant="body1" color="primary">
													{formatPercentage(margin_perc_country_unit)}

												</Typography>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell classes={{ root: styles.FirstColumn }}>
												<Typography variant="body1" color="primary">
													{country === 'IT'
														? 'Margine IT per calcolo'
														: 'Transfer price factor'
													}
												</Typography>
											</TableCell>
											<TableCell classes={{ root: styles.TableCell }}>
												<Typography variant="body1" color="primary">
													{formatPercentage(margin_it)}
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell classes={{ root: styles.FirstColumn }}>
												<Typography variant="body1" color="primary">
													Transfert Price
												</Typography>
											</TableCell>
											<TableCell classes={{ root: styles.TableCell }}>
												<Typography variant="body1" color="primary">
													{formatPriceValue(margin_it * (extra_discounted_price || sales_net_price))}
												</Typography>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</LayoutCountryFilter>
						</Box>

						<LayoutCountryFilter
							country={country}
							country_filter={country_filters}
						>
							<Box mt={2}>
								<Alert severity="info">
									{(extra_discounted_price ? extra_discounted_price : sales_net_price) >
										(sdd - 0.01) ? (
										<span>GRIGLIA SCONTO - Gross discount</span>
									) : (
										<span>
											PREZZO VENDITA - {formatPercentage(discount_perc_rdre)} Agreed net price
										</span>
									)
									}
									{/* {auth_level !== 3 ? (
										<span>GRIGLIA SCONTO - Grid discount</span>
									) : (
										<span>
											PREZZO VENDITA - {formatPercentage(discount_perc_rdre)} Agreed net price
										</span>
									)
									} */}
								</Alert>
							</Box>
						</LayoutCountryFilter>

					</CardContent>
				</Card>

			</Box>


		</div>
	)
}

export default AdminView;
