import { List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import RexrothGIF from '../../../assets/media/rexroth.gif';

const useStyles = makeStyles(theme => ({
    list: {
        marginTop: 'auto'
    },
    listItem: {
        display: 'flex',
        justifyContent: 'center'
    }
}))

const NavMenuLogo = () => {

    const styles = useStyles();

    return (
        <List
            classes={{root: styles.list}}
        >
            <ListItem
                classes={{root: styles.listItem}}
            >
                <img
                    alt="Rexroth"
                  src={RexrothGIF}
                  height={40}
                />
            </ListItem>
        </List>
    );
};

export default NavMenuLogo;
