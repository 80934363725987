import axios from 'axios';
import jwt_decode from 'jwt-decode';

let debug_flag = true;
let debug_flag_detail = false;

let refreshingToken = false;
let tokenPromise = null;
let currentToken = null;
let interceptorAdded = false;

const axiosInstance = axios.create({
    baseURL: 'api/',
    withCredentials: true,
});

export const useAxios = () => {


    async function getNewToken(token, req) {

        // if (debug_flag_detail) console.log(req.url, " - faccio chiamata per il refresh");

        const rs = await axios.post('api/security/refreshtoken', {
            refresh_token: token.refresh_token,
        });

        // if (debug_flag_detail) console.log(req.url, " - token refreshato");

        // if (debug_flag_detail) console.log(req.url, " - inizio lavorazione response refresh token");

        const new_tokens = rs.data;

        // if (debug_flag_detail) console.log(req.url, " - fine lavorazione response refresh token");

        return new_tokens;
    }

    async function refreshToken(token, req) {


        if (refreshingToken) {
            // if (debug_flag) console.log(req.url, " - Refresh token in corso da altri, attendo");
            await tokenPromise;
            // if (debug_flag) console.log(req.url, " - token ok, proseguo");
            return currentToken;
        }

        refreshingToken = true;
        // if (debug_flag_detail) console.log(req.url, " - ho messo a true");

        // Crea una nuova promessa per il processo di refresh
        tokenPromise = (async () => {
            // if (debug_flag_detail) console.log(req.url, " - inizio promise");

            // Verifica se il token è scaduto
            const decodedToken = await jwt_decode(sessionStorage.getItem("access_token"));
            let expirationDate = new Date(decodedToken.exp * 1000);
            const currentDate = new Date();

            if (expirationDate < currentDate) {
                // if (debug_flag) console.log(req.url, " - token da refreshare");

                const newToken = await getNewToken(token, req);
                currentToken = newToken;

                // if (debug_flag) console.log(req.url, " - token refresh ok");
            }
            else {

                // if (debug_flag) console.log(req.url, " - token da non refreshare");
                currentToken = token;
                // if (debug_flag) console.log(req.url, " - ok, proseguo");
            }

            refreshingToken = false;

            // if (debug_flag_detail) console.log(req.url, " - fine promise");
        })();

        // Attendi il completamento del processo di refresh
        await tokenPromise;

        // Resetta la promessa
        tokenPromise = null;

        return currentToken;

    }

    if (!interceptorAdded) {
        axiosInstance.interceptors.request.use(
            async (req) => {


                let token = {
                    id_token: sessionStorage.getItem("id_token"),
                    access_token: sessionStorage.getItem("access_token"),
                    refresh_token: sessionStorage.getItem("refresh_token")
                };

                // if (debug_flag) console.log("URL: ", req.url);
                // if (debug_flag) console.log("Scade il:", new Date((await jwt_decode(sessionStorage.getItem("access_token"))).exp * 1000));

                // if (debug_flag_detail) console.log(req.url, " - 1id_token: ", token.id_token.slice(-50));
                // if (debug_flag_detail) console.log(req.url, " - 1access_token: ", token.access_token.slice(-50));
                // if (debug_flag_detail) console.log(req.url, " - 1refresh_token: ", token.refresh_token.slice(-50));

                // Aggiorna il token prima della chiamata asincrona
                token = await refreshToken(token, req);

                // if (debug_flag_detail) console.log(req.url, " - uso i token");

                sessionStorage.setItem("id_token", token.id_token);
                sessionStorage.setItem("access_token", token.access_token);
                sessionStorage.setItem("refresh_token", token.refresh_token);

                // if (debug_flag_detail) console.log(req.url, " - 2id_token: ", token.id_token.slice(-50));
                // if (debug_flag_detail) console.log(req.url, " - 2access_token: ", token.access_token.slice(-50));
                // if (debug_flag_detail) console.log(req.url, " - 2refresh_token: ", token.refresh_token.slice(-50));

                req.headers['Authorization'] = 'Bearer ' + token.id_token;
                req.headers['AuthorizationRole'] = token.access_token;

                return req;
            }
        );
        interceptorAdded = true;
    }

    return axiosInstance;
}