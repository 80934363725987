import { Container, List } from '@material-ui/core';
import React from 'react'
import { useSelector } from 'react-redux';
import { useMqXS } from '../../../../utils/Utils'
import ArchiveFilterItem from './ArchiveFilterItem/ArchiveFilterItem';

const ArchiveFiltersContent = () => {

    const mq_xs = useMqXS();

    const filters_family = useSelector(state => {
        return state.archive.filters_mirror;
    });

    return (
        <>
            <Container disableGutters={mq_xs}>
                <>
                {filters_family
                    ?   <List>
                            {filters_family.map((f,i) => (
                                <ArchiveFilterItem key={i} {...f} />
                            ))}
                        </List>
                    :   null
                }
                </>
            </Container>
        </>
    )
}

export default ArchiveFiltersContent