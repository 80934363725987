import * as actions from '../actions/appActions';

const initialState = {
    snackbar: {
        open: false,
        message: null,
        severity: "info",
    },
    is_authenticated: sessionStorage.getItem("is_authenticated") || false,
    menuIndex: null,
    terms_conditions: sessionStorage.getItem("terms") || false,
    tokens: JSON.parse(sessionStorage.getItem("tokens")) || null,
    user: JSON.parse(sessionStorage.getItem("user")) || null,
    isRefreshingToken: false,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.UPDATE_SNACKBAR:
            return {
                ...state,
                snackbar: action.value
            }
        case actions.SET_MENU_INDEX:
            return {
                ...state,
                menuIndex: action.payload
            };
        case actions.SET_TOKEN:
            sessionStorage.setItem("is_authenticated", true);
            sessionStorage.setItem("tokens", JSON.stringify(action.payload)
            );
            return {
                ...state,
                tokens: action.payload,
                is_authenticated: true
            }
        case actions.SET_USER_INFO:
            sessionStorage.setItem("user",
                JSON.stringify(action.payload)
            );
            return {
                ...state,
                user: action.payload,
            }
        case actions.USER_UNAUTHRIZED:
            sessionStorage.setItem("is_authenticated", false);
            return {
                ...state,
                is_authenticated: false,
                tokens: null
            }
        case actions.SET_TERMS_CONDITIONS:
            sessionStorage.setItem("terms", JSON.stringify(action.value));
            return {
                ...state,
                terms_conditions: action.value
            }
        default:
            break;
    }
    return state;

}

export default reducer;