import { Box, Hidden, IconButton } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import React from 'react'
import { useSelector } from "react-redux";
import { useHistory } from 'react-router';



const BackButton = ({ whichRole, params }) => {

    const history = useHistory();
    const redirectUrl = useSelector(state => {
        return state?.configuration?.redirectUrl
    });


    const handleClick = () => {
        // if (redirectUrl && params.currentView === 'summary') {
        //     history.push(redirectUrl);
        // } else {
        //     if (!params.currentView) {
        //         history.push('/configuration/');
        //     } else {
        //         if (params.currentView === 'summary') {
        //             const baseRoute = `/configuration/${params.rdre}/${params.size}`;
        //             const idConfiguration = params?.id_configuration ? `/${params.id_configuration}` : '';

        //             if (whichRole === 'External') {
        //                 history.push(idConfiguration ? `${baseRoute}${idConfiguration}` : baseRoute);
        //             } else {
        //                 const discountRoute = params.currentView === 'discount' ? '/discount' : '';

        //                 history.push(idConfiguration ? `${baseRoute}${discountRoute}/${params.id_configuration}` : `${baseRoute}${discountRoute}`);
        //             }
        //         } else if (params.currentView === 'discount') {
        //             const baseRoute = `/configuration/${params.rdre}/${params.size}`;
        //             const idConfiguration = params?.id_configuration ? `/${params.id_configuration}` : '';

        //             history.push(idConfiguration ? `${baseRoute}${idConfiguration}` : baseRoute);
        //         }
        //     }
        // }
        history.goBack();
    };

    return (
        <>
            <Hidden smUp>
                {/* {mobile} */}
                <IconButton
                    color="primary"
                    onClick={handleClick}
                >
                    <ArrowBack />
                </IconButton>
            </Hidden>
            <Hidden smDown>
                {/* {desktop} */}
                <Box mr={2}>
                    <IconButton
                        color="primary"
                        onClick={handleClick}
                    >
                        <ArrowBack />
                    </IconButton>
                </Box>
            </Hidden>
        </>
    )
}

export default BackButton