import React from "react";
import { Text, View, StyleSheet, Line, Svg } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
// creo lo stile del PDF
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  logo: {
    width: "20%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 5,
    paddingBottom: 5,
  },
  specstitle: {
    paddingRight: "20px",
    width: "30%",
    borderStyle: "solid 1pt",
    borderColor: "black",
  },
  specsdescription: {
    width: "70%",
    color: "#5a7c91",
    borderStyle: "solid 1pt",
    borderColor: "black",
  },
  today: {
    position: "absolute",
    right: 0,
    top: 0,
    fontSize: 12,
  },
  title: {
    marginTop: "8",
    marginBottom: "14",
    textAlign: "center",
    fontSize: "10",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: "8px",
  },
  flexSignature: {
    display: "flex",
    alignItems: "left",
    flexDirection: "row",
    marginBottom: "8px",
    borderTop: "solid 3px",
    color: "RGB(255,0,0)",
  },
  rowInformation: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    fontSize: 10,
  },
  subTitle: {
    marginTop: 14,
    fontSize: 12,
  },
  divider: {
    marginTop: 8,
  },
  sizeHalf: {
    width: "50%",
  },
  detailsWrapper: {
    fontSize: 10,
    marginBottom: "30px",
  },
  specValue: {
    fontSize: 7,
    width: "70%",
  },
  downloadBtn: {
    textDecoration: "none",
    color: "inherit",
    fontFamily: "inherit",
    height: 24,
  },
});

const PDFHeaderData = ({ data, model_code, country }) => {
  return (
    <>
      <View style={styles.rowInformation}>
        <View style={styles.sizeHalf}>
          <View style={styles.flexCenter}>
            <Text style={styles.sizeHalf}>Type</Text>
            <Text style={styles.specValue}>{data?.product?.family}</Text>
          </View>
          <View style={styles.flexCenter}>
            <Text style={styles.sizeHalf}>Product Name</Text>
            <Text style={styles.specValue}>{data?.product?.product_name}</Text>
          </View>
          <View style={styles.flexCenter}>
            <Text style={styles.sizeHalf}>Size</Text>
            <Text style={styles.specValue}>{data?.product?.size}</Text>
          </View>
          <View style={styles.flexCenter}>
            <Text style={styles.sizeHalf}>Code</Text>
            <Text style={styles.specValue}>
              {" "}
              {model_code?.replace(/%/g, "")}
            </Text>
          </View>
        </View>
        <View style={styles.sizeHalf}>
          <View style={styles.flexCenter}>
            <Text style={styles.sizeHalf}>Sales Engineer</Text>
            <Text style={styles.specValue}>
              {
                data?.discountInfo?.find((e) => e.type === "sales_engineer")
                  ?.optionValue
              }
            </Text>
          </View>
          <View style={styles.flexCenter}>
            <Text style={styles.sizeHalf}>Existing Code</Text>
            <Text style={styles.specValue}>{data?.existingCode}</Text>
          </View>
          <View style={styles.flexCenter}>
            <Text style={styles.sizeHalf}>Product Desc</Text>
            <Text style={styles.specValue}>{data?.product?.product_desc}</Text>
          </View>
          <View style={styles.flexCenter}>
            <Text style={styles.sizeHalf}>Customer Name</Text>
            <Text style={styles.specValue}>
              {
                data?.discountInfo?.find((e) => e.type === "customer")
                  ?.optionValue
              }
            </Text>
          </View>
          {country !== "IT" ? (
            <View style={styles.flexCenter}>
              <Text style={styles.sizeHalf}>Customer Channel</Text>
              <Text style={styles.specValue}>

                {data?.discount?.is_direct ? data?.discount?.is_direct ? "Direct" : "Indirect" : ''}

              </Text>
            </View>
          ) : null}
          {country !== "IT" ? (
            <View style={styles.flexCenter}>
              <Text style={styles.sizeHalf}>Customer Type</Text>
              <Text style={styles.specValue}>
                {data?.discount?.customer_type?.customer_type}
              </Text>
            </View>
          ) : null}
        </View>
      </View>
      <View style={styles.divider}>
        <Svg width="100%" height="4">
          <Line
            x1="0"
            x2="1000"
            y1="2"
            y2="2"
            strokeWidth="1"
            stroke="#002b49"
          />
        </Svg>
      </View>
    </>
  );
};
export default PDFHeaderData;
