import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ErrorBoundary from '../UI/ErrorBoundary/ErrorBoundary.jsx';
import { useAxiosSecurity } from '../../utils/useAxiosSecurity';

const Login = () => {

    const axios = useAxiosSecurity();
    const history = useHistory();

    const is_authenticated = useSelector(state => {
        return state.application.is_authenticated;
    });

    const [error, setError] = useState(null);


    useEffect(() => {

        if (is_authenticated) {

            history.replace({ search: null });
            history.push('/home');

        } else {
            axios.get('security/login')
                .then(response => window.location.href = response.data)
                .catch(error => {
                    console.log(error);
                    setError(error);
                });
        }

    }, [is_authenticated, history]);

    // useEffect(() => {

    //         // redirect to keycloack login page
    //         axios.get('security/login')
    //         .then(response => window.location.href = response.data)
    //         .catch(error => {
    //           setError(error.response.status);
    //         });



    // }, [axios]);

    return <ErrorBoundary error={error}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 24 }}>Redirecting...</div>
    </ErrorBoundary>;
};

export default Login;