import { IconButton, List, ListItem, ListItemIcon, ListItemText, Menu } from '@material-ui/core'
import { BuildOutlined, InfoOutlined, NewReleasesOutlined } from '@material-ui/icons'
import React, { useState } from 'react'

const InfoLegend = () => {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

  return (
      <>

        <IconButton
            color="secondary"
            onClick={handleClick}    
        >
            <InfoOutlined />
        </IconButton>

        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <List>
                <ListItem>
                    <ListItemIcon>
                        <NewReleasesOutlined color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary={"Important"} />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <BuildOutlined color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={"Related to configuration"} />
                </ListItem>
            </List>
        </Menu>

      </>

  )
}

export default InfoLegend