import { useMediaQuery, useTheme } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useCountry } from "./useCountry";



import { testFields, validationTypes } from "./validationTypes";



function formatPrice(price) {
  return new Intl.NumberFormat("it-IT", {
    minimumFractionDigits: "2",
    maximumFractionDigits: "2",
  }).format(price);
}
export function priceFormatter(value, isTotal = false, symbol = true) {

  let price = formatPrice(Math.round(value * 100) / 100);
  let sign = value >= 0 ? "+" : "";
  sign = isTotal ? "" : sign;
  return (symbol ? "€ " : "") + sign + " " + price;
}

export function priceFormatterSummary(value, isTotal = false, symbol = true) {
  let price = formatPrice(Math.round(value * 100) / 100);
  let sign = value >= 0 ? "+" : "";
  sign = isTotal ? "" : sign;
  return sign + " " + price + (symbol ? "€ " : "");
}

export function formatPriceValue(value, with_sign = false) {
  let price;
  if (value) {
    price = formatPrice(Math.round(value * 100) / 100);
  } else {
    price = formatPrice(Math.round(0 * 100) / 100);
  }

  let sign = value >= 0 ? "+" : "";

  sign = with_sign ? `${sign} ` : '';
  return `${sign}${price} €`;
}

export function percFormatter(value) {
  return (value * 100).toFixed(2) + " %";
}

export const useGeneratedModelCode = () => {
  // cicla le specs selected
  // quando trovo la posizione precedente a slash / dash aggiungo il carattere
  // return model code

  const slash = useSelector((state) => {
    return state?.configuration?.product?.slash_after_position;
  });

  const dash = useSelector((state) => {
    return state?.configuration?.product?.dash_after_position;
  });

  const specs = useSelector((state) => {
    return state?.configuration?.specs;
  });

  let modelCode = "";

  if (specs) {
    specs.forEach((element) => {

      modelCode += element?.value?.value ? element?.value?.value : "";

      if (element.position === slash) {
        modelCode += "/";
      }

      if (element.position === dash) {
        modelCode += "-";
      }
    });
  }

  return modelCode;
};

export const useMqXS = () => {

  const theme = useTheme();
  const mq_xs = useMediaQuery(theme.breakpoints.down("xs"));
  return mq_xs;

}

export const FormatAllSpecs = (data) => {
  let initialSpecsSelected = [];[...data].forEach((pos) => { // controllo che ci sia una sola opzione valida per questa posizione 
    const validList = pos.value_list;
    // estraggo i dettagli dell'opzione salvata 
    const details_saved = pos.id_value_saved ? pos.value_list.filter((o) => o.value === pos.value_saved).pop() : null;

    const id_datasheet = pos.id_value_saved ? details_saved ? details_saved.id : null : validList.length === 1 ? validList[0].id : null;

    const value = pos.id_value_saved ? details_saved ? details_saved.value : null : validList.length === 1 ? validList[0].value : null;

    const value_desc = pos.id_value_saved ? details_saved ? details_saved.value_desc : null : validList.length === 1 ? validList[0].value_desc : null;

    const value_details = pos.id_value_saved ? details_saved ? details_saved.value_details : null : validList.length === 1 ? validList[0].value_details ? validList[0].value_details : "" : null;

    const type = pos.id_value_saved ? details_saved ? details_saved.type : null : validList.length === 1 ? validList[0].type : null;

    const is_gross = pos.id_value_saved ? details_saved ? details_saved.is_gross : true : true;

    const price = pos.id_value_saved ? details_saved ? details_saved.price_option_list.length > 0 ? details_saved.price_option_list[0] : 0 : 0 : 0;

    const in_datasheet = pos.id_value_saved ? details_saved ? details_saved.in_datasheet : true : true;

    const is_valid = pos.id_value_saved ? details_saved ? details_saved.is_valid : true : true;

    const is_special = pos.id_value_saved ? details_saved ? details_saved.is_special : false : false;

    // creo l'item per initialSpecsSelected 
    initialSpecsSelected.push({
      id_datasheet: id_datasheet,
      position: pos.position,
      position_desc: pos.position_desc,
      value: value,
      value_desc: value_desc,
      value_details: value_details,
      type: type,
      is_gross: is_gross,
      price: price,
      in_datasheet: in_datasheet,
      is_valid: is_valid,
      is_special: is_special,
      accessoriesControlled: pos.accessory_control_list,
    });
  });
  return initialSpecsSelected;
}

export const createNewAllListValues = (data) => {


  return data.map(a => {


    // se value_list ha un solo valore
    if (a.value_list.length === 1 && !a.family.includes('Z')) {
      // aggiorno il valore 'yes'
      let newValueList = a.value_list.map(v => {
        return {
          ...v,
          value: 'Yes',
          desc: 'Yes'

        }
      })

      // aggiungo il valore 'no'
      newValueList.push({
        id: 0,
        value: 'No',
        desc: 'No',
        accessory: a.accessory,
        family: a.family,


      })

      // aggiorno la value_list_iniziale
      return {
        ...a,
        value_list: newValueList
      }

    } else {
      // se value_list ha più di un valore -> sarà sempre il caso degli S2 o degli Z

      let newValueList = a.value_list.map(v => {
        return {
          ...v,
          desc: v.value,

        }
      })

      // aggiorno la value_list_iniziale
      if (!a.family.includes('Z')) {
        return {
          ...a,
          value_list: [{ id: 0, value: 'No', desc: 'No', accessory: a.accessory, family: a.family }, ...newValueList]
        }
      } else {
        return {
          ...a,
          value_list: newValueList
        }
      }

    }

  });

}

export const useFirstRender = () => {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
}

// Hook
export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export const isEmpty = (a) => {
  return Array.isArray(a) && a.every(isEmpty);
};

//qui



export const countryFilters = ['AT', 'DE', 'CH', 'FR', 'GR', 'UK', 'TR', 'BE'];








export function formatPercentage(value) {

  if (value) {

    return (value * 100).toFixed(2) + " %";
  } else {

    return (0 * 100).toFixed(2) + " %";
  }

}

export function checkValue(value) {

  const tmp = Number(value);

  return Number.isFinite(tmp)
    ? Number(value)
      ? Number(value)
      : 0
    : 0;

}





export const isSpecialSelected = (props) => {

  // restituisce se è stata selezionata una opzione non standard o non valida
  const internalList = props.filter(o => o.in_datasheet === false && o.is_valid === true);
  const standardOutList = props.filter(o => o.is_valid === false);

  let isSpecial = false;

  if ((internalList.length > 0) || (standardOutList.length > 0)) {

    isSpecial = true;

  } else {

    isSpecial = false;

  }

  return isSpecial;

};


export const useValidationDiscount = (country) => {

  const validation = {};
  const auth_level = useCheckAuthorization(country);


  const applicationInfo = useSelector(state => {
    return state?.configuration?.applicationInfo
  });

  const discountInfo = useSelector(state => {
    return state?.configuration?.discountInfo
  });



  if (discountInfo?.some(e => e.type === 'customer') && discountInfo?.some(e => e.type === 'sales_engineer')) {
    delete validation.customer
    delete validation.sales_engineer
  } else {
    validation.customer = { required: true, value: discountInfo?.some(e => e.type === 'customer') }
    validation.sales_engineer = { required: true, value: discountInfo?.some(e => e.type === 'sales_engineer') }
  }

  if (auth_level === 3 && (!applicationInfo?.est_annual_qty || !discountInfo?.some(e => e.type === 'branch_manager'))) {
    validation.est_annual_qty = { required: true, value: applicationInfo?.est_annual_qty }
    validation.branch_manager = { required: true, value: discountInfo?.some(e => e.type === 'branch_manager') }
  } else {
    delete validation.est_annual_qty
    delete validation.branch_manager
  }













  return validation


}


export const useCheckAuthorization = (country) => {

  // 0: None
  // 1: Cluster manager
  // 2: SLM1 or PM
  // 3: Branch







  const is_military = useSelector(state => {
    return state?.configuration?.applicationInfo?.is_military
  });

  const is_nuclear = useSelector(state => {
    return state?.configuration?.applicationInfo?.is_nuclear
  });

  const is_aerospace = useSelector(state => {
    return state?.configuration?.applicationInfo?.is_aerospace
  });

  const base_price = useSelector(state => {
    return state?.configuration?.product?.base_price
  });

  // const top_sales_engineer_limit_price = useSelector(state => {
  //   return state.configuration.discount.top_sales_engineer_limit_price;
  // });

  const sales_engineer_limit_price = useSelector(state => {
    return state?.configuration?.discount?.sales_engineer_limit_price;
  });

  const cluster_manager_limit_price = useSelector(state => {
    return state?.configuration?.discount?.cluster_manager_limit_price;
  });

  const product_manager_limit_price = useSelector(state => {
    return state?.configuration?.discount?.product_manager_limit_price;
  });

  const extra_discounted_price = useSelector(state => {
    return state?.configuration?.calculatedDiscount;
  });

  const internal_sales_limit_price = useSelector(state => {
    return state?.configuration?.discount?.internal_sales_limit_price;
  });

  const sales_manager_limit_price = useSelector(state => {
    return state?.configuration?.discount?.sales_manager_limit_price;
  });

  const general_manager_limit_price = useSelector(state => {
    return state?.configuration?.discount?.general_manager_limit_price;
  });

  const customer_type_price = useSelector(state => {
    return state?.configuration?.discount?.customer_type_price;
  });

  const cu_maximum_price = useSelector(state => {
    return state?.configuration?.discount?.cu_maximum_price;
  });


  // const specsSelected = useSelector(state => {
  //   return state.configuration.specsSelected;
  // });

  // const is_special_selected = isSpecialSelected(specsSelected);

  // if (is_special_selected) {
  //   return 2;
  // }

  let discountedPrice;
  if (extra_discounted_price?.extra_discount) {
    discountedPrice = extra_discounted_price?.extra_discounted_price
  } else {
    discountedPrice = extra_discounted_price?.sales_net_price
  }

  if (is_military || is_nuclear || is_aerospace) {
    return 3;
  }

  if (base_price === 0) {
    return 3;
  }

  /*
 
  come funziona:
 
  creo un array di oggetti dove ogni oggetto contiene:
  - range start
  - range end
  - codice autorizzazione
 
  in base alla nazione -> filtra l'array -> ottieni l'oggetto dove il valore del prezzo extra scontato è tra il range start e il range end -> ritorna il codice
 
  */

  // fino al customer type è none, se il sales price è tra il customer type e il cu maximum price display manager auth; se è < al cu maximum

  switch (country) {
    case 'IT':

      if (discountedPrice < 1000000 && discountedPrice >= sales_engineer_limit_price) {
        return 0
      } else if (discountedPrice < sales_engineer_limit_price && discountedPrice >= cluster_manager_limit_price) {

        return 1
      } else if (discountedPrice < cluster_manager_limit_price && discountedPrice >= product_manager_limit_price) {

        return 2
      } else {

        return 3
      }

      // case 'BE':
      return ([
        {
          start: 1000000,
          end: internal_sales_limit_price,
          code: 0,
        },
        {
          start: internal_sales_limit_price,
          end: sales_engineer_limit_price,
          code: 5
        },
        {
          start: sales_engineer_limit_price,
          end: sales_manager_limit_price,
          code: 6
        },
        {
          start: sales_manager_limit_price,
          end: general_manager_limit_price,
          code: 7
        },
        {
          start: general_manager_limit_price,
          end: 0,
          code: 8
        }
      ]
        .filter(l => discountedPrice < l.start && discountedPrice >= l.end)
        .pop() || {}).code;
    default:// tutte le altre country unit
      console.log('discountedPrice', discountedPrice, discountedPrice < cu_maximum_price);
      if (discountedPrice < 1000000 && discountedPrice >= customer_type_price) {
        return 0
      } else if (discountedPrice < customer_type_price && discountedPrice >= cu_maximum_price) {

        return 4
      } else if (discountedPrice !== null && discountedPrice < cu_maximum_price) {

        return 3
      } else {

        return 0
      }

  }


}



export const useCheckMandatoryFields = () => {

  const country = useCountry()



  const id_customer = useSelector(state => {
    return state.configuration.discount.id_customer;
  });

  const customer = useSelector(state => {
    return state.configuration.discount.customer;
  });

  const customer_type = useSelector(state => {
    return state.configuration.discount.customer_type;
  });

  const is_direct = useSelector(state => {
    return state.configuration.discount.is_direct;
  });

  const manual_discount_class = useSelector(state => {
    return state.configuration.discount.manual_discount_class;
  });

  const sales_engineer = useSelector(state => {
    return state.configuration.discount.sales_engineer;
  });

  const branch_manager = useSelector(state => {
    return state.configuration.discount.branch_manager;
  });

  const est_annual_qty = useSelector(state => {
    return state.configuration.discount.est_annual_qty;
  });

  const sales_net_price = useSelector(state => {
    return state.configuration.discount.sales_net_price;
  });

  const auth_level = useCheckAuthorization();

  let count = 0;

  // check altre country
  switch (country) {
    case 'IT':
    case 'BE':
      // do nothing
      break;

    default:// altre country unit

      if (!customer_type) count++;

      if (!is_direct) count++;

      break;
  }

  // se customer === null
  if (!customer) count++;

  // se ho scelto un cliente manuale ma non ho ancora scelto la classe sconto
  if (id_customer === null && manual_discount_class === null) count++;

  // se sales_engineer === null
  if (!sales_engineer) count++;

  // se validazione sales_net_price === true -> error === true
  if (testFields(validationTypes.intMandatory, sales_net_price)) count++;

  // se auth_level === 'Branch'
  if (auth_level === 3) {

    // se branch_manager === null
    if (!branch_manager) count++;

    // se validazione est_annual_qty === true -> error === true
    if (testFields(validationTypes.intMandatory, est_annual_qty)) count++;

  }

  return count > 0

}


