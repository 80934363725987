import React, { useState, useEffect } from "react"
import { Grid } from "@material-ui/core";
import { formatPercentage, formatPriceValue } from "../../../../../../../utils/Utils";

import CustomerInfoBelgium from "../../../../../../../components/Configuration/Discount/Customer/CustomerInfoBelgium/CustomerInfoBelgium";





const CustomerBox = ({ data, country, readonly }) => {


    return (


        <>


            <Grid item xs={4} className="custom-border border-r-none">
                <p>{country === 'IT' ? 'Customer Discount' : 'Customer Type Price'}</p>
            </Grid>
            <Grid item xs={4} className="custom-border border-r-none">
                <p>{country === 'IT' ? formatPercentage(data?.customer_grid_discount) : formatPercentage(data?.customer_type_discount)}</p>
            </Grid>
            <Grid item xs={4} className="custom-border">
                {country === 'IT' ? formatPriceValue(data?.customer_grid_price) : formatPriceValue(data?.customer_type_price)}
            </Grid>
            {country === 'IT' && <>
                <Grid item xs={4} className="custom-border border-r-none">
                    <p>{country === 'IT' ? 'Sales Limit' : 'Sales Eng Price'}</p>
                </Grid>
                <Grid item xs={4} className="custom-border border-r-none">
                    <p>{formatPercentage(data?.sales_engineer_limit_discount)}</p>
                </Grid>
                <Grid item xs={4} className="custom-border">
                    {formatPriceValue(data?.sales_engineer_limit_price)}
                </Grid>
            </>}
            {/* <Grid item xs={4} className="custom-border  border-r-none">
                <p>Top Discount</p>
            </Grid>
            <Grid item xs={4} className="custom-border  border-r-none">
                {formatPercentage(data?.top_grid_discount)}
            </Grid>
            <Grid item xs={4} className="custom-border">
                {formatPriceValue(data?.top_grid_price)}
            </Grid> */}
            {country === 'BE' && <CustomerInfoBelgium data={data} />}
            {/* {(country !== 'BE' && country !== 'IT') && <CustomerFlagDirect readonly={readonly} data={data} />} */}

        </>

    )
}

export default CustomerBox