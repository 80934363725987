import { IconButton } from '@material-ui/core';
import { ArrowBackOutlined } from '@material-ui/icons';
import React from 'react'

const BackToList = (props) => {

    const handleClickBack = () => {
        props.backToList();
    }

    return (
        <IconButton color="primary" onClick={handleClickBack}>
            <ArrowBackOutlined />
        </IconButton>
    )

}

export default BackToList;