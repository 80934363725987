import * as actions from '../../actions/archive/actions';

const initialState = {
    id_selected:  sessionStorage.getItem("id_selected") || null,
    archiveId: sessionStorage.getItem("archiveId") || false,
    loading: true,
    edit_mode: false,
    copy_mode: false,
    permissions: {
        edit: false,
        delete: false,
        quotation: false
    },
    filter_my_config: false,
    filters: null,
    filter_apply: false,
    user_created_on: ''
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.SET_ARCHIVE_CONFIG_ID:
            sessionStorage.setItem("id_selected", action.value);
            return {
                ...state,
                id_selected: action.value
            }
        case actions.SET_ARCHIVE_LOADING:
            return {
                ...state,
                loading: action.value
            }
            case actions.SET_ARCHIVE_ID:
                sessionStorage.setItem("archiveId", true);
                return {
                    ...state,
                    archiveId: action.value
                }
                case actions.CLEAR_ARCHIVE_ID:
                    sessionStorage.setItem("archiveId", false);
                    return {
                        ...state,
                        archiveId: false
                    }
        case actions.RESET_ARCHIVE_CONFIG_ID:
            return {
                ...state,
                id_selected: null,
                loading: true
            }
        case actions.SET_EDIT_MODE:
            return {
                ...state,
                edit_mode: action.value
            }
        case actions.SET_COPY_MODE:
            return {
                ...state,
                copy_mode: action.value
            }
        case actions.SET_CONFIG_PERMISSIONS:
            return {
                ...state,
                permissions: action.value
            }
        case actions.SET_FILTER_MYCONFIG:
            return {
                ...state,
                filter_my_config: !state.filter_my_config
            }
        case actions.SET_FILTERS_DATA:
            return {
                ...state,
                filters: action.value,
                filters_mirror: action.value
            }
        case actions.SET_PRODUCT_FILTER:
            return {
                ...state,
                filters_mirror: [...state.filters_mirror].map(f => {
                    return {
                        ...f,
                        selected: (f.rdre === action.value) ? !f.selected : f.selected
                    }
                })
            }
        case actions.SET_SIZE_FILTER:

            const tmp_rdre = [...state.filters_mirror].filter(x => x.rdre === action.rdre).pop();
            
            const updated_list = tmp_rdre.size_list.map(x => {
                return {
                    ...x,
                    selected: (x.size === action.value) ? !x.selected : x.selected
                }
            });

            return {
                ...state,
                filters_mirror: [...state.filters_mirror].map(f => {
                    if (f.rdre === action.rdre) {
                        return {
                            ...f,
                            size_list: updated_list
                        }
                    } else {
                        return f
                    }
                })
            }
        case actions.SET_USER_CREATED_ON:
            return {
                ...state,
                user_created_on: action.value
            }
        case actions.RESET_FILTERS:
            return {
                ...state,
                filters_mirror: [...state.filters_mirror].map(p => {
                    return {
                        ...p,
                        selected: false,
                        size_list: [...p.size_list].map(s => {
                            return {
                                ...s,
                                selected: false
                            }
                        })
                    }
                })
            }
        case actions.APPLY_FILTERS:
            return {
                ...state,
                filter_apply: !state.filter_apply,
                filters: [...state.filters_mirror]
            }
        default:
            break;
    }

    return state;

}

export default reducer;