import React, { useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Line, Svg, usePDF } from '@react-pdf/renderer';
import { CircularProgress, IconButton, makeStyles } from '@material-ui/core';
import RexrothLogo from '../../assets/media/Rexroth-Logo_RGB_M.png';
import { PictureAsPdfOutlined } from '@material-ui/icons';

// creo lo stile del PDF
const styles = StyleSheet.create({
    page: {
        padding: 24
    },
    logo: {
        width: '20%'
    },
    today: {
        position: 'absolute',
        right: 0,
        top: 0,
        fontSize: 12
    },
    title: {
        paddingTop: 16,
        textAlign: 'center'
    },
    subTitle: {
        marginTop: 16,
        fontSize: 14
    },
    divider: {
        marginTop: 8
    },
    detailsWrapper: {
        fontSize: 12
    },
    specValue: {
        fontSize: 10
    },
    downloadBtn: {
        textDecoration: 'none',
        color: 'inherit',
        fontFamily: 'inherit',
        height: 24
    }
});

const useStyles = makeStyles(theme => ({
    btnDownload: theme.btnPDF,
    loadPDF: {
        color: theme.palette.accent.main
    }
}));

const dateOptions = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    timeZone: 'Europe/Rome'
};

const AKonfigPDFButton = (props) => {

    const style = useStyles();

    // creo il PDF
    const MyPDF = (
        <Document>
            <Page size="A4" orientation='portrait' style={styles.page}>

                <View>
                    <Image src={RexrothLogo} style={styles.logo} />
                    <Text style={styles.today}>{new Intl.DateTimeFormat('en-GB', dateOptions).format(new Date())}</Text>
                </View>
                <View style={styles.title}>
                    <Text>DATASHEET MATERIAL: {props.data.code}</Text>
                </View>
                <View style={styles.subTitle}>
                    <Text>Material description: {props.data.description}</Text>
                </View>
                <View style={styles.divider}>
                    <Svg width="100%" height="4">
                        <Line x1="0" x2="1000" y1="2" y2="2" strokeWidth="1" stroke="#002b49" />
                    </Svg>
                </View>
                <View style={{fontSize: 14, marginTop: 8, marginBottom: 16}}>
                    <Text>TECHNICAL DETAILS</Text>
                </View>
                <View style={styles.detailsWrapper}>
                    {props.data.specsList.map((s, i) => {
                        return (
                            <View style={{marginBottom: 8}} key={i}>
                                <View>
                                    <Text>{s.spec_label}</Text>
                                </View>
                                <View style={styles.specValue}>
                                    <Text style={{color: '#5a7c91'}}>{s.spec_value}</Text>
                                </View>
                            </View>
                        )
                    })}
                </View>

            </Page>
        </Document>
    );

    const [instance, updateInstance] = usePDF({document: MyPDF});
    
    useEffect(() => {
        return () => {
            updateInstance();
        };
    }, [updateInstance]);

    return(
        <React.Fragment>
            <IconButton classes={{root: style.btnDownload}}>
                <a href={instance.url} download={`TechData_${props.data.code}.pdf`} style={styles.downloadBtn}>
                    {instance.loading ? <CircularProgress classes={{root: style.loadPDF}} size={20} /> : <PictureAsPdfOutlined />}
                </a>
            </IconButton>
        </React.Fragment>
    )
}

export default AKonfigPDFButton;