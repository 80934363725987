import { Box } from '@material-ui/core';
import React from 'react';
import RexrothGIF from '../../../assets/media/rexroth.gif';
import { useMqXS } from '../../../utils/Utils';
import LoginButton from '../NavMenu/LoginButton/LoginButton';


const NavLogo = () => {
    
    const mq_xs = useMqXS();

    return (
        <>
            <Box ml={'auto'} display={'flex'} alignItems={'center'}>
                <Box mr={!mq_xs ? 2 : 0}>
                    <LoginButton />
                </Box>
                {!mq_xs
                    ?   <img 
                            src={RexrothGIF}
                            height={48}
                            alt="Rexroth"
                        />
                    :   null
                }
            </Box>
        </>
    );
};

export default NavLogo;