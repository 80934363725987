
import { useSelector } from 'react-redux';

export const useRoleDesc = () => {

    const user = useSelector(state => {
        return state.application.user;
    });


    const roles = user?.resource_access['dc-akonfig']?.roles ? user?.resource_access['dc-akonfig'].roles : (user?.resource_access['akonfig-dev']?.roles ? user?.resource_access['akonfig-dev'].roles : user?.resource_access['dc-akonfig-qa'].roles);

    if (roles) {
        if (roles.includes('admin')) return 'Admin'
        else if (roles.includes('user-vip')) return 'VIP'
        else if (roles.includes('user-internal')) return 'Internal'
        else if (roles.includes('user-external')) return 'External'
        else return "No role"
    }


}