import { Typography, Box, useTheme } from '@material-ui/core';
import React from 'react';
import { useMqXS } from '../../../utils/Utils';

const SectionTitle = (props) => {

    const mq_xs = useMqXS();
    const theme = useTheme();

    return (
        <Box
            px={2}
            py={1}
            style={{ 
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                width: 'max-content' 
            }}
            mb={2}
        >
            <Typography variant={mq_xs ? "body1" : "h5"} color="inherit">
                {props.title}
            </Typography>
        </Box>
    )

}

export default SectionTitle;