import { Grid } from '@material-ui/core';
import React from 'react';
import FunctionCard from '../FunctionCard/FunctionCard';

const Functionalities = () => {

  return (
      <>
        <Grid container spacing={5} direction="row" justifyContent="space-around" alignItems="stretch">
                <Grid item md={4} sm={12} xs={12}>

                    <FunctionCard 
                        title="Product configuration"
                        body="Configure a specific product, choose the accessories and request an official quotation"
                        buttonText="Start"
                        section="configuration"
                    />

                </Grid>
                
                <Grid item md={4} sm={12} xs={12}>

                    <FunctionCard 
                        title="Archive"
                        body="Search, review and edit your saved configurations"
                        buttonText="Open"
                        section="archive"
                    />

                </Grid>
                
            </Grid>
      </>
  );
};

export default Functionalities;
