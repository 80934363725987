import { Box, Container, makeStyles } from '@material-ui/core';
import React from 'react';
import Greetings from '../Greetings/Greetings.jsx';

const useStyles = makeStyles(theme => ({
    WelcomeBanner: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        width: '100%',
        position: 'relative',
        '&::before': {
            content: '""',
            backgroundColor: theme.palette.common.white,
            width: '200%',
            position: 'absolute',
            top: '-30vh',
            left: '-50%',
            transform: 'rotate(-4deg)',
            zIndex: '-9',
        }
    },
    WelcomeBannerChild: {
        position: 'relative',
        color: theme.palette.primary.main
    }
}));

const WelcomeBanner = (props) => {

    const styles = useStyles();

    return (
        <div className={styles.WelcomeBanner}>
            <Container>
                <Box mt={4} className={styles.WelcomeBannerChild}>

                    <Greetings />
                
                </Box>
            </Container>
        </div>
    )

}

export default WelcomeBanner;