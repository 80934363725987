import { Box, CircularProgress, makeStyles, Toolbar } from '@material-ui/core';
import React from 'react';
import AKonfigPDFButton from '../../../../PDF/PDF';
import BackToList from '../../../../UI/ProductSelectorActionButtons/BackToList';
import ConfirmMaterial from '../../../../UI/ProductSelectorActionButtons/ConfirmMaterial';
import InfoLegend from '../../../../UI/ProductSelectorActionButtons/InfoLegend.jsx';
import ToggleAllSpecs from '../../../../UI/ProductSelectorActionButtons/ToggleAllSpecs';

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around'
    },
    progressPDF: {
        color: theme.palette.accent.main
    }
}));

const ProductDetailsMobileActions = (props) => {

    const styles = useStyles();

    const handleClickBack = () => {
        props.backToList();
    }

    const handleClickSelect = () => {
        props.selectMaterial();
    }

    const handleAllSpecs = () => {
        props.toggleAllSpecs();
    }

    return (
        <div>
            <Toolbar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <BackToList backToList={handleClickBack} />
                    {/* <Divider flexItem orientation="vertical" /> */}
                    <ConfirmMaterial confirmMaterial={handleClickSelect} />
                    {/* <Divider flexItem orientation="vertical" /> */}
                    <ToggleAllSpecs allSpecs={props.allSpecs} toggleAllSpecs={handleAllSpecs} />
                    {/* <Divider flexItem orientation="vertical" /> */}
                    {props.materialPDFData.specsList
                        ? <AKonfigPDFButton type="mobile" data={props.materialPDFData} />
                        : <CircularProgress classes={{root: styles.progressPDF}} />
                    }
                    <InfoLegend />
                </Box>
            </Toolbar>
        </div>
    )

}

export default ProductDetailsMobileActions