import { Box, Button, Hidden, IconButton } from '@material-ui/core'
import { EditOutlined, FileCopyOutlined } from '@material-ui/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const CopyEditButton = ({ params, type, editable }) => {

    const history = useHistory();
    const handleClick = () => {


        if (type === 'copy') {
            history.push('/configuration/' + params.rdre + '/' + params.size);
        } else {
            history.push('/configuration/' + params.rdre + '/' + params.size + '/edit/' + params.id_configuration);
        }
    }

    if (type === 'edit' && !editable) {
        return <></>
    }



    return (
        <>
            <Hidden smUp>
                {/* {mobile} */}
                <IconButton
                    color="secondary"
                    onClick={handleClick}
                >
                    {type === 'copy' ? <FileCopyOutlined /> : <EditOutlined />}

                </IconButton>
            </Hidden>
            <Hidden smDown>
                {/* {desktop} */}
                <Box mr={2}>
                    <Button
                        color="secondary"
                        variant="contained"
                        startIcon={type === 'copy' ? <FileCopyOutlined color='inherit' /> : <EditOutlined color='inherit' />}
                        onClick={handleClick}
                    >
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                    </Button>
                </Box>
            </Hidden>
        </>
    )
}

export default CopyEditButton