import { IconButton } from '@material-ui/core';
import { UnfoldLessOutlined, UnfoldMoreOutlined } from '@material-ui/icons';
import React from 'react';

const ToggleAllSpecs = (props) => {

    const handleToggle = () => {
        props.toggleAllSpecs();
    }

    return (
        <IconButton color="primary" onClick={handleToggle}>
            {props.allSpecs
                ?   <UnfoldLessOutlined />
                :   <UnfoldMoreOutlined />
            }
        </IconButton>
    )

}

export default ToggleAllSpecs