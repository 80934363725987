export const parseJwt = (access_token, id_token, refresh_token) => {
    const base64Url = access_token.split('.')[1];
    const base64UrlID = id_token.split('.')[1];
    const base64UrlRefresh = refresh_token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const base64ID = base64UrlID.replace(/-/g, '+').replace(/_/g, '/');
    const base64Refresh = base64UrlRefresh.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    const jsonPayloadID = decodeURIComponent(window.atob(base64ID).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    const jsonPayloadRefresh = decodeURIComponent(window.atob(base64Refresh).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

   
   
    // return JSON.parse(jsonPayload);
    return {...JSON.parse(jsonPayload), ...JSON.parse(jsonPayloadID), ...JSON.parse(jsonPayloadRefresh)}
}