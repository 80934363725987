import { useSelector } from "react-redux";

export const useCountry = () => {
    return useSelector((state) => {

        console.log(state.application.user.country, 'COUNTRY');
        return state.application.user.country;

        /*
        //COUNTRY TEST
        if (state.application.user.country == "PL") {
            return "IT";
        }
        else {
            if (state.application.user.email == "dario.massarani@boschrexroth.it") {
                return "DE";
            }
            else {
                if (state.application.user.email == "christian.motta@boschrexroth.it" || state.application.user.email == "riccardo.ferrari@boschrexroth.it" || state.application.user.email == "cristina.lazzati@boschrexroth.it") {
                    return "DDD";
                } else {
                    return state.application.user.country;
                }
            }
        }
        */

    });
};
