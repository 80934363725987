import React from 'react';
import { Snackbar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_SNACKBAR } from '../../../store/actions/appActions';
import { Alert, AlertTitle } from "@material-ui/lab";




const NotificaSnackbar = () => {

    const storeSnackbar = useSelector(state => {
        return state.application.snackbar
    });

    const dispatch = useDispatch();

    const updateSnackbar = (objData) => dispatch({type: UPDATE_SNACKBAR, value: objData});

    const handleClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        updateSnackbar({open: false, message: null});
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            open={storeSnackbar.open}
            autoHideDuration={2000}
            onClose={handleClose}
            //message={storeSnackbar.message}
            
        >
          <Alert severity={storeSnackbar.severity}>{storeSnackbar.message}</Alert>
          </Snackbar> 
    )

}

export default NotificaSnackbar;
