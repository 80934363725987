import React, { useState, useEffect, useCallback } from "react";
import FamilyBar from "../../components/Configuration/SelectProductData/FamilyBar/FamilyBar";
import ProductsGrid from "../../components/Configuration/SelectProductData/ProductsGrid/ProductsGrid";
import { useDispatch, useSelector } from "react-redux";
import { RESET_COMPLETE_CONFIG, SET_BASE_PRICE, RESET_SPECIAL_REQUEST } from "../../store/actions/actions";
import LoadingBar from "../../components/UI/LoadingBar/LoadingBar";
import { Card, makeStyles } from "@material-ui/core";
import { Container, Grid } from '@material-ui/core';
import { SET_PRODUCTS_LIST } from "../../store/actions/actions";
import ErrorBoundary from "../../components/UI/ErrorBoundary/ErrorBoundary";
import { useAxios } from "../../utils/useAxios";
import { useRoleDesc } from "../../utils/useRoleDesc";
import { CLEAR_MATERIAL_SELECTED } from "../../store/actions/ProductSelector/actions";

const useStyles = makeStyles(theme => ({
    content: {
        backgroundColor: 'transparent',
        marginTop: theme.spacing(14),
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(9)
        }
    }
}));

const ConfigurationsList = () => {

    const styles = useStyles();
    const dispatch = useDispatch();
    const axios = useAxios();
    const whichRole = useRoleDesc();
    const productsList = useSelector(state => {

        return state.configuration.productsList;

    });

    const [results, setResults] = useState(productsList ? productsList : []);
    const [staticResults, setStaticResults] = useState([]);
    const [family, setFamily] = useState();
    const [families, setFamilies] = useState([]);
    const [error, setError] = useState(null);


    const getProductsQuery = async () => {

        if (whichRole === "Admin" | whichRole === "Internal" | whichRole === "VIP") {
            try {
                const mainResult = await axios.get('product/getallproduct')

                if (mainResult) {
                    try {
                        const partialResult = await axios.get('product/getallproductsizeconfidential')

                        if (partialResult) {
                            for (let i = 0; i < mainResult.data.length; i++) {
                                for (let j = 0; j < partialResult.data.length; j++) {
                                    if (mainResult.data[i].rdre === partialResult.data[j].rdre) {
                                        mainResult.data[i].size_list.push(partialResult.data[j]);
                                    }
                                }
                            }

                            setResults(mainResult.data);
                            setStaticResults(mainResult.data);
                            dispatch({ type: SET_PRODUCTS_LIST, value: mainResult.data })
                        }


                    } catch (e) {
                        console.log(e);
                    }
                }
            } catch (error) {
                console.log(error);
            };
        } else {
            try {
                const mainResult = await axios.get('product/getallproduct')

                if (mainResult) {
                    setResults([...mainResult.data]);
                    setStaticResults(mainResult.data);
                    dispatch({ type: SET_PRODUCTS_LIST, value: mainResult.data })
                }
            } catch (error) {
                console.log(error);
            };
        }



    }

    useEffect(() => {
        document.querySelector('#root').scrollTo(0, 0);
        getProductsQuery()
        dispatch({ type: RESET_COMPLETE_CONFIG });
        dispatch({ type: RESET_SPECIAL_REQUEST });
        dispatch({ type: CLEAR_MATERIAL_SELECTED })


    }, []);

    useEffect(() => {
        const valoriUnici = new Set();

        // Creo un array per contenere i valori unici
        const valoriUniciArray = [];

        // Itero sull'array in input
        results.forEach((item) => {
            const familyValue = item.family;

            // Aggiungo il valore al Set se non è già presente
            if (!valoriUnici.has(familyValue)) {
                valoriUnici.add(familyValue);
                valoriUniciArray.push(item.family);
            }
        });

        setFamilies(valoriUniciArray);

        dispatch({ type: SET_BASE_PRICE, value: 0 });

    }, [staticResults]);


    useEffect(() => {

        if (family) {
            const newList = staticResults.filter(item => item.family === family);
            setResults(newList)
        } else {
            setResults(staticResults)
        }
    }, [family]);


    return (
        <ErrorBoundary error={error}>
            {results.length > 0
                ? <>
                    <FamilyBar
                        list={families}
                        setExtFamily={setFamily}
                    />
                    <Card elevation={0} classes={{ root: styles.content }}>
                        <Container>
                            <Grid container spacing={3} style={{ marginBottom: '8px' }}>
                                {results.map((item, index) => {
                                    return (
                                        <Grid key={index} item xs={12} sm={6} md={4}>

                                            <ProductsGrid
                                                item={item}
                                            />

                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Container>
                    </Card>

                    {/* <ActionBarMobile /> */}

                </>
                : <LoadingBar />
            }
        </ErrorBoundary>
    );
};

export default ConfigurationsList;


