export const SET_ARCHIVE_CONFIG_ID = 'SET_ARCHIVE_CONFIG_ID';
export const SET_ARCHIVE_LOADING = 'SET_ARCHIVE_LOADING';
export const RESET_ARCHIVE_CONFIG_ID = 'RESET_ARCHIVE_CONFIG_ID';
export const SET_ARCHIVE_ID = 'SET_ARCHIVE_ID';
export const SET_USER_CREATED_ON = 'SET_USER_CREATED_ON';
export const CLEAR_ARCHIVE_ID = 'CLEAR_ARCHIVE_ID';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const SET_COPY_MODE = 'SET_COPY_MODE';
export const SET_CONFIG_PERMISSIONS = 'SET_CONFIG_PERMISSIONS';
export const SET_FILTER_MYCONFIG = 'SET_FILTER_MYCONFIG';
export const SET_FILTERS_DATA = 'SET_FILTERS_DATA';
export const SET_PRODUCT_FILTER = 'SET_PRODUCT_FILTER';
export const SET_SIZE_FILTER = 'SET_SIZE_FILTER';
export const RESET_FILTERS = 'RESET_FILTERS';
export const APPLY_FILTERS = 'APPLY_FILTERS';