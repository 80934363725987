import React, { useEffect } from "react";
import {
  StyleSheet,
  usePDF,
} from "@react-pdf/renderer";
import {
  CircularProgress,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { PictureAsPdfOutlined } from "@material-ui/icons";
import MyPDFCustomer from "./MyPDFCustomer";



// creo lo stile del PDF
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  logo: {
    width: "20%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 6,
    paddingBottom: 6,
  },

  specstitle: {
    paddingRight: "20px",
    width: "90%",
    borderStyle: "solid 1pt",
    borderColor: "black",
  },
  specsdescription: {
    width: "90%",
    color: "#5a7c91",
    borderStyle: "solid 1pt",
    borderColor: "blue",
  },
  today: {
    position: "absolute",
    right: 0,
    top: 0,
    fontSize: 10,
  },
  title: {
    marginTop: "8",
    marginBottom: "14",
    textAlign: "center",
    fontSize: "11",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: "4px",
  },
  flexSignature: {
    display: "flex",
    alignItems: "left",
    flexDirection: "row",
    marginBottom: "8px",
    borderTop: "solid 3px",
    color: "RGB(255,0,0)",
  },
  rowInformation: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    fontSize: 10,
  },
  subTitle: {
    marginTop: 14,
    fontSize: 12,
  },
  divider: {
    marginTop: 6,
  },
  sizeHalf: {
    width: "50%",
  },
  detailsWrapper: {
    fontSize: 10,
    marginBottom: "30px",
  },
  specValue: {
    fontSize: 8,
  },
  downloadBtn: {
    textDecoration: "none",
    color: "inherit",
    fontFamily: "inherit",
    height: 24,
  },
});

const useStyles = makeStyles((theme) => ({
  btnDownload: theme.btnPDF,
  loadPDF: {
    color: theme.palette.accent.main,
  },
}));

const PDFCustomer = ({ data, model_code, whichRole, country }) => {
  const style = useStyles();
  const [instance, updateInstance] = usePDF({ document: <MyPDFCustomer data={data} whichRole={whichRole} modelCode={model_code} country={country} /> });

  useEffect(() => {
    updateInstance();
  }, [data]);





  return (
    <React.Fragment>
      <IconButton classes={{ root: style.btnDownload }}>
        <a
          href={instance.url}
          download={`CustomerData_${data?.product?.product_name}.pdf`}
          style={styles.downloadBtn}
        >
          {instance.loading ? (
            <CircularProgress classes={{ root: style.loadPDF }} size={20} />
          ) : (
            <Tooltip title="Customer PDF">
              <PictureAsPdfOutlined />
            </Tooltip>
          )}
        </a>
      </IconButton>
    </React.Fragment>
  );
};

export default PDFCustomer;
