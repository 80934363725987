import React from 'react'

import { ReactComponent as CircleFilled } from '../../../assets/optionSymbols/circle_filled.svg';
import { ReactComponent as CircleEmpty } from '../../../assets/optionSymbols/circle_empty.svg';
import { ReactComponent as Triangle } from '../../../assets/optionSymbols/triangle.svg';
import { ReactComponent as Square } from '../../../assets/optionSymbols/square.svg';
import { Box, Typography } from '@material-ui/core';

const OptionSymbol = ({ type }) => {

    const primary = '#002b49';

    let symbol = null;

    switch (type) {
        case 0: symbol = <CircleFilled style={{ fill: primary }} />; break;
        case 1: symbol = <CircleEmpty style={{ fill: primary }} />; break;
        case 2: symbol = <Triangle style={{ fill: primary }} />; break;
        case 3: symbol = <Square style={{ fill: primary }} />; break;
        default:
            break;
    }

    return (
        <div style={{ marginRight: '8px', marginBottom: '0' }}>


            {symbol}


        </div>
    )

}

export default OptionSymbol;