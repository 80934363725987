import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppBar, Box, Button, Typography, makeStyles, Toolbar, Menu, MenuItem, ListItemIcon, Chip, Container, Hidden } from '@material-ui/core';
import { Check, FilterListOutlined } from '@material-ui/icons';
import { useMqXS } from '../../../../utils/Utils';
import ConfigureProductButton from '../../ConfigureProductButton/ConfigureProductButton';

const useStyles = makeStyles(theme => ({
    appbar: {
        top: theme.spacing(8),
        [theme.breakpoints.down("xs")]: {
            top: theme.spacing(7)
        },
        backgroundColor: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.standard.main}`
    },
    BoxChip: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            marginLeft: theme.spacing(1)
        }
    }
}));

const FamilyBar = ({ list, setExtFamily }) => {


    const styles = useStyles();
    const mq_xs = useMqXS();
    const [family, setFamily] = useState();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const openMenuHandler = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleClick = (family) => {
        setFamily(family)
        setExtFamily(family)
        handleClose();
    }

    const handleDelete = () => {
        setFamily();
        setExtFamily()
    }





    return (
        <>
            <AppBar position="fixed" elevation={0} classes={{ root: styles.appbar }}>
                <Toolbar>
                    <Container maxWidth="lg" disableGutters={mq_xs}>
                        <Box display={'flex'} alignItems={'center'}>
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<FilterListOutlined />}
                                size={mq_xs ? 'small' : 'medium'}
                                onClick={openMenuHandler}
                            >
                                Filter
                            </Button>
                            <Box classes={{ root: styles.BoxChip }}>

                                {family && <Chip

                                    label={`Family: ${family}`}
                                    color="primary"
                                    variant="outlined"
                                    onDelete={() => handleDelete()}
                                    size={mq_xs ? 'small' : 'medium'}
                                />}

                            </Box>

                            <ConfigureProductButton />

                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>

            <Menu
                id="families-filters"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: 48 * 8
                    }
                }}
            >
                {list.map((f, indexY) => (
                    <MenuItem key={indexY} onClick={() => handleClick(f)}>
                        {f === family
                            ? <ListItemIcon>
                                <Check color="primary" />
                            </ListItemIcon>
                            : <>
                                {!family
                                    ? null
                                    : <ListItemIcon></ListItemIcon>
                                }
                            </>
                        }
                        <Typography variant="body1" color="primary">
                            {f}
                        </Typography>
                    </MenuItem>))}
            </Menu>
        </>
    )

}

export default FamilyBar;