import {
  AppBar,
  Box,
  Button,
  Container,
  Hidden,
  makeStyles,
  Toolbar,
} from "@material-ui/core";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_PRODUCT_TYPE_LIST,
  RESET_COMPARE_SLOTS,
} from "../../../../store/actions/ProductSelector/actions";
import { useAxios } from "../../../../utils/useAxios";
import { useMqXS } from "../../../../utils/Utils";
import CodeList from "./CodeList/CodeList";


const useStyles = makeStyles((theme) => ({
  AppBar: {
    borderBottom: `1px solid ${theme.palette.standard.main}`,
  },
  container: {
    padding: 0,
  }
}));


const ProductsLists = (props) => {

  const styles = useStyles();
  const axios = useAxios();
  const mq_xs = useMqXS();

  const buttons = [
    {
      text: 'Standard',
      value: props.standard,
      click: () => handleClickLoadList("standard"),
      disabled: props.standard === 0,
      color: props.listType === "standard" ? "primary" : "secondary"
    },
    {
      text: 'Go To',
      value: props.goto,
      click: () => handleClickLoadList("goTo"),
      disabled: props.goto === 0,
      color: props.listType === "goTo" ? "primary" : "secondary"
    },
    {
      text: 'Customer',
      value: props.customer,
      click: () => handleClickLoadList("customer"),
      disabled: props.customer === 0,
      color: props.listType === "customer" ? "primary" : "secondary"
    },
  ];



  const storeProductSelectorLists = useSelector((state) => {
    return state.productSelector.lists;
  });

  const storeCompareSlots = useSelector((state) => {
    return state.productSelector.compareProducts.slots;
  });



  const dispatch = useDispatch();

  const setProductTypeList = useCallback(
    (objData) =>
      dispatch({
        type: SET_PRODUCT_TYPE_LIST,
        fieldName: objData.fieldName,
        value: objData.value,
      }),
    [dispatch]
  );
  const resetCompareSlots = () => dispatch({ type: RESET_COMPARE_SLOTS });

  const getProductTypeList = (listType) => {

    const body = {
      // country: "IT",
      type: listType,
      material_desc: props.modelCode
    }


    axios.get("productselector/GetProductTypeDetail", { params: body })
      .then((response) => {
        setProductTypeList({ fieldName: listType, value: response.data });
      })
      .catch((error) => {
        console.error(error);
      });

  }

  const handleClickLoadList = (listType) => {

    resetCompareSlots();

    props.changeList(listType);

    if (!storeProductSelectorLists[listType].loaded) {

      getProductTypeList(listType);

    }

  };

  const handleClickCompare = () => {
    props.changeSection(2);
  };

  useEffect(() => {
    getProductTypeList(props.listType);
  }, [props.modelCode]);

  const list = storeProductSelectorLists[props.listType].data;

  return (
    <div>

      <AppBar
        color="transparent"
        elevation={0}
        position="relative"
        classes={{ root: styles.AppBar }}
      >
        <Toolbar>
          <Container maxWidth="lg" disableGutters={mq_xs}>

            <Box display={'flex'} alignItems={'center'} justifyContent={mq_xs ? 'space-between' : 'flex-start'}>

              {buttons.map((button, index) => {
                return (
                  <Box key={index} mr={mq_xs ? 0 : 2}>
                    <Button
                      color={button.color}
                      size={mq_xs ? "small" : "medium"}
                      variant="contained"
                      disabled={button.disabled}
                      onClick={button.click}
                    >
                      {button.value} {button.text}
                    </Button>
                  </Box>
                )
              })}
              {/* <Box mr={mq_xs ? 0 : 2}>
                <Button
                  color={props.listType === "standard" ? "primary" : "secondary"}
                  size={mq_xs ? "small" : "medium"}
                  variant="contained"
                  disabled={props.standard === 0}
                  onClick={() => handleClickLoadList("standard")}
                >
                  {props.standard} Standard
                </Button>
              </Box>
              <Box mr={mq_xs ? 0 : 2}>
                <Button
                  color={props.listType === "goTo" ? "primary" : "secondary"}
                  size={mq_xs ? "small" : "medium"}
                  variant="contained"
                  disabled={props.goto === 0}
                  onClick={() => handleClickLoadList("goTo")}
                >
                  {props.goto} Go To
                </Button>
              </Box>
              <Button
                color={props.listType === "customer" ? "primary" : "secondary"}
                size={mq_xs ? "small" : "medium"}
                variant="contained"
                disabled={props.customer === 0}
                onClick={() => handleClickLoadList("customer")}
              >
                {props.customer} Customer
              </Button> */}

              <Hidden smDown>
                <Box ml={'auto'}>
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={storeCompareSlots.length < 2 ? true : false}
                    onClick={handleClickCompare}
                  >
                    Compare products
                  </Button>
                </Box>
              </Hidden>

            </Box>
          </Container>
        </Toolbar>
      </AppBar>

      <Container maxWidth="lg" disableGutters={mq_xs}>
        <CodeList changeSection={props.changeSection} listType={props.listType} list={list} />
      </Container>

    </div>
  );
};

export default ProductsLists;
