import { AppBar, Box, Hidden, makeStyles, Toolbar } from '@material-ui/core'
import React from 'react'
import ArchiveFilters from '../ArchiveFilters/ArchiveFilters';

const useStyles = makeStyles(theme => ({
    AppBar: {
        bottom: 0,
        top: 'auto',
        borderTop: '1px solid #e5e5e5'
    }
}))

const ActionBarMobile = () => {

    const styles = useStyles();

    return (
        <>
            <Hidden smUp>

                <Toolbar />

                <AppBar
                    position="fixed"
                    color="default"
                    elevation={0}
                    classes={{root: styles.AppBar}}
                >
                    <Toolbar>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>

                            <ArchiveFilters />

                        </Box>
                    </Toolbar>
                </AppBar>

            </Hidden>
        </>
    )
}

export default ActionBarMobile