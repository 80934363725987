import { Box, Button, Hidden, IconButton } from '@material-ui/core'
import { DeleteOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_SNACKBAR } from '../../../../../store/actions/appActions';
import { useAxios } from '../../../../../utils/useAxios';
import DialogDelete from '../../../../../components/Archive/ActionBar/DialogDelete/DialogDelete';


const MobileBottomBarLayout = ({ children, isMobile }) => {




    return (
        <>
            {isMobile ? <div style={isMobile ? { boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', padding: '15px', position: 'fixed', width: '100%', left: '0', bottom: '0', backgroundColor: 'white', display: 'flex' } : {}}>
                {children}
            </div> : <> {children}</>}


        </>
    )
}

export default MobileBottomBarLayout