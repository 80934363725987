import axios from 'axios';

export const useAxiosSecurity = () => {


    const xSecurity = axios.create({
        baseURL: 'api/',
        withCredentials: true,
    });

    return xSecurity;


}