import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import configurationReducer from './store/reducers/ConfigurationReducer'
import applicationReducer from './store/reducers/appReducer'
import productSelectorReducer from './store/reducers/ProductSelector/reducer';
import archiveReducer from './store/reducers/archive/reducer';
import App from './App';
import { unregister } from './registerServiceWorker';

const rootReducer = combineReducers({
    application: applicationReducer,
    configuration: configurationReducer,
    productSelector: productSelectorReducer,
    archive: archiveReducer
});

const store = createStore(rootReducer, process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.getElementById('root'));

unregister();