import React from 'react';
import { makeStyles, Typography } from "@material-ui/core";
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { useMqXS } from '../../../utils/Utils';

const useStyles = makeStyles(theme => ({
    WelcomeBanner: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        width: '100%',
        position: 'relative',
        '&::before': {
            content: '""',
            backgroundColor: theme.palette.common.white,
            width: '200%',
            height: '110%',
            padding: 8,
            position: 'absolute',
            left: '-50%',
            transform: 'rotate(-4deg)',
            zIndex: '-9',
        }
    },
    WelcomeBannerChild: {
        position: 'relative',
        color: theme.palette.primary.main
    }
}));

const Greetings = () => {

    const styles = useStyles();
    const mq_xs = useMqXS();

    const is_authenticated = useSelector(state => {
        return state.application.is_authenticated;
    });
    const user = useSelector(state => {
        return state.application.user;
    });

 


    // const id_token = useSelector(state => {
    //     return state.application.tokens?.id_token
    // });

    return (
        <>
            <div className={styles.WelcomeBanner}>
                <Typography variant={mq_xs ? "h4" :"h3"} color="inherit">
                    {is_authenticated
                        ? <span>Hello {user?.given_name}</span>
                        : <span>This is AKonfig</span>
                    }
                </Typography>
                <Typography variant={mq_xs ? "h6" :"h5"} color="inherit" gutterBottom>
                    {is_authenticated
                        ?   <span>What are you up to today?</span>
                        :   <span>The new AK products configurator by Rexroth</span>
                    }
                </Typography>
            </div>
        </>
    );
};

export default Greetings;
