import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { SET_TOKEN } from '../../../store/actions/appActions';
import { SET_USER_INFO } from '../../../store/actions/appActions';
import { useAxiosSecurity } from '../../../utils/useAxiosSecurity';
import { parseJwt } from '../../../utils/parseJwt';
import { SET_TERMS_CONDITIONS } from '../../../store/actions/appActions';



const VerifyLogin = (props) => {

  const history = useCallback(useHistory(), []);
  const axios = useCallback(useAxiosSecurity(), []);

  const dispatch = useDispatch();
  const setToken = useCallback((objData) => dispatch({ type: SET_TOKEN, payload: objData.payload }), [dispatch]);
  const setUserInfo = useCallback((objData) => dispatch({ type: SET_USER_INFO, payload: objData.payload }), [dispatch]);
  const query = new URLSearchParams(props.location.search);
  const authorization_code = query.get('code');





  useEffect(() => {

    if (authorization_code) {


      axios.post('security/gettoken', { "authorization_code": authorization_code })
        .then(response => {

          setToken({ payload: response.data });
          dispatch({ type: SET_TERMS_CONDITIONS, value: response.data.terms_accepted });
          setUserInfo({ payload: parseJwt(response.data.access_token, response.data.id_token, response.data.refresh_token) });
          dispatch({ type: SET_TOKEN, payload: response.data })

          //CIP
          sessionStorage.setItem("id_token", response.data.id_token);
          sessionStorage.setItem("access_token", response.data.access_token);
          sessionStorage.setItem("refresh_token", response.data.refresh_token);

          // history.push("/home");

        })
        .catch(error => {

          if (error.response) {

            if (error.response.status === 401) {
              history.push("/not-authorized");
            } else {
              // history.push("/error");
              console.log(error);
            }

          } else if (error.request) {
            // history.push("/error");
          } else {
            // history.push("/error");
          }

        });

    }

  }, []);
  // axios, authorization_code, history, setToken

  return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 24 }}>Verifying credentials...</div>;

}

export default VerifyLogin;