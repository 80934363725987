import { Box, Button, Hidden, IconButton } from '@material-ui/core'
import { DeleteOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_SNACKBAR } from '../../../../../store/actions/appActions';
import { useAxios } from '../../../../../utils/useAxios';
import DialogDelete from '../../../../../components/Archive/ActionBar/DialogDelete/DialogDelete';


const DeleteButton = ({ isOwner, params }) => {

    const dispatch = useDispatch();
    const axios = useAxios();



    const [open, setOpen] = useState(false);

    const updateSnackbar = (objData) => dispatch({ type: UPDATE_SNACKBAR, value: objData });

    const handleConfirm = () => {

        if (!isOwner) return;



        axios.patch('storage/deactivatestorage?id_configuration=' + params.id_configuration)
            .then(res => {

                updateSnackbar({ open: true, message: 'Configuration deleted', severity: "success" });
            })
            .catch(error => {

                updateSnackbar({ open: true, message: 'Unable to delete configuration', severity: "error" });
            });

    }

    const handleOpenDeleteDialog = () => {

        if (!isOwner) return;

        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }



    return (
        <>
            {isOwner
                ? <>
                    <Hidden smUp>
                        {/* {mobile} */}
                        <IconButton
                            color="secondary"
                            onClick={handleOpenDeleteDialog}
                        >
                            <DeleteOutlined />
                        </IconButton>
                    </Hidden>
                    <Hidden smDown>
                        {/* {desktop} */}
                        <Box mr={2}>
                            <Button
                                color="secondary"
                                variant="contained"
                                startIcon={<DeleteOutlined color="inherit" />}
                                onClick={handleOpenDeleteDialog}
                            >
                                Delete
                            </Button>
                        </Box>
                    </Hidden>
                    <DialogDelete
                        open={open}
                        handleClose={handleClose}
                        handleConfirm={handleConfirm}
                    />
                </>
                : null
            }


        </>
    )
}

export default DeleteButton