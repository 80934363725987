import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Link, makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { SET_TERMS_CONDITIONS } from '../../../store/actions/appActions';
import { useAxios } from '../../../utils/useAxios';
import { useMqXS } from '../../../utils/Utils';
import ErrorBoundary from '../../UI/ErrorBoundary/ErrorBoundary';

const useStyles = makeStyles(theme => ({
    DialogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(8),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
            height: theme.spacing(7),
        }
    },
    DialogContent: {
        padding: 0
    },
    DialogActions: {
        justifyContent: 'center'
    }
}))

const TermsConditions = () => {

    const styles = useStyles();
    const mq_xs = useMqXS();
    const axios = useAxios();
    const dispatch = useDispatch();

     const terms_conditions = useSelector(state => {
        return state.application.terms_conditions;
      });

      

    const [terms, setTerms] = useState('');
    const [userInputs, setUserInputs] = useState('');
    const [userAccept, setUserAccept] = useState('');
    const [error, setError] = useState(null);

    const setTermsConditions = (objData) => dispatch({type: SET_TERMS_CONDITIONS, value: objData.value});

    const handleChangeTerms = (e) => {
        setTerms(e.target.value);
    }

    const handleChangeUserInputs = (e) => {
        setUserInputs(e.target.value);
    }

    const handleChangeAccept = (e) => {
        setUserAccept(e.target.value);
    }

    const handleClick = () => {
        axios.post('user/acceptterms')
        .then(response => {
            
             dispatch({type: SET_TERMS_CONDITIONS, value: response.data});
            // setTermsConditions({value: response});
        })
        .catch(error => {
            setError(error.response.status);
        });
    }

    const missing = (Number(terms) + Number(userInputs) + Number(userAccept)) !== 3;

    const alert_mandatory = <Alert severity="error">
                                E' obbligatorio accettare i termini e le condizioni di utilizzo per poter usufruire del servizio. Prego selezionare Accetto per proseguire.
                            </Alert>;
        
    return (
        <div>
            <Dialog
                fullScreen
                fullWidth
                open={!terms_conditions}
            >
                <DialogTitle classes={{root: styles.DialogTitle}}>
                    <Typography color="inherit" variant="h6">Terms & Conditions</Typography>
                </DialogTitle>
                <DialogContent dividers classes={{root: styles.DialogContent}}>
                    <Container>
                        <Box mb={4} mt={2}>
                            <Typography color="primary" variant="body1">Leggere attentamente i <Link underline="always" href='./terms/terms.pdf' download target='_blank'>Termini e Condizioni di utilizzo</Link></Typography>
                            <FormControl>
                                <RadioGroup name="terms" value={terms} onChange={handleChangeTerms}>
                                    <FormControlLabel value={"1"} control={<Radio color='primary' />} label="Accetto" />
                                    <FormControlLabel value={"0"} control={<Radio color='primary' />} label="Non accetto" />
                                </RadioGroup>
                            </FormControl>
                            {Number(terms) === 0 && terms !== ''
                                ?   alert_mandatory
                                :   null
                            }
                        </Box>
                        <Box mb={4}>
                            <Typography color="primary" variant="body1">
                                L'utente riconosce e accetta che il configuratore è uno strumento di simulazione fornito gratuitamente da Bosch Rexroth S.p.A..<br/>
                                Il configuratore non verifica né l'accuratezza dei dati inseriti dall'utente, da cui i risultati del processo di configurazione dipendono, né l'idoneità del risultato finale all'applicazione dell'utente.<br/>
                                L'utente è l'unico responsabile dei dati inseriti nel configuratore e del risultato ottenuto all'esito della configurazione.
                            </Typography>
                            <FormControl>
                                <RadioGroup name="userInputs" value={userInputs} onChange={handleChangeUserInputs}>
                                    <FormControlLabel value={"1"} control={<Radio color='primary' />} label="Accetto" />
                                    <FormControlLabel value={"0"} control={<Radio color='primary' />} label="Non accetto" />
                                </RadioGroup>
                            </FormControl>
                            {Number(userInputs) === 0 && userInputs !== ''
                                ?   alert_mandatory
                                :   null
                            }
                        </Box>
                        <Box mb={4}>
                            <Typography color="primary" variant="body1">
                                L'utente riconosce e accetta che le informazioni fornite in fase di configurazione e all'esito della stessa, inclusi i prezzi dei prodotti, sono puramente indicative e non rappresentano in alcun modo un'offerta vincolante da parte di Bosch Rexroth S.p.A.
                            </Typography>
                            <FormControl>
                                <RadioGroup name="userAccept" value={userAccept} onChange={handleChangeAccept}>
                                    <FormControlLabel value={"1"} control={<Radio color='primary' />} label="Accetto" />
                                    <FormControlLabel value={"0"} control={<Radio color='primary' />} label="Non accetto" />
                                </RadioGroup>
                            </FormControl>
                            {Number(userAccept) === 0 && userAccept !== ''
                                ?   alert_mandatory
                                :   null
                            }
                        </Box>
                    </Container>
                </DialogContent>
                <DialogActions classes={{root: styles.DialogActions}}>
                    <ErrorBoundary error={error}>
                        <Button
                            variant='contained'
                            color="secondary"
                            size={mq_xs ? "small" : "medium"}
                            disabled={missing}
                            onClick={handleClick}
                        >
                            Conferma
                        </Button>
                    </ErrorBoundary>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default TermsConditions