import React, { useCallback, useEffect, useState } from "react";
import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "./components/SearchBar";
import { useAxios } from "../../utils/useAxios";
import ErrorBoundary from "../../components/UI/ErrorBoundary/ErrorBoundary";
import ArchiveListItem from "../../components/Archive/ArchiveList/ArchiveListItem/ArchiveListItem";
import LoadingBar from "../../components/UI/LoadingBar/LoadingBar";
import NotFound from "../../components/UI/NotFound/NotFound";
import ActionBarMobile from "../../components/Archive/ActionBarMobile/ActionBarMobile";
import { CLEAR_MATERIAL_SELECTED } from "../../store/actions/ProductSelector/actions";
import { useRoleDesc } from "../../utils/useRoleDesc";
import { useCountry } from "../../utils/useCountry";

const useStyles = makeStyles((theme) => ({
    content: {
        marginTop: theme.spacing(8)
    }
}));

const Archive = () => {
    const styles = useStyles();
    const axios = useAxios();
    const dispatch = useDispatch();
    const whichRole = useRoleDesc();
    const country = useCountry();
    const id_selected = useSelector(state => state.archive.id_selected);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const search = useCallback((params) => {
        setLoading(true);
        axios.post("storage/getallconfiguration", params)
            .then((response) => setList(response.data))
            .catch((error) => setError(error?.response?.status))
            .finally(() => setLoading(false));
    }, [axios]);

    const handleSearch = useCallback((value, isMyConfig, filters) => {
        const params = {
            product_filter_list: filters ?? [],
            search_value: String(value),
            is_my_config: Boolean(isMyConfig)
        };
        search(params);
    }, [search]);

    useEffect(() => {
        const params = { product_filter_list: [], search_value: '', is_my_config: false };
        search(params);
        dispatch({ type: CLEAR_MATERIAL_SELECTED });
    }, [id_selected, dispatch, search]);

    return (
        <div>
            <ErrorBoundary error={error}>
                {!id_selected && (
                    <div>
                        <SearchBar search={handleSearch} />
                        <div className={styles.content}>
                            {!loading && (
                                <Container maxWidth="lg">
                                    <Box py={2}>
                                        {list.length > 0 ? (
                                            <Grid container spacing={3}>
                                                {list.map(i => (
                                                    <Grid item xs={12} sm={6} md={4} key={i.id}>
                                                        <ArchiveListItem data={i} />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        ) : (
                                            <NotFound />
                                        )}
                                    </Box>
                                </Container>
                            )}
                            {loading && <LoadingBar />}
                        </div>
                        <ActionBarMobile />
                    </div>
                )}
            </ErrorBoundary>
        </div>
    );
};

export default Archive;
