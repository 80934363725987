import { Box, Button } from '@material-ui/core'
import { PersonOutlineOutlined } from '@material-ui/icons';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { SET_FILTER_MYCONFIG } from '../../../store/actions/archive/actions';
import { useMqXS } from '../../../utils/Utils'

const MyConfigurationsButton = () => {

    const mq_xs = useMqXS();
    const dispatch = useDispatch();

    const filter_my_config = useSelector(state => {
        return state.archive.filter_my_config;
    });

    const setFilter = () => dispatch({type: SET_FILTER_MYCONFIG});

    const handleClick = () => {
        setFilter();
    }

    return (
        <>
            <Box mr={1}>
                <Button
                    color={filter_my_config ? "primary" : "secondary"}
                    variant="contained"
                    size={mq_xs ? 'small' : 'medium'}
                    onClick={handleClick}
                    startIcon={<PersonOutlineOutlined />}
                >
                   Owned
                </Button>
            </Box>
        </>
    )
}

export default MyConfigurationsButton