import React from "react";
import { Box, Container, Grid } from "@material-ui/core";
import { Card, makeStyles } from "@material-ui/core";
import VideoPlayer from "../UI/VideoPlayer/VideoPlayer";
import { Typography } from "@material-ui/core";

const Tutorial = () => {
  const useStyles = makeStyles((theme) => ({
    content: {
      backgroundColor: "transparent",
      marginTop: theme.spacing(14),
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(9),
      },
    },
  }));
  const styles = useStyles();

  const videoFiles = [
    require("../../../../ClientApp/src/assets/tutorial/Configurazione_Base.mp4"),
    require("../../../../ClientApp/src/assets/tutorial/Inviare_Segnalazione.mp4"),
    require("../../../../ClientApp/src/assets/tutorial/Product_Selector.mp4"),
    require("../../../../ClientApp/src/assets/tutorial/Salvataggio_Configurazione.mp4"),
    // Aggiungi altri file video se necessario
  ];

  const videoTitles = [
    " Creare una configurazione di base",
    " Inviare una segnalazione",
    " Utilizzare il product selector",
    " Salvare una configurazione",
  ];

  return (
    <Container >
      <Typography
        variant="h3"
        style={{ color: "#00CCFF", textAlign: "center" }}
      >
        Come fare per...
      </Typography>
      <Box mt={1} display="flex" justifyContent="center">
        <Card style={{ padding: '20px 0' }} elevation={0}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box mt={0}>
                <Container>
                  <>
                    <VideoPlayer
                      videoFiles={[videoFiles[0]]}
                      videoTitles={[videoTitles[0]]}
                    />
                  </>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mt={0}>
                <Container>
                  <>
                    <VideoPlayer
                      videoFiles={[videoFiles[1]]}
                      videoTitles={[videoTitles[1]]}
                    />
                  </>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mt={0}>
                <Container>
                  <>
                    <VideoPlayer
                      videoFiles={[videoFiles[2]]}
                      videoTitles={[videoTitles[2]]}
                    />
                  </>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mt={0}>
                <Container>
                  <>
                    <VideoPlayer
                      videoFiles={[videoFiles[3]]}
                      videoTitles={[videoTitles[3]]}
                    />
                  </>
                </Container>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Container>
  );
};

export default Tutorial;
