import { AppBar, Box, Container, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Toolbar, Typography } from '@material-ui/core';
import { BuildOutlined, NewReleasesOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_COMPARE_SLOTS, SET_MATERIAL_SELECTED } from '../../../../store/actions/ProductSelector/actions';
import { useAxios } from '../../../../utils/useAxios';
import BackToList from '../../../UI/ProductSelectorActionButtons/BackToList';
import ConfirmMaterial from '../../../UI/ProductSelectorActionButtons/ConfirmMaterial';
import InfoLegend from '../../../UI/ProductSelectorActionButtons/InfoLegend.jsx';
import ToggleAllSpecs from '../../../UI/ProductSelectorActionButtons/ToggleAllSpecs';


const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: `1px solid ${theme.palette.standard.main}`,
    },
    toolbarDesktop: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: theme.spacing(1)
    },
    container: {
        padding: 0
    },
    columnHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.standard.main}`,
    },
    divider: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    GridItem: {
        borderLeft: `1px solid ${theme.palette.standard.main}`,
        borderRight: `1px solid ${theme.palette.standard.main}`,
    }
}));

const ProductCompare = (props) => {

    const styles = useStyles();
    const axios = useAxios();

    const storeCompareSlots = useSelector(state => {
        return state.productSelector.compareProducts.slots
    });

    const [materials, setMaterials] = useState([]);

    const dispatch = useDispatch();
    const setMaterialSelected = (objData) => dispatch({type: SET_MATERIAL_SELECTED, code: objData.code, description: objData.description});
    const resetCompareSlots = () => dispatch({type: RESET_COMPARE_SLOTS});

    const handleClickBack = () => {
        resetCompareSlots();
        props.backToList();
    }

    const handleClickSelectMaterial = (objMaterial) => {
        setMaterialSelected({code: objMaterial.code, description: objMaterial.desc});
        props.closeModal();
    }

    const handleAllSpecs = () => {
        props.toggleAllSpecs();
    }

    useEffect(() => {

        // load all material data
        let promises = [];
        let results = [];

        storeCompareSlots.forEach(m => {
            promises.push(
                axios.get('productselector/GetProductDetail', {params: {material: m.code}})
                .then(response => {

                    const result = response.data ? response.data : {};
                    results.push(result);
                })
            )
        });

        Promise.all(promises).then(() => {

            // genero un nuovo set di materiali ma trasformo specDetailsList da array a oggetto
            const new_results = results.map(r => {

                return {
                    ...r,
                    spec_detail_list: r.spec_detail_list.reduce((o, key) => ({ ...o, [key.spec_label]: key.spec_value ? key.spec_value.toLowerCase() : 'Not available' }), {})
                }

            });

            // prendo il primo materiale come base per ciclare le spec
            const base_material = [...results].slice(0,1).pop();
            
            // array che contiene la lista delle spec diverse
            let different_keys = [];

            base_material.spec_detail_list.forEach(spec => {
                
                const base_field = spec.spec_label;
                const base_field_value = spec.spec_value ? spec.spec_value.toLowerCase() : 'Not available';

                new_results.forEach(x => {

                    // per ogni materiale controllo il valore della spec corrente
                    if (x.spec_detail_list[base_field] !== base_field_value) {
                        // se in almeno un materiale il valore è diverso salvo il nome della spec nell'array
                        different_keys.push(base_field);
                    }

                })
            
            });

            // ciclo il set di materiali di base e aggiorno il flag is_different per ogni valore presente in different_keys
            const new_res = results.map(res => {
                return {
                    ...res,
                    spec_detail_list: res.spec_detail_list.map(sp => {
                        return {
                            ...sp,
                            is_different: different_keys.indexOf(sp.spec_label) > -1
                        }
                    })
                }
            });

            setMaterials(new_res);

        });

    }, []);


    return (
        <React.Fragment>
            <AppBar classes={{root: styles.appBar}} color="transparent" elevation={0} position="relative">
                <Toolbar>
                    <Container maxWidth="lg">
                        <Box display={'flex'} alignItems={'center'}>
                            <BackToList backToList={handleClickBack} />
                            <Box ml={2}>
                                <ToggleAllSpecs 
                                    allSpecs={props.allSpecs}
                                    toggleAllSpecs={handleAllSpecs}
                                />
                            </Box>
                            <Box ml={2}>
                                <InfoLegend />
                            </Box>
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>

            <Container maxWidth="lg">
                <Grid container spacing={0}>
                    {materials.map(c => {
                        const list = c.spec_detail_list
                        ?   props.allSpecs
                            ?   c.spec_detail_list
                            :   c.spec_detail_list.filter(s => s.is_important)
                        : null
                        return (
                            <Grid item xs={12 / materials.length} key={c.material} classes={{root: styles.GridItem}}>
                                <div className={styles.columnHeader}>
                                    <Typography style={{wordBreak: "break-all"}}  color="primary" variant="body1">{c.material} &middot; {c.material_desc}</Typography>
                                    <Box ml={2}>
                                        <ConfirmMaterial data={{code: c.material, desc: c.material_desc}} confirmMaterial={handleClickSelectMaterial}/>
                                    </Box>
                                </div>
                                <List>
                                    {list.map((s, i) => {
                                        return (
                                            <ListItem divider key={i} selected={s.is_different}>
                                                {s.is_important
                                                    ?   <ListItemIcon>
                                                            <NewReleasesOutlined color="secondary" />
                                                        </ListItemIcon>
                                                    :   null
                                                }
                                                {s.is_configuration
                                                    ?   <ListItemIcon>
                                                            <BuildOutlined color="primary" />
                                                        </ListItemIcon>
                                                    :   null
                                                }
                                                <ListItemText
                                                    inset={s.is_configuration || s.is_important ? false : true}
                                                    primary={s.spec_label}
                                                    secondary={s.spec_value ?? 'Not available'} 
                                                />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </React.Fragment>
    )

}

export default ProductCompare