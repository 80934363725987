import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';




const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '40%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '30px 35px',
    },
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
}));

// Inspired by blueprintjs
function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}







const ManualPriceDrawer = ({ isMobile, open, data, onClose, setValueSelected }) => {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [radioValue, setRadioValue] = React.useState('gross');

    const [amount, setAmount] = React.useState('');

    function getModalStyle() {


        if (isMobile) {
            return {
                top: `50%`,
                left: `50%`,
                transform: `translate(-50%, -50%)`,
                width: '80%'
            };
        } else {
            return {
                top: `50%`,
                left: `50%`,
                transform: `translate(-50%, -50%)`,
            };
        }
    }


    useEffect(() => {

        setAmount('');
        setRadioValue('gross');

    }, [open])


    return (

        <div>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={modalStyle} className={classes.paper}>
                    <div style={{ marginBottom: '40px' }}>
                        <p style={{ color: '#002b49', fontSize: isMobile ? '15px' : '1.2rem', marginBottom: '15px', marginTop: '0px' }} >No price can be calculated for  <span style={{ color: '#00ccff' }}>{data?.value?.value} - {data?.value?.value_desc}</span> because is a out of catalog or internal catalog option.</p>
                        <p style={{ fontSize: isMobile ? '15px' : '1.1rem', margin: '0px' }}>Insert price manually</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                        <FormControl component="fieldset">
                            <FormLabel style={{ fontSize: isMobile ? '13px' : '1rem', textAlign: 'center', color: '#002b49' }} component="legend">Value</FormLabel>
                            <RadioGroup style={{ flexDirection: 'row', flexWrap: 'nowrap' }} defaultValue="gross" aria-label="gender" name="customized-radios">
                                <FormControlLabel onChange={(e) => { setRadioValue(e.target.defaultValue); }} value="gross" control={<StyledRadio />} label="Gross" />
                                <FormControlLabel onChange={(e) => { setRadioValue(e.target.defaultValue); }} value="net" control={<StyledRadio />} label="Net" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '20%', marginBottom: '30px', justifyContent: 'center', alignItems: 'center' }}>

                        <FormControl className={classes.margin} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                            <OutlinedInput
                                width={90}
                                type='text'
                                id="outlined-adornment-amount"
                                value={amount}
                                onChange={(e) => {
                                    const inputValue = e.target.value.replace(",", ".");;
                                    if (inputValue === '' || !isNaN(parseFloat(inputValue))) {
                                        // Se l'input è vuoto o rappresenta un numero valido
                                        setAmount(inputValue || '');
                                    }
                                }}
                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                labelWidth={60}
                            />
                        </FormControl>
                        <div>
                            <Button variant="contained" onClick={() => {
                                const copyObj = { ...data }
                                copyObj.value.price = amount === '' ? 0 : parseFloat(amount);
                                copyObj.value.is_gross = radioValue === 'gross';
                                setValueSelected(copyObj);
                                onClose();
                            }} style={{ color: '#ffff', backgroundColor: '#00ccff', marginTop: isMobile ? '10px' : '0', fontSize: isMobile ? '15px' : '' }}>Set price</Button>

                        </div>
                    </div>
                </div>
            </Modal >
        </div >
    );

}

export default ManualPriceDrawer