


export const prepareConfiguration = (whichRole, configuration, params, modelCode) => {


    const tmp_discount = { ...configuration?.discount };
    delete tmp_discount.salesEngineersList;
    delete tmp_discount.customersList;
    delete tmp_discount.branchManagersList;
    delete tmp_discount.projectsList;
    delete tmp_discount.id_project;
    delete tmp_discount.project;
    delete tmp_discount.id;

    delete configuration?.discount?.customer_type

    const material_code = configuration?.product?.description === modelCode ? configuration?.product?.code : null;
    const material_description = configuration?.product?.description === modelCode ? configuration?.product?.description : null;







    const realBody = {


        // omit the project object if you don't have to create a new project
        "project": {
            // omit the id field or set to null if you want to create a new project

            // id: id_project,
            // name: project,
            id: configuration?.discount?.id_project,
            name: configuration?.discount?.project,

        },
        "configuration": {
            // omit the id field or set to null if you want to create a new configuration
            id: parseInt(params?.id_configuration) || null,
            name: configuration?.name,
            // omit the id_project field or set to null if you are creating a new project  
            rdre: configuration?.product.rdre,
            matclass: "",
            family: configuration?.product?.family,
            product_name: configuration?.product?.product_name,
            size: configuration?.product?.size,
            generated_code: modelCode,
            final_price: configuration?.calculatedDiscount?.finalPrice || configuration?.finalPrice,
            material: material_code,
            material_desc: material_description,
            operating_mode: configuration?.product.operating_mode,
            slash_after_position: configuration?.product.slash_after_position,
            dash_after_position: configuration?.product?.dash_after_position,
            series: configuration?.product.series,
            product_desc: configuration?.product?.product_desc,
            special_request: configuration?.specialSelected,
            sales_engineer: configuration?.discountInfo?.find(e => e.type === 'sales_engineer').optionValue,
            customer: configuration?.discountInfo?.find(e => e.type === 'customer').optionValue,
            base_price: configuration?.product?.base_price
        },

        "accessory_list": configuration?.accessories,
        "position_list": configuration?.specs,
        "discount": whichRole !== 'External' ? {
            ...configuration?.discount,
            ...configuration?.calculatedDiscount,
            id_customer: configuration?.customCustomer?.customer ? null : configuration?.discountInfo?.find(e => e.type === 'customer')?.optionSelected?.id_customer,
            customer: configuration?.customCustomer?.customer || configuration?.discountInfo?.find(e => e.type === 'customer').optionValue,
            id_sales_engineer: configuration?.discountInfo?.find(e => e.type === 'sales_engineer')?.optionSelected?.id_sales_engineer,
            sales_engineer: configuration?.discountInfo?.find(e => e.type === 'sales_engineer')?.optionValue,
            id_branch_manager: configuration?.discountInfo?.find(e => e.type === 'branch_manager')?.optionSelected?.id_branch_manager,
            branch_manager: configuration?.discountInfo?.find(e => e.type === 'branch_manager')?.optionValue,
            manual_discount_class: configuration?.customCustomer?.manualDiscountClass,
            is_military: configuration?.discount.applicationInfo?.is_military,
            is_aerospace: configuration?.discount.applicationInfo?.is_aerospace,
            is_nuclear: configuration?.discount.applicationInfo?.is_nuclear,
            existing_code: (configuration?.existingCode && configuration?.existingCode != '') ? configuration?.existingCode : null,
            min_order_qty: configuration?.discount.applicationInfo?.min_order_qty,
            est_annual_qty: configuration?.discount.applicationInfo?.est_annual_qty,
            authorization: 0,
        } : {}
    }


    return {

        url: 'storage/savestorage',
        data: realBody

    }






}