import { Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import Rexroth_Stripe from '../../../assets/media/REXR_Stripe_rgb.png';
import { useDispatch } from 'react-redux';
import { SET_CONFIG_CURRENT_SECTION } from '../../../store/actions/actions';
import { RESET_ARCHIVE_CONFIG_ID } from '../../../store/actions/archive/actions';


const useStyles = makeStyles(theme => ({
    Card: {
        border: `1px solid ${theme.palette.primary25.main}`,
        minHeight: 360,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    CardActionArea: {
        display: 'flex',
        flexDirection: 'column'
    },
    CardActions: {
        justifyContent: 'center',
        marginTop: 'auto'
    },
    actionBtn: {
        marginBottom: theme.spacing(1)
    }
}));

const FunctionCard = (props) => {
    const dispatch = useDispatch();

    const styles = useStyles();
    const history = useHistory();

    const handleClick = () => {
        if (props.section === 'configuration') {
            dispatch({type: SET_CONFIG_CURRENT_SECTION, value: 0})
        }
        dispatch({type: RESET_ARCHIVE_CONFIG_ID})
        history.push(`/${props.section}`);

    }

    return (
        <Card elevation={2} classes={{root: styles.Card}}>
            <CardMedia 
                component='img'
                src={Rexroth_Stripe}
            />
            <CardActionArea disableRipple disableTouchRipple classes={{root: styles.CardActionArea}}>
                <CardHeader 
                    title={props.title}
                    titleTypographyProps={{variant: 'h6', color: 'primary', align: 'center'}}
                />
                <CardContent>
                    <Typography variant="body1" color="initial">
                        {props.body}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions disableSpacing classes={{root: styles.CardActions}}>
                <Button
                    classes={{root: styles.actionBtn}}
                    color="secondary"
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                >
                    Go
                </Button>
            </CardActions>
        </Card>
    )

}

export default FunctionCard;