import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import React from 'react';

const DialogDelete = (props) => {

    const handleClose = () => {
        props.handleClose();
    }

    const handleConfirm = () => {
        props.handleConfirm();
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
        >
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    The configuration cannot be restored if you delete it. Do you want to continue?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    style={{
                        fontFamily: "Montserrat, sans-serif",
                        textTransform: "none",
                        fontWeight: 600,
                        color: "#5a7c91"
                    }}
                    onClick={handleClose}
                    variant="text"
                    color="default"
                    size="large"
                >
                    No
                </Button>
                <Button
                    style={{
                        fontFamily: "Montserrat, sans-serif",
                        textTransform: "none",
                        fontWeight: 600,
                        color: "white",
                    }}
                    onClick={handleConfirm}
                    variant="contained"
                    size="large"
                    disableElevation
                    color="secondary"
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default DialogDelete;