import { Box, Button, Card, CardActionArea, CardActions, CardContent, Grid, makeStyles, Table, TableBody, TableCell, TableRow, Typography, useTheme } from '@material-ui/core';
import { ArrowDropDownOutlined, DescriptionOutlined } from '@material-ui/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { useMqXS } from '../../../../../utils/Utils';

const useStyles = makeStyles(theme => ({
    ProductCard: {
        border: `1px solid ${theme.palette.primary25.main}`
    },
    card: {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
        borderTop: `1`
    },
    cardSelected: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText
    },
    image: {
        height: '10vh',
        backgroundSize: 'contain'
    },
    Table: {
        borderTop: `1px solid ${theme.palette.primary25.main}`,
        borderLeft: `1px solid ${theme.palette.primary25.main}`,
        borderRight: `1px solid ${theme.palette.primary25.main}`,
        backgroundColor: theme.palette.common.white
    },
    TableCell: {
        width: '20%',
        borderRight: `1px solid ${theme.palette.primary25.main}`
    }
}));

const ProductCard = ({ size, item, chooseSize }) => {

    const styles = useStyles();
    const theme = useTheme();
    const mq_xs = useMqXS();

    const rdre_five = item.rdre.substring(0, 5);
    // const url = `https://www.boschrexroth.com/en/xc/myrexroth/media-directory?p_p_id=3&p_p_lifecycle=0&p_p_state=normal&p_p_mode=view&p_p_col_id=column-1&p_p_col_pos=1&p_p_col_count=3&_3_struts_action=%2Fsearch%2Fsearch&_3_redirect=https%3A%2F%2Fwww.boschrexroth.com%2Fen%2Fweb%2Fxc%2Fmyrexroth%2Fmedia-directory%3Fp_p_id%3D3%26p_p_lifecycle%3D0%26p_p_state%3Dnormal%26p_p_mode%3Dview%26p_p_col_id%3Dcolumn-1%26p_p_col_pos%3D1%26p_p_col_count%3D3&_3_keywords=${rdre_five}&_3_groupId=0`;

    const url = `https://www.boschrexroth.com/en/de/search.html?q=${rdre_five}&num=10&origin=header&s=download`;


    const openMediaDirectory = () => {
        window.open(url, "_blank").focus();
    }

    const handleClick = (e) => {
        chooseSize(e.currentTarget, item.rdre);
    }




    const productRdreFromRedux = useSelector(state => {
        return state?.configuration?.product?.rdre;
    });

    return (
        <React.Fragment>
            <Card elevation={2} classes={{ root: styles.ProductCard }}>
                <CardActionArea >
                    <Box py={4} borderBottom={'1px solid #e5e5e5'} display={'flex'} justifyContent={'center'}>
                        <img
                            alt={item.description}
                            style={{
                                height: '10vh',
                                backgroundSize: 'contain'
                            }}
                            src={`./products_images/${item.rdre}.jpg`}
                            title={item.description}
                        />
                    </Box>
                    <CardContent classes={{ root: item.rdre === productRdreFromRedux ? styles.cardSelected : styles.card }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h6" color="inherit">
                                {item.product}{item.operating_mode} &middot; {item.series}
                            </Typography>
                        </div>
                        <Box mt={mq_xs ? 0 : 1}>
                            <Typography
                                variant="body2"
                                color={item.rdre === productRdreFromRedux ? "inherit" : "textSecondary"}
                            >
                                {item.product_desc}
                            </Typography>
                        </Box>
                    </CardContent>
                </CardActionArea>
                <CardActions style={{ padding: theme.spacing(2), paddingTop: 0 }} classes={{ root: item.rdre === productRdreFromRedux ? styles.cardSelected : styles.card }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                disableFocusRipple
                                color="secondary"
                                variant="contained"
                                disableElevation
                                startIcon={<DescriptionOutlined />}
                                onClick={openMediaDirectory}
                                size={mq_xs ? "small" : "medium"}
                            >
                                {rdre_five}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Table size="small" classes={{ root: styles.Table }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell classes={{ root: styles.TableCell }}>
                                            <Typography variant="subtitle1" color="primary"
                                                style={{ fontWeight: 600 }}
                                            >
                                                Size
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                size={mq_xs ? "small" : "medium"}
                                                variant="text"
                                                color="default"
                                                endIcon={<ArrowDropDownOutlined />}

                                                onClick={(e) => handleClick(e, item.rdre)}
                                            >
                                                <Typography variant="subtitle1" color="primary"
                                                    style={{ fontWeight: 600 }}
                                                >
                                                    {size
                                                        ? <span>{size}</span>
                                                        : 'Choose size'
                                                    }
                                                </Typography>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>

                </CardActions>
            </Card>
        </React.Fragment>
    )

}

export default ProductCard;