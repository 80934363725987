import React, { useEffect, useState } from 'react';
import { Drawer, List, Typography, Box, makeStyles, useTheme, Button, Hidden } from '@material-ui/core';
import { useSelector } from 'react-redux';
import './style.css'
import AlertSpecialSelected from '../../../../../components/UI/AlertSpecialSelected/AlertSpecialSelected';
import AuthorizationDrawer from '../../../../../components/Configuration/Discount/AuthorizationDrawer/AuthorizationDrawer';
import TitleBox from '../../../../../components/TitleBox';
import { formatPriceValue } from '../../../../../utils/Utils';




const PriceDrawer = ({ whichRole, mergedResults, results, basePrice, currentView, show, calculatedDiscount, openPriceDrawer, setOpenPriceDrawer, isMobile }) => {

    const [totalPartial, setTotalPartial] = useState({ net: 0, gross: 0 })
    const [openForMobile, setOpenForMobile] = useState(true)

    const listNet = mergedResults.filter((e) => e.value && !e.value.is_gross && e.value.price !== 0 && e.action !== 'hide').map((e) => {
        if (e.position_desc) {
            e.value.position_desc = e.position_desc;

        }
        return e.value;
    });


    const listGross = mergedResults.filter((e) => e.value && e.value.is_gross && e.value.price !== 0 && e.action !== 'hide').map((e) => {
        if (e.position_desc) {
            e.value.position_desc = e.position_desc;
        }
        return e.value;
    });



    useEffect(() => {

        // checkIfPriceIsCalculable()
        const copyObj = { ...totalPartial };
        const netTotalPrice = listNet.reduce((accumulator, currentValue) => {

            return accumulator + parseFloat(currentValue.price);
        }, 0);
        const grossTotalPrice = listGross.reduce((accumulator, currentValue) => {

            return accumulator + parseFloat(currentValue.price);
        }, 0);
        copyObj.net = netTotalPrice;
        copyObj.gross = grossTotalPrice;
        setTotalPartial(copyObj);
    }, [results, basePrice]);

    useEffect(() => {
        if (!isMobile) {
            setOpenForMobile(true)
        } else {
            if (openPriceDrawer) {
                setOpenForMobile(true)
            } else {
                setOpenForMobile(false)
            }
        }

    }, [isMobile, openPriceDrawer]);




    if ((whichRole === "Admin" || whichRole === "Internal" || whichRole === "VIP") && show) {
        return (
            <>

                <div style={isMobile ? { width: '100%', height: openForMobile ? '100%' : '0px' } : {}} className='price-drawer'>
                    {isMobile && <div style={{ padding: '0 15px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}><p style={{ margin: '0', fontSize: '20px', fontWeight: '600', cursor: 'pointer' }} onClick={() => { setOpenPriceDrawer(false) }}>X</p></div>}
                    <div style={{ marginBottom: '10px' }}>
                        <div style={{ padding: '0 15px' }}>
                            {currentView === 'discount' ? <AuthorizationDrawer calculatedDiscount={calculatedDiscount} totalDefaultPrice={mergedResults.reduce((sum, val) => sum + (+val?.value?.price || 0), 0) + basePrice} listNet={listNet.reduce((accumulatore, oggetto) => accumulatore + oggetto.price, 0)} /> : <TitleBox isMobile={isMobile} title={'Price Info'} />}
                        </div>
                        <>
                            <div style={{ padding: '0 15px', borderBottom: '1px solid #bfcad1' }}>
                                <h2>Net Price: {formatPriceValue(totalPartial?.net)}</h2>
                                {listNet.map((net, indexNet) => {
                                    return (
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={!net.in_datasheet ? { color: '#00ccff' } : {}} key={indexNet}>{net.position_desc ? net.position_desc : net.accessory}: </p>
                                            <p style={!net.in_datasheet ? { color: '#00ccff' } : {}}>{formatPriceValue(net?.price)}</p> </div>

                                    )
                                })}
                            </div>
                            <div style={{ padding: '0 15px' }}>
                                <h2 style={!basePrice || basePrice === 0 ? { color: 'red' } : {}}>Gross Price: {formatPriceValue(totalPartial?.gross + basePrice)}</h2>
                                {listGross.map((gross, indexGross) => {
                                    return (
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={!gross.in_datasheet ? { color: '#00ccff' } : {}} key={indexGross}>{gross?.position_desc ? gross?.position_desc : gross.accessory}: </p>
                                            <p style={!gross.in_datasheet ? { color: '#00ccff' } : {}}>{formatPriceValue(gross?.price)}</p> </div>
                                    )
                                })}
                            </div>
                            <div style={{ padding: '0 20px', borderBottom: '1px solid #bfcad1', display: 'flex', justifyContent: 'space-between' }}>
                                <h2 style={{ fontSize: '13px', marginTo: '0', fontWeight: '600', color: !basePrice || basePrice === 0 ? 'red' : '#002b49' }}>Base price: </h2>
                                <h2 style={{ fontSize: '13px', marginTo: '0', fontWeight: '600', color: !basePrice || basePrice === 0 ? 'red' : '#002b49' }}>{formatPriceValue(basePrice)}</h2>
                            </div>
                        </>
                    </div>
                    {(!basePrice || basePrice === 0) && <AlertSpecialSelected />}
                </div>
            </>
        )
    } else {
        return null
    }

}

export default PriceDrawer;