import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, Divider, IconButton, InputAdornment, List, ListItem, ListItemIcon, makeStyles, TextField, Typography } from '@material-ui/core'
import { useMqXS } from '../../../../../../../utils/Utils';
import { AddOutlined, CheckOutlined, SearchOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { SET_DISCOUNT_INFO, SET_MANUAL_DISCOUNT_CLASS, SET_CUSTOMER_TYPE } from '../../../../../../../store/actions/actions';



const useStyles = makeStyles(theme => ({
    DialogContent: {
        padding: 0
    }
}));

const DialogOptionList = ({ activeId, data, open, handleClose, onClick, setOptionSelected, setActiveId, setOptionValue, setCustomerClassValue, setIsCustom, type, isCustom, isForClass }) => {


    const mq_xs = useMqXS();
    const styles = useStyles();
    const dispatch = useDispatch();
    const [filterValue, setFilterValue] = useState('');
    const [filterData, setFilterData] = useState([]);
    const discountInfo = useSelector(state => {
        return state.configuration.discountInfo
    });

    console.log('DATALOZ', isForClass);
    const handleClick = (optionSelected, optionValue, id) => {




        if (Object.keys(optionSelected)[1] === 'customer') {
            if (optionSelected.id_customer) {

                setIsCustom(false);
            } else {
                onClick();
            }

        }

        let array = discountInfo ? [...discountInfo] : [];
        const obj = {
            type: Object.keys(optionSelected)[1],
            optionSelected,
            optionValue

        }
        let found = false;
        array = array.map(item => {
            if (item.type === obj.type) {
                found = true;
                return obj; // Sostituisce l'oggetto con lo stesso 'type'
            }
            return item;
        });

        if (!found) {
            array.push(obj);
        }


        if (!optionSelected.customer_type) {
            console.log('non entro');
            if (!optionSelected.erp_discount_class) {

                setOptionSelected(optionSelected); setActiveId(id); setOptionValue(optionValue)
                setCustomerClassValue(null)
                dispatch({ type: SET_MANUAL_DISCOUNT_CLASS, value: null });

            } else {

                setCustomerClassValue(optionValue);
                const customObj = {
                    customer: filterValue,
                    manualDiscountClass: optionValue
                }
                dispatch({ type: SET_MANUAL_DISCOUNT_CLASS, value: customObj });

                dispatch({ type: SET_DISCOUNT_INFO, value: null });

                setOptionSelected(null)


            }
        } else {
            setActiveId(id);
        }
        if (optionSelected.customer_type) {
            dispatch({ type: SET_CUSTOMER_TYPE, value: optionSelected, id: optionSelected?.id })
        }
        handleClose();
        dispatch({ type: SET_DISCOUNT_INFO, value: array });
    }

    useEffect(() => {
        setFilterData(data);

    }, [data]);


    useEffect(() => {
        const obj = data?.filter((elemento) => {
            // Controlla se la parola chiave è inclusa in una delle proprietà dell'oggetto (ignorando maiuscole/minuscole)
            for (let chiave in elemento) {
                if (elemento.hasOwnProperty(chiave) && typeof elemento[chiave] === 'string') {
                    if (elemento[chiave].toLowerCase().includes(filterValue.toLowerCase())) {
                        return true;
                    }
                }
            }
            return false;
        });

        setFilterData(obj)

    }, [filterValue]);


    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            fullScreen={mq_xs}
            open={open}
            onClose={handleClose}
        >
            <Box
                py={2}
                px={3}
            >
                <TextField
                    autoFocus
                    fullWidth
                    size={mq_xs ? 'small' : 'medium'}
                    variant="outlined"
                    placeholder="Search"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchOutlined /></InputAdornment>,
                        endAdornment: <InputAdornment position="end">
                            <IconButton color="primary" onClick={() => { setOptionValue(filterValue); handleClose(); handleClick({ [`id_${type}`]: null, [type]: filterValue }, filterValue, null) }}>
                                <AddOutlined color="inherit" />
                            </IconButton>
                        </InputAdornment>
                    }}
                    value={filterValue}
                    onChange={(e) => { setFilterValue(e.target.value); }}
                />
            </Box>
            <DialogContent dividers classes={{ root: styles.DialogContent }}>
                <List>
                    {filterData?.map((c, index) => {
                        const id = c.id_customer || c.id_sales_engineer || c.id_branch_manager || c.id
                        const value = c.customer || c.sales_engineer || c.branch_manager || c.erp_discount_class || c.customer_type
                        return (
                            <div key={index}>
                                {/* <ListItem button onClick={() => handleSelectCustomer(c)}> */}
                                <ListItem button onClick={() => { handleClick(c, value, id); }}>
                                    {activeId === id
                                        ? <ListItemIcon><CheckOutlined color="primary" /></ListItemIcon>
                                        : <React.Fragment>
                                            {activeId
                                                ? <ListItemIcon></ListItemIcon>
                                                : null
                                            }
                                        </React.Fragment>
                                    }
                                    <Box py={1} px={2}>
                                        <Typography variant="body1" color="primary">{value}</Typography>
                                    </Box>
                                </ListItem>
                                <Divider orientation="horizontal" component="li" />
                            </div>
                        )
                    })}
                </List>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'flex-start' }}>
                <Box px={2} py={1}>
                    <Typography variant="body2" align="left" color="primary">{filterData?.length} {filterData?.length === 1 ? 'record' : 'records'}</Typography>
                </Box>
            </DialogActions>
        </Dialog>
    )

}

export default DialogOptionList;