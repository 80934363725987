import { AppBar, Badge, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Hidden, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core'
import { CloseOutlined, FilterListOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { SET_FILTERS_DATA } from '../../../store/actions/archive/actions';
import { useAxios } from '../../../utils/useAxios';
import { useMqXS } from '../../../utils/Utils'
import ErrorBoundary from '../../UI/ErrorBoundary/ErrorBoundary';
import MyConfigurationsButton from '../MyConfigurationsButton/MyConfigurationsButton';
import ApplyFiltersButton from './ApplyFiltersButton/ApplyFiltersButton';
import ArchiveFiltersContent from './ArchiveFiltersContent/ArchiveFiltersContent';
import RemoveAllFiltersButton from './RemoveAllFiltersButton/RemoveAllFiltersButton';
import { useGetProductsQuery } from "../../../utils/useGetProductsQuery";
import {useRoleDesc} from "../../../utils/useRoleDesc";

const useStyles = makeStyles(theme => ({
    DialogTitle: {
        backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		display: 'flex',
		alignItems: 'center',
		height: theme.spacing(8),
		[theme.breakpoints.down("sm")]: {
			padding: theme.spacing(2),
			height: theme.spacing(7),
		}
    },
    AppBar: {
		borderBottom: `1px solid ${theme.palette.standard.main}`,
	},
    DialogContent: {
		padding: 0,
		[theme.breakpoints.down("sm")]: {
			padding: theme.spacing(2)
		}		
	},
}))

const ArchiveFilters = () => {

    const styles = useStyles();
    const mq_xs = useMqXS();
    const axios = useAxios();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);

    const filters = useSelector(state => {
        return state.archive.filters;
    });

    

    const badge_invisible = filters ? filters.filter(x => x.selected).length === 0 : true;

    const setFiltersData = (objData) => dispatch({type: SET_FILTERS_DATA, value: objData.value});

    const whichRole = useRoleDesc();
    const filterResults = useGetProductsQuery(whichRole);

    const handleClick = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
       

        if (!filters && filterResults.length) {
           
            // setFiltersData({value: filterResults});
            dispatch({type: SET_FILTERS_DATA, value: filterResults})
           

            // axios.get('product/getproductslist')
            // .then(response => {
            //     setFiltersData({value: response.data});
            // })
            // .catch(error => {
            //     setError(error.response.status);
            // });

        }

    }, [filterResults, filters]);

    return (
        <>  

            <MyConfigurationsButton />

            <Box mr={1}>
                <Badge variant="dot" invisible={badge_invisible} color="error">
                    <Button
                        color={"secondary"}
                        variant="contained"
                        size={mq_xs ? 'small' : 'medium'}
                        startIcon={filters ? <FilterListOutlined /> : null}
                        onClick={handleClick}
                        disabled={!filters}
                    >
                        {filters
                            ?   "Filter"
                            :   <CircularProgress color="inherit" size={24} />
                        }
                    </Button>
                </Badge>
            </Box>

            <Dialog
                fullScreen
                fullWidth
                open={open}
                onClose={handleClose}
            >
                <DialogTitle
                    disableTypography
                    classes={{root: styles.DialogTitle}}
                >
                    <Typography
                        variant={mq_xs ? "body1": "h6"}
                        color="inherit"
                    >
                        Filter
                    </Typography>
                    <Box ml={'auto'}>
                        <IconButton color="inherit" onClick={handleClose}>
                            <CloseOutlined />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent
                    dividers
                    classes={{root: styles.DialogContent}}
                >

                    <ErrorBoundary
                        error={error}
                    >
                        
                        <Hidden smDown>

                            <AppBar
                                color="transparent"
                                elevation={0}
                                position="relative"
                                classes={{root: styles.AppBar}}
                            >
                                <Toolbar>

                                    <Container>

                                    <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>

                                        <Box mr={1}>
                                            <RemoveAllFiltersButton />
                                        </Box>

                                        <ApplyFiltersButton 
                                            handleClose={handleClose}
                                        />

                                    </Box>

                                    </Container>

                                </Toolbar>
                            </AppBar>

                        </Hidden>

                        <ArchiveFiltersContent />

                    </ErrorBoundary>
                    
                </DialogContent>
                <Hidden smUp>
                    <DialogActions>

                        <RemoveAllFiltersButton />

                        <ApplyFiltersButton
                            handleClose={handleClose}
                        />

                    </DialogActions>
                </Hidden>
            </Dialog>
        </>
    )
}

export default ArchiveFilters