import { Box, Button, Hidden } from '@material-ui/core'
import { EuroOutlined } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'


const CheckPriceButton = ({ mergedResults, params, role, isSpecialField, specialSelected, isMobile }) => {

    const history = useHistory();
    const [disabled, setDisabled] = useState(true);

    const filteredResults = mergedResults.filter(obj =>
        obj.action === 'mandatory' || !obj.hasOwnProperty('action')
    );


    const handleClick = () => {
        // history.push('/configuration/discount/' + params.rdre + '/' + params.size)
        if (role === 'Admin' || role === 'Internal' || role === 'VIP') {
            if (!params.currentView) {
                history.push('/configuration/' + params.rdre + '/' + params.size + '/discount')
            } else {
                history.push('/configuration/' + params.rdre + '/' + params.size + '/discount/' + params.id_configuration)
            }
        } else {
            if (!params.currentView) {
                history.push('/configuration/' + params.rdre + '/' + params.size + '/summary')
            } else {
                history.push('/configuration/' + params.rdre + '/' + params.size + '/summary/' + params.id_configuration)
            }
        }
    }

    useEffect(() => {

        if (!filteredResults.every(elemento => 'value' in elemento)) {
            setDisabled(true);
        } else {
            if (isSpecialField) {
                if (!specialSelected) {
                    setDisabled(true)
                } else {
                    setDisabled(false)
                }

            } else {
                setDisabled(false)
            }
        }




    }, [filteredResults, isSpecialField, specialSelected]);


    return (
        <>
            {isMobile ? <div>
                {/* {mobile} */}
                <Button
                    disabled={disabled}
                    color="inherit"
                    variant="contained"
                    size="small"
                    startIcon={<EuroOutlined />}
                    onClick={handleClick}
                >
                    Check price
                </Button>
            </div> : <div >
                {/* {desktop} */}
                <Box mx={1}>
                    <Button
                        disabled={disabled}
                        color="inherit"
                        variant="contained"
                        startIcon={<EuroOutlined />}
                        onClick={handleClick}
                    >
                        Check price
                    </Button>
                </Box>
            </div>}

        </>
    )
}

export default CheckPriceButton