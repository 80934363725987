import { Box, IconButton, makeStyles, Menu, Typography } from '@material-ui/core';
import { AccountCircleOutlined, BusinessOutlined } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAxios } from '../../../../utils/useAxios';
import LoadingBar from '../../LoadingBar/LoadingBar';
import jwtDecode from 'jwt-decode';
import { useMqXS } from '../../../../utils/Utils';
import { SET_TERMS_CONDITIONS, USER_UNAUTHRIZED } from '../../../../store/actions/appActions';
import TermsConditions from '../../../Home/TermsConditions/TermsConditions.jsx';
import { useRoleDesc } from '../../../../utils/useRoleDesc';

const useStyles = makeStyles(theme => ({
    userBtn: {
        border: `2px solid ${theme.palette.primary.main}`
    },
    userInfo: {
        display: 'flex',
        alignItems: 'center'
    }
}))

const LoginButton = () => {


    const styles = useStyles();
    const mq_xs = useMqXS();
    const axios = useAxios();
    const dispatch = useDispatch();
    const role = useRoleDesc();


    const setUnauthorized = useCallback(() => dispatch({ type: USER_UNAUTHRIZED }), [dispatch]);
    const setTermsConditions = useCallback((objData) => dispatch({ type: SET_TERMS_CONDITIONS, value: objData.value }), [dispatch]);

    const is_authenticated = useSelector(state => {
        return state.application.is_authenticated;
    });

    const id_token = useSelector(state => {
        return state.application.tokens?.id_token;
    });

    const [anchorEl, setAnchorEl] = useState(null);
    const [userInitials, setUserInitials] = useState(null);
    const [givenName, setGivenName] = useState(null);
    const [familyName, setFamilyName] = useState(null);
    const [company, setCompany] = useState(null);
    const [country, setCountry] = useState(null);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
        getUserInfo();
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getUserInfo = async () => {


        if (role === 'External') {
            try {
                const result = await axios.get('user/infocustomer');
                setCompany(result.data.customer_desc)
            } catch (e) {
                console.log(e);
            }
        } else {
            setCompany('Bosch Rexroth')
            
        }
    }

    useEffect(() => {

        if (id_token) {


            const decoded = jwtDecode(id_token);

            setGivenName(decoded.given_name);
            setFamilyName(decoded.family_name);
            setCountry(decoded.country);


            const initials = decoded.given_name[0].toUpperCase() + decoded.family_name[0].toUpperCase();

            setUserInitials(initials);

        }

    }, [id_token]);

    // useEffect(() => {

    //    if (is_authenticated && ) {

    //          axios.get('user/userinfo')
    //          .then(response => {
    //              setTermsConditions({value: response.data.terms_accepted});
    //         })
    //          .catch(error => {
    //             setUnauthorized();
    //          });

    //      }

    // }, [is_authenticated, axios, company, setCompany, setTermsConditions, setUnauthorized]);

    return (
        <>
            {is_authenticated
                ? <>

                    <TermsConditions />

                    <Box>
                        <IconButton
                            classes={{ root: styles.userBtn }}
                            color="primary"
                            onClick={handleClick}
                        >
                            <Typography variant="body2">{userInitials}</Typography>
                        </IconButton>
                    </Box>

                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <Box p={2}>
                            <Typography
                                component={'span'}
                                variant="body1"
                                color="primary"
                            >
                                <Box display={'flex'} alignItems={'center'}>
                                    <Box display={'flex'} alignItems={'center'} mr={1}>
                                        <AccountCircleOutlined color="inherit" />
                                    </Box>
                                    <Box>
                                        {givenName} {familyName}
                                    </Box>
                                </Box>
                            </Typography>
                            <Box mt={1}>
                                <Typography
                                    component={'span'}
                                    variant="body1"
                                    color="primary"
                                >
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Box display={'flex'} alignItems={'center'} mr={1}>
                                            <BusinessOutlined color="inherit" />
                                        </Box>
                                        <Box>
                                            {!company
                                                ? <LoadingBar />
                                                : company
                                            }   
                                            
                                        </Box>
                                        -
                                        <Box>
                                            {!country
                                                ? <LoadingBar />
                                                : country
                                            }
                                        </Box>
                                    </Box>
                                </Typography>
                            </Box>
                        </Box>
                    </Menu>

                </>

                : <IconButton
                    size={mq_xs ? 'medium' : 'medium'}
                    color="default"
                >
                    <AccountCircleOutlined color="inherit" />
                </IconButton>
            }
        </>

    )

}

export default LoginButton;