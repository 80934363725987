import React from 'react'
import './style.css'


const TitleBox = ({ title, style, isMobile }) => {
    return (
        <div style={style} className="title-container">
            <h5 style={isMobile ? { fontSize: '1rem' } : {}}>{title}</h5>
        </div>
    )
}

export default TitleBox