import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, TextField } from '@material-ui/core';

const SpecialField = ({ isSpecialSelected, readonly }) => {
    const dispatch = useDispatch();
    const specialRequestFromRedux = useSelector(state => {
        return state?.configuration?.specialSelected
    });

    const [requiredBorderStyle, setRequiredBorderStyle] = useState('');


    const [specialRequest, setSpecialRequest] = useState(null);


    useEffect(() => {
        // Quando il componente si smonta e isSpecialSelected diventa false, resetta il valore in Redux

        if (!isSpecialSelected) {
            dispatch({ type: 'RESET_SPECIAL_REQUEST' });
            setSpecialRequest(null) // Sostituisci 'RESET_SPECIAL_REQUEST' con il tuo tipo d'azione
        }

    }, [isSpecialSelected]);
    useEffect(() => {
        // Quando il componente si smonta e isSpecialSelected diventa false, resetta il valore in Redux

        if (specialRequestFromRedux) {
            setSpecialRequest(specialRequestFromRedux)
            setRequiredBorderStyle('none');
        } else {
            setRequiredBorderStyle('1px solid red');
        }

    }, [specialRequestFromRedux]);

    const handleChange = (e) => {
        const value = e.target.value;
        setSpecialRequest(value);
        if (isSpecialSelected) {
            // Se isSpecialSelected è true, fai la dispatch per salvare il valore in Redux
            dispatch({ type: 'SET_SPECIAL_REQUEST', value: value }); // Sostituisci 'SAVE_SPECIAL_REQUEST' con il tuo tipo d'azione
        }
    };

    return (
        <>
            {isSpecialSelected ? (
                <Box mt={2}>
                    <TextField
                        style={{ border: requiredBorderStyle }}
                        value={specialRequest ? specialRequest : ''}
                        disabled={readonly}
                        onChange={handleChange}
                        onFocus={() => { setRequiredBorderStyle('none') }}
                        onBlur={() => { if (!specialRequest) { setRequiredBorderStyle('1px solid red') } else { setRequiredBorderStyle('none') } }}
                        required
                        fullWidth
                        label="Special request"
                        placeholder="You selected a special version. Please specify here your special requests."
                        rows={3}
                        multiline
                        variant="outlined"
                    />
                </Box>
            ) : null}
        </>
    );
};

export default SpecialField;