import React, { useState, useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import "./style.css";
import DiscountBox from "./components/DiscountBox";
import { useDispatch, useSelector } from "react-redux";
import { useAxios } from "../../../../../utils/useAxios";
import {
  useCheckAuthorization,
  useValidationDiscount,
} from "../../../../../utils/Utils";
import PercentageBox from "./components/PercentageBox";
import { SET_DISCOUNT } from "../../../../../store/actions/actions";
import ProductApplicationBox from "./components/ProductApplicationBox";
import AdminView from "../../../../../components/Configuration/Discount/AdminView/AdminView";
import ExistingCodeBox from "./components/ExistingCodeBox";
import ExtraPercentageBox from "./components/ExtraPercentageBox";

const DiscountPage = ({
  data,
  params,
  modelCode,
  results,
  accessories,
  basePrice,
  mergedResults,
  readonly,
  whichRole,
  country,
  isMobile,
}) => {
  const axios = useAxios();
  const dispatch = useDispatch();
  const validation = useValidationDiscount(country);
  const discountResults = useSelector((state) => {
    return state.configuration.discount;
  });
  const calculatedDiscount = useSelector((state) => {
    return state?.configuration?.calculatedDiscount;
  });
  const customCustomer = useSelector((state) => {
    return state?.configuration?.customCustomer;
  });
  const customerType = useSelector((state) => {
    return state?.configuration?.discount?.customer_type;
  });
  const discountInfo = useSelector((state) => {
    return state?.configuration?.discountInfo;
  });

  const [optionSelected, setOptionSelected] = useState();
  console.log('optionSelected', optionSelected);
  // const [discountResults, setDiscountResults] = useState(discountFromRedux ? discountFromRedux : null);

  const getDiscount = async () => {
    const body = {
      customer: customCustomer?.customer || discountInfo?.find((e) => e.type === 'customer').optionSelected?.customer?.split(" - ")[0],
      id_customer: discountInfo?.find((e) => e.type === 'customer').optionSelected?.id_customer || null,
      rdre: params.rdre,
      size: params.size,
      id_customer_type: customerType?.id,
      type_code: modelCode?.replace(/%/g, ""),
      manual_discount_class: customCustomer?.manualDiscountClass,
      product_discount_list: results
        .filter((s) => s?.value?.price > 0 && s?.value?.is_gross)
        .map((s) => {
          return {
            value: s.value.value,
            position: s.position,
          };
        }),
      accessory_discount_list: accessories
        .filter((a) => a?.value?.price > 0 && a?.value?.is_gross)
        .map((a) => {
          return {
            value: a.value.value,
            family: a.family,
            accessory: a.accessory,
          };
        }),
    };

    try {
      const result = await axios.post("discount/GetDiscountConfidential", body);
      if (result.data) {
        dispatch({ type: SET_DISCOUNT, value: { ...discountResults, ...result.data } });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // if (["IT", "BE"].indexOf(country) === -1) return;
    if (country === 'IT' || country === 'BE') {
      if (
        (optionSelected && optionSelected.customer) ||
        customCustomer?.customer
      ) {
        //console.log('non devo entrare', (optionSelected && optionSelected.customer), customCustomer);
        getDiscount();
      }
    } else {
      if (
        (customerType && !optionSelected?.sales_engineer && !optionSelected?.branch_manager)
      ) {
        //console.log('non devo entrare', (optionSelected && optionSelected.customer), customCustomer);
        getDiscount();
      }
    }
  }, [optionSelected, customCustomer, customerType]);

  return (
    <div>
      {readonly && !discountResults ? (
        <></>
      ) : (
        <Container
          style={{
            paddingTop: "30px",
            backgroundColor: "white",
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            <ExistingCodeBox readonly={readonly} modelCode={modelCode} />
            <DiscountBox
              isMobile={isMobile}
              country={country}
              readonly={readonly}
              setOptionSelected={setOptionSelected}
              data={discountResults}
              options={data?.customer_list}
              customerTypeOptions={data?.customer_type_list}
              title={"Customer"}
              type={"customer"}
              params={params}
            />
            <DiscountBox
              isMobile={isMobile}
              country={country}
              readonly={readonly}
              setOptionSelected={setOptionSelected}
              data={discountResults}
              options={data?.sales_engineer_list}
              title={"Sales Engineer"}
              type={"sales_engineer"}
            />
            <PercentageBox
              isMobile={isMobile}
              calculatedDiscount={calculatedDiscount}
              readonly={readonly}
              type={"standard"}
              data={discountResults}
              country={country}
              basePrice={basePrice}
              totalPrice={mergedResults
                .filter((v) => v?.value?.is_gross)
                .reduce((sum, val) => sum + (+val?.value?.price || 0), 0)}
            />

            <ExtraPercentageBox
              isMobile={isMobile}
              calculatedDiscount={calculatedDiscount}
              readonly={readonly}
              data={discountResults}
              country={country}
              basePrice={basePrice}
              totalPrice={mergedResults
                .filter((v) => v?.value?.is_gross)
                .reduce((sum, val) => sum + (+val?.value?.price || 0), 0)}
            />
            <ProductApplicationBox
              validation={validation?.est_annual_qty}
              readonly={readonly}
            />
            <DiscountBox
              isMobile={isMobile}
              country={country}
              readonly={readonly}
              isNeeded={validation?.branch_manager?.required}
              setOptionSelected={setOptionSelected}
              data={discountResults}
              options={data?.branch_manager_list}
              title={"Branch Manager"}
              type={"branch_manager"}
            />
            {(whichRole === "Admin" || whichRole === "VIP") && (
              <AdminView mergedResults={mergedResults} country={country} />
            )}
          </Grid>
        </Container>
      )}
    </div>
  );
};

export default DiscountPage;
