import React, { useState, useEffect } from 'react';
import { Box, Divider, Drawer, List, Typography, Table, TableBody, TableCell, TableRow, makeStyles, Hidden } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { formatPriceValue, isSpecialSelected, useCheckAuthorization } from '../../../../utils/Utils';
import AuthorizationTitle from '../../../UI/AuthorizationTitle/AuthorizationTitle';
import { SET_CALCULATED_DISCOUNT } from '../../../../store/actions/actions';

const useStyles = makeStyles(theme => ({
    Drawer: {
        width: '336px'
    },
    DrawerPaper: {
        width: '336px',
        top: '129px',
        borderLeft: `1px solid ${theme.palette.primary25.main}`
    },
    List: {
        width: '100%',
        maxWidth: '336px'
    },
    FirstColumn: {
        width: '33.33%',
        borderRight: `1px solid ${theme.palette.primary25.main}`,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1)
        }
    },
    TableCell: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1)
        }
    }
}));

const AuthorizationDrawer = ({ listNet, totalDefaultPrice, calculatedDiscount }) => {


    const styles = useStyles();
    const dispatch = useDispatch();
    // const specsSelected = useSelector(state => {
    //     return state.configuration.specsSelected;
    // });

    // const accSelected = useSelector(state => {
    //     return state.configuration.accessoriesSelected;
    // });

    const [total, setTotal] = useState();

    const [discountedPrice, setDiscountedPrice] = useState();

    useEffect(() => {

        if (calculatedDiscount) {
            if (calculatedDiscount?.extra_discount && calculatedDiscount?.extra_discount !== 0) {
                setTotal(listNet + calculatedDiscount?.extra_discounted_price)
                setDiscountedPrice(calculatedDiscount?.extra_discounted_price)
            } else {
                if (calculatedDiscount && calculatedDiscount?.sales_manual_discount === 0) {
                    setTotal(calculatedDiscount?.sales_net_price)
                    setDiscountedPrice(0)
                } else {
                    setTotal(listNet + calculatedDiscount?.sales_net_price)
                    setDiscountedPrice(calculatedDiscount?.sales_net_price)
                }
            }
        }

    }, [calculatedDiscount]);

    useEffect(() => {
        const updatedObj = { ...calculatedDiscount };
        updatedObj.finalPrice = total;
        dispatch({ type: SET_CALCULATED_DISCOUNT, value: updatedObj });

    }, [total]);





    // const is_special_selected = isSpecialSelected(specsSelected);

    return (
        <>
            {/* <div >
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <AuthorizationTitle />

                    <Typography variant="body1" color="primary">
                        Total: {formatPriceValue(listNet)}
                    </Typography>
                </Box>
            </div> */}
            <div >
                {/* <Drawer
                    classes={{ root: styles.Drawer, paper: styles.DrawerPaper }}
                    variant={'permanent'}
                    open={true}
                    anchor="right"
                > */}
                {/* <div style={{display: 'flex', justifyContent: 'flex-end', padding: '0 20px'}}> <p style={{cursor: 'pointer'}} onClick={()=>{setOpenDrawer(false)}}>X</p></div> */}
                <Box

                    mb={2}
                >
                    <AuthorizationTitle />
                </Box>
                <Box

                >
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell classes={{ root: styles.FirstColumn }}>
                                    <Typography variant="body1" color="primary">
                                        Net
                                    </Typography>
                                </TableCell>
                                <TableCell classes={{ root: styles.TableCell }}>
                                    <Typography variant="body1" color="primary">
                                        {formatPriceValue(listNet)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell classes={{ root: styles.FirstColumn }}>
                                    <Typography variant="body1" color="primary">
                                        Discounted
                                    </Typography>
                                </TableCell>
                                <TableCell classes={{ root: styles.TableCell }}>
                                    <Typography variant="body1" color="primary">
                                        {/* {(discountPrices && discountPrices?.perc === null) ? formatPriceValue(0) : formatPriceValue(discountPrices?.value)} */}
                                        {formatPriceValue(discountedPrice)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell classes={{ root: styles.FirstColumn }}>
                                    <Typography variant="body1" color="primary">
                                        Total
                                    </Typography>
                                </TableCell>
                                <TableCell classes={{ root: styles.TableCell }}>
                                    <Typography variant="body1" color="primary">
                                        {total ? formatPriceValue(total) : formatPriceValue(totalDefaultPrice)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>

                <Divider orientation="horizontal" />

                {/* <List classes={{ root: styles.List }}>
                        {checkIfPriceIsCalculable()
                            ? <div>
                                <NetList specs={listNet} acc={listAccNet} />
                                <GrossList specs={listGross} acc={listAccGross} />
                            </div>
                            : <Box mt={2}>
                                <AlertSpecialSelected />
                            </Box>
                        }
                    </List> */}
                {/* </Drawer> */}
            </div>
        </>
    )

}

export default AuthorizationDrawer;