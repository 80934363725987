import * as actions from '../../actions/ProductSelector/actions';

const initialState = {
    open: false,
    count: {
        standard: 0,
        goTo: 0,
        customer: 0
    },
    type: null,
    section: 'lists',// lists, details, compare
    lists: {
        standard: {
            loaded: false,
            data: []
        },
        goTo: {
            loaded: false,
            data: []
        },
        customer: {
            loaded: false,
            data: []
        }
    },
    material: {
        code: null,
        class: null,
        description: null,
        specsList: null
    },
    materialSelected: {
        code: null,
        description: null
    },
    compareProducts: {
        slots: []
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_PRODUCT_TYPE_LIST:
            return {
                ...state,
                lists: {
                    ...state.lists,
                    [action.fieldName]: {
                        loaded: true,
                        data: action.value
                    }
                }
            }
        case actions.SET_MATERIAL_MODAL:
            return {
                ...state,
                material: {
                    ...state.material,
                    code: action.code,
                    class: action.class,
                    description: action.description,
                    specsList: action.specsList
                }
            }
        case actions.SET_MATERIAL_SELECTED:
            return {
                ...state,
                materialSelected: {
                    ...state.materialSelected,
                    code: action.code,
                    description: action.description
                }
            }
        case actions.ADD_MATERIAL_TO_SLOT:
            return {
                ...state,
                compareProducts: {
                    ...state.compareProducts,
                    slots: [...state.compareProducts.slots, {listType: action.listType, code: action.value}]
                }
            }
        case actions.REMOVE_MATERIAL_FROM_SLOT:
            return {
                ...state,
                compareProducts: {
                    ...state.compareProducts,
                    slots: [...state.compareProducts.slots.filter(m => m.code !== action.value)]
                }
            }
        case actions.RESET_COMPARE_SLOTS:
            return {
                ...state,
                compareProducts: {
                    ...state.compareProducts,
                    slots: []
                }
            }
        case actions.CLEAR_MATERIAL_SELECTED:
            return {
                ...state,
                materialSelected: {
                    ...state.materialSelected,
                    code: null,
                    description: null
                }
            }
        case actions.SET_MATERIAL_SELECTED_FROM_ARCHIVE:
            return {
                ...state,
                materialSelected: {
                    ...state.materialSelected,
                    code: action.material,
                    description: action.material_desc
                }
            }
        default:
            break;
    }
    return state;
}

export default reducer;