import { Button } from '@material-ui/core'
import { DoneOutlined } from '@material-ui/icons';
import React from 'react'
import { useDispatch } from 'react-redux';
import { APPLY_FILTERS } from '../../../../store/actions/archive/actions';
import { useMqXS } from '../../../../utils/Utils'

const ApplyFiltersButton = (props) => {

    const mq_xs = useMqXS();
    const dispatch = useDispatch();

    const applyFilters = () => dispatch({type: APPLY_FILTERS});

    const handleClick = () => {
        props.handleClose();
        applyFilters();
    }

    return (
        <>
            <Button
                color="inherit"
                variant="contained"
                size={mq_xs ? "small" : "medium"}
                startIcon={<DoneOutlined />}
                onClick={handleClick}
            >
                Apply
            </Button>
        </>
    )
}

export default ApplyFiltersButton