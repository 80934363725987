import { Checkbox, FormControlLabel } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import { SET_SIZE_FILTER } from '../../../../../../store/actions/archive/actions';

const SizeItem = (props) => {

    const dispatch = useDispatch();

    const setSizeSelected = (objData) => dispatch({type: SET_SIZE_FILTER, rdre: objData.rdre, value: objData.value});

    const handleSelect = () => {

        setSizeSelected({rdre: props.rdre, value: props.size});

    }

    return (
        <FormControlLabel 
            control={
                <Checkbox
                    color='primary'
                    name={props.size}
                    checked={props.selected ?? false}
                    onClick={handleSelect}
                />
            }
            label={props.size}
        />
    )
}

export default SizeItem