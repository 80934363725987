
import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAxios } from './useAxios';
import { SET_PRODUCTS_LIST } from "../store/actions/actions";

export const useGetProductsQuery = (whichRole) => {

    const dispatch = useDispatch();

    const [results, setResults] = useState([]);
    const axios = useCallback(useAxios(), []);
    const getQuery = async () => {
        if (whichRole === "Admin" | whichRole === "Internal" | whichRole === "VIP") {
            try {
                const mainResult = await axios.get('product/getallproduct')

                if (mainResult) {
                    try {
                        const partialResult = await axios.get('product/getallproductsizeconfidential')

                        if (partialResult) {
                            for (let i = 0; i < mainResult.data.length; i++) {
                                for (let j = 0; j < partialResult.data.length; j++) {
                                    if (mainResult.data[i].rdre === partialResult.data[j].rdre) {
                                        mainResult.data[i].size_list.push(partialResult.data[j]);
                                    }
                                }
                            }
                            dispatch({ type: SET_PRODUCTS_LIST, value: mainResult.data })
                            setResults(mainResult.data);
                        }


                    } catch (e) {
                        console.log(e);
                    }
                }
            } catch (error) {
                console.log(error);
            };
        } else {
            try {
                const mainResult = await axios.get('product/getallproduct')

                if (mainResult) {
                    setResults([...mainResult.data]);
                    dispatch({ type: SET_PRODUCTS_LIST, value: mainResult.data })
                }
            } catch (error) {
                console.log(error);
            };
        }

    }

    useEffect(() => {
        getQuery();
        return () => {
            setResults({});
        };
    }, []);


    return results;

}