import { Button, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core'
import { Check, SearchOutlined } from '@material-ui/icons';
import React, { useState } from 'react'



import { useMqXS } from '../../../../../../../utils/Utils';

const CustomerType = ({ readonly, onClick, customerType }) => {

    const mq_xs = useMqXS();

    // console.log('customerType', customerType);

    // const customer_direct_flag = useSelector(state => {
    //     return state.configuration.discount.customer_direct_flag;
    // });

    // const customerDirectList = useSelector(state => {
    //     return state.configuration.discount.customerDirectList;
    // });







    return (
        <>
            <Button
                size="large"
                variant="text"
                startIcon={!readonly ? <SearchOutlined color="inherit" /> : null}
                onClick={onClick}
                disabled={readonly}
            >
                {customerType
                    ? <Typography
                        variant={mq_xs ? "body1" : "h6"}
                        color="primary"
                    >
                        {/* {data?.customer_direct_flag} customer */}
                        {customerType}
                    </Typography>
                    : <Typography
                        variant={mq_xs ? "body1" : "h6"}
                        align="left"
                        color="error"
                    >
                        Customer Type
                    </Typography>
                }
            </Button>
            {/* <Menu
                id="direct-indirect-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {data?.customerDirectList?.map(option => (
                    <MenuItem
                        key={option.id}
                        onClick={() => handleClick(option)}
                    >
                        {option.text === data?.customer_direct_flag
                            ? <ListItemIcon>
                                <Check color="primary" />
                            </ListItemIcon>
                            : <>
                                {!data?.customer_direct_flag
                                    ? null
                                    : <ListItemIcon></ListItemIcon>
                                }
                            </>
                        }
                        <Typography variant="body1" color="primary">
                            {option.text} customer
                        </Typography>
                    </MenuItem>
                ))}
            </Menu> */}
        </>
    )
}

export default CustomerType