import { Checkbox, FormGroup, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import { SET_PRODUCT_FILTER } from '../../../../../store/actions/archive/actions';
import SizeItem from './SizeItem/SizeItem';

const useStyles = makeStyles(theme => ({
    ListItemText: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

const ArchiveFilterItem = (props) => {

    const styles = useStyles();
    const dispatch = useDispatch();

    const selectProductFilter = (objData) => dispatch({type: SET_PRODUCT_FILTER, value: objData.value});

    const handleSelect = () => {

        selectProductFilter({value: props.rdre});

    }

    return (
        <ListItem key={props.rdre} divider alignItems={props.selected ? 'flex-start' : 'center'}>
            <ListItemIcon color="primary">
                <Checkbox 
                    color="primary"
                    onClick={handleSelect}
                    checked={props.selected ?? false}
                />
            </ListItemIcon>
            <ListItemText disableTypography classes={{root: styles.ListItemText}}>
                <Typography variant="body1" color="primary">{props.product + props.operating_mode} &middot; {props.series}</Typography>
                {props.selected
                    ?    <>
                            <Typography variant="body2" color="inherit">Sizes</Typography>
                            <FormGroup row>
                                {props.size_list.map((s, index) => (
                                   <SizeItem key={index} rdre={props.rdre} {...s} />
                                ))}
                            </FormGroup>
                        </>
                            
                    :   null
                }
            </ListItemText>
        </ListItem>
    )
}

export default ArchiveFilterItem