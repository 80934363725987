import React, { useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Line,
  Svg,
  usePDF,
} from "@react-pdf/renderer";
import {
  CircularProgress,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import RexrothLogo from "../../assets/media/Rexroth-Logo_RGB_M.png";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import { useSelector } from "react-redux";
import { useCheckAuthorization } from "../../utils/Utils";
import { priceFormatter } from "../../utils/Utils";
import { percFormatter } from "../../utils/Utils";
import PDFConfiguration from "./PDFConfiguration";
import PDFHeaderData from "./PDFHeaderData";
import { useCountry } from "../../utils/useCountry";

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  logo: {
    width: "20%",
    marginBottom: "20px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "stretch",
    marginTop: "2px",
    marginBotton: "2px",
  },

  specstitle: {
    paddingRight: "20px",
    width: "30%",
    borderStyle: "solid 1pt",
    borderColor: "black",
  },
  specsdescription: {
    width: "70%",
    color: "#5a7c91",
    borderStyle: "solid 1pt",
    borderColor: "black",
  },
  today: {
    position: "absolute",
    right: 0,
    top: 0,
    fontSize: 12,
  },
  title: {
    marginTop: "5",
    marginBottom: "14",
    textAlign: "left",
    fontSize: "11",
    width: "50%",
  },
  flexCenter: {
    display: "flex",
    //alignItems: "center",
    flexDirection: "row",
    marginBottom: "8px",
  },
  flexSignature: {
    display: "flex",
    alignItems: "left",
    flexDirection: "row",
    marginBottom: "8px",
    marginTop: "8px",
    borderTop: "solid 3px",
    color: "RGB(255,0,0)",
  },
  rowInformation: {
    display: "flex",
    //alignItems: "center",
    flexDirection: "row",
    width: "100%",
    fontSize: 10,
  },
  subTitle: {
    marginTop: 14,
    fontSize: 9,
  },
  divider: {
    marginTop: 8,
  },
  sizeHalf: {
    width: "50%",
  },
  sizeHalf2: {
    width: "50%",
  },
  detailsWrapper: {
    fontSize: 9,
    marginBottom: "6px",
    marginTop: "6px",
  },
  specValue: {
    fontSize: 8,
    width: "70%",
  },
  downloadBtn: {
    textDecoration: "none",
    color: "inherit",
    fontFamily: "inherit",
    height: 24,
  },
  detailsPrices: {
    fontSize: 8,
    marginTop: "6px",
    marginBottom: "6px",
    marginLeft: "100px",
    fontWeight: "bold",
  },
  pricetitle: {
    paddingRight: "20px",
    width: "30%",
  },
  pricedescription: {
    //border: "solid 2px",
  },
  sizeHalfPdf: {
    fontSize: 9,
  },
  specValuePdf: {
    width: "50%",
    fontSize: 9,
  },
  row_price_custom: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 3,
    paddingBottom: 3,
    alignItems: "stretch",
    marginTop: "3px",
    marginBotton: "3px",
  },
  sizeHalfBlack: {
    width: "50%",
    fontWeight: "extrabold",
  },
  specValue_price_custom: {
    fontSize: 8,
    width: "70%",
  },
});

const useStyles = makeStyles((theme) => ({
  btnDownload: theme.btnPDF,
  loadPDF: {
    color: theme.palette.accent.main,
  },
}));

const dateOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  timeZone: "Europe/Rome",
};

const PDFInternal = ({
  data,
  model_code,
  whichRole,
  specs,
  accessories,
  country,
}) => {
  const finalPrice = useSelector((state) => {
    return state?.configuration?.calculatedDiscount?.finalPrice;
  });

  const listGross = specs.filter(
    (spec) => spec?.value?.price > 0 && spec?.value?.is_gross === true
  );
  const listAccGross = accessories.filter(
    (acc) => acc?.value?.price > 0 && acc?.value?.is_gross === true
  );

  const listNet = specs?.filter(
    (spec) =>
      spec?.value?.price > 0 &&
      (spec?.value?.is_gross === false || spec?.value?.is_gross === null)
  );
  const listAccNet = accessories?.filter(
    (acc) =>
      acc?.value?.price > 0 &&
      (acc?.value?.is_gross === false || acc?.value?.is_gross === null)
  );


  const auth_level = useCheckAuthorization(country);

  let authorization = "";

  switch (auth_level) {
    case 0:
      authorization = "None";
      break;
    case 1:
      authorization = "Cluster manager";
      break;
    case 2:
      authorization = "SLM1 o PM";
      break;
    case 3:
      authorization = "Branch";
      break;
    case 4:
      authorization = "Manager";
      break;
    case 5:
      authorization = "Internal Sales";
      break;
    case 6:
      authorization = "Sales Eng";
      break;
    case 7:
      authorization = "Sales Mngr";
      break;
    case 8:
      authorization = "General Mngr";
      break;
    default:
      authorization = "Branch";

      break;
  }
  const style = useStyles();

  // creo il PDF
  const MyPDFInternal = (
    <Document>
      <Page size="A4" orientation="portrait" style={styles.page}>
        <View>
          <Image src={RexrothLogo} style={styles.logo} />
          <Text style={styles.today}>
            {new Intl.DateTimeFormat("en-GB", dateOptions).format(new Date())}
          </Text>
        </View>
        <View style={styles.title}>
          <Text>CLASSIFICATION: {model_code?.replace(/%/g, "")}</Text>
        </View>
        <PDFHeaderData
          data={data}
          model_code={model_code}
          whichRole={whichRole}
          country={country}
        />

        <View style={styles.title}></View>
        <View style={styles.rowInformation}>
          <View style={styles.sizeHalf}>
            <View style={styles.flexCenter}>
              <Text>PRODUCT APPLICATION</Text>
              <Text style={styles.specValue}></Text>
            </View>

            <View style={styles.flexCenter}>
              <Text style={styles.sizeHalf}>Military Application</Text>
              <Text style={styles.specValue}>
                {data?.applicationInfo?.is_military ? "YES" : "NO"}
              </Text>
            </View>
            <View style={styles.flexCenter}>
              <Text style={styles.sizeHalf}>Nuclear Application</Text>
              <Text style={styles.specValue}>
                {data?.applicationInfo?.is_nuclear ? "YES" : "NO"}
              </Text>
            </View>
            <View style={styles.flexCenter}>
              <Text style={styles.sizeHalf}>Aerospace Application</Text>
              <Text style={styles.specValue}>
                {data?.applicationInfo?.is_aerospace ? "YES" : "NO"}
              </Text>
            </View>

            <View style={styles.divider}>
              <Svg width="80%" height="4">
                <Line
                  x1="0"
                  x2="1000"
                  y1="2"
                  y2="2"
                  strokeWidth="1"
                  stroke="#002b49"
                />
              </Svg>
            </View>
            <View style={styles.flexCenter}>
              <Text style={styles.sizeHalf}>AUTHORIZATION:</Text>
              <Text style={styles.specValue}> {authorization}</Text>
            </View>
            <View style={styles.detailsWrapper}>
              <View style={styles.flexSignature}>
                <Text style={{ color: auth_level === 0 ? "black" : "white" }}>
                  NO AUTHORIZATION NEEDED
                </Text>
              </View>
              <View style={styles.flexSignature}>
                <Text style={{ color: auth_level === 1 ? "red" : "white" }}>
                  CLUSTER MANAGER FOR AKNOWLEDGEMENT
                </Text>
              </View>
              <View style={styles.flexSignature}>
                <Text style={{ color: auth_level === 2 ? "red" : "white" }}>
                  SLM1 or SPM SIGNATURE
                </Text>
              </View>
              <View style={styles.flexSignature}>
                <Text style={{ color: auth_level === 3 ? "red" : "white" }}>
                  BRANCH FOR AKNOWLEDGEMENT
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.sizeHalf}>
            <View style={styles.flexCenter}>
              <Text>CUSTOMER DISCOUNT SPECIFICATION</Text>
              <Text style={styles.specValue}></Text>
            </View>
            <View style={styles.detailsWrapper}>
              <View style={styles.row}>
                <Text
                  style={{
                    ...styles.sizeHalf,
                    ...{
                      color:
                        data?.product?.base_price === 0 ||
                        !data?.product?.base_price
                          ? "red"
                          : "",
                    },
                  }}
                >
                  Gross Price
                </Text>
                <Text
                  style={{
                    ...styles.specValue,
                    ...{
                      color:
                        data?.product?.base_price === 0 ||
                        !data?.product?.base_price
                          ? "red"
                          : "",
                    },
                  }}
                >
                  {priceFormatter(
                    parseFloat(data?.discount?.product_gross_price),
                    true
                  )}
                </Text>
              </View>
            </View>
            <View style={styles.detailsPrices}>
              <View style={styles.row}>
                <Text
                  style={{
                    ...styles.sizeHalf,
                    ...{
                      color:
                        data?.product?.base_price === 0 ||
                        !data?.product?.base_price
                          ? "red"
                          : "",
                    },
                  }}
                >
                  Base Price
                </Text>
                <Text
                  style={{
                    ...styles.specValue,
                    ...{
                      color:
                        data?.product?.base_price === 0 ||
                        !data?.product?.base_price
                          ? "red"
                          : "",
                    },
                  }}
                >
                  {priceFormatter(data?.product?.base_price, true)}
                </Text>
              </View>
            </View>
            <View style={styles.detailsPrices}>
              {listGross.map((a, b) => {
                return (
                  <View style={styles.row} key={b}>
                    <Text style={styles.sizeHalf}>{a?.position_desc}</Text>
                    <Text style={styles.specValue}>
                      {priceFormatter(a?.value?.price, true)}
                    </Text>
                  </View>
                );
              })}
            </View>
            <View style={styles.detailsPrices}>
              {listAccGross?.map((c, d) => {
                return (
                  <View style={styles.row} key={d}>
                    <Text style={styles.sizeHalf}>
                      {c?.family} - {c?.accessory}{" "}
                    </Text>
                    <Text style={styles.specValue}>
                      {priceFormatter(c?.value?.price, true)}
                    </Text>
                  </View>
                );
              })}
            </View>
            <View>
              <View style={styles.row}>
                <Text style={styles.sizeHalf}>Customer Discount</Text>
                <Text style={styles.specValue}>
                  {percFormatter(data?.discount?.top_grid_discount)}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.sizeHalf}>Customer Price</Text>
                <Text style={styles.specValue}>
                  {priceFormatter(data?.discount?.top_grid_price, true)}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.sizeHalf}>Sales Limit</Text>
                <Text style={styles.specValue}>
                  {percFormatter(data?.discount?.sales_engineer_limit_discount)}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.sizeHalf}>Top Customer Discount</Text>
                <Text style={styles.specValue}>
                  {percFormatter(data?.discount?.top_grid_discount)}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.sizeHalf}>Cluster Limit</Text>
                <Text style={styles.specValue}>
                  {percFormatter(
                    data?.discount?.cluster_manager_limit_discount
                  )}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.sizeHalf}>SLM1 o PM Limit</Text>
                <Text style={styles.specValue}>
                  {percFormatter(
                    data?.discount?.product_manager_limit_discount
                  )}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.sizeHalf}>Final Standard Discount</Text>
                <Text style={styles.specValue}>
                  {data?.calculatedDiscount?.sales_manual_discount} %
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.sizeHalf}>Sales Net Price</Text>
                <Text style={styles.specValue}>
                  {priceFormatter(
                    data?.calculatedDiscount?.sales_net_price,
                    true
                  )}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.sizeHalf}>Final Discount</Text>
                <Text style={styles.specValue}>
                  {data?.calculatedDiscount?.extra_final_discount
                    ? percFormatter(
                        data?.calculatedDiscount?.extra_final_discount
                      )
                    : percFormatter(data?.calculatedDiscount?.final_discount)}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.sizeHalf}>Sales Discounted Price</Text>
                <Text style={styles.specValue}>
                  {data?.calculatedDiscount?.extra_discounted_price
                    ? priceFormatter(
                        data?.calculatedDiscount?.extra_discounted_price,
                        true
                      )
                    : priceFormatter(0, true)}
                </Text>
              </View>
            </View>

            <View style={styles.detailsWrapper}>
              <View style={styles.row}>
                <Text style={styles.sizeHalf}>Additional Net Price</Text>
                <Text style={styles.specValue}>
                  {priceFormatter(
                    [...specs, ...accessories]
                      .filter((item) => item.value && !item.value.is_gross)
                      .reduce((sum, item) => sum + item.value.price, 0),
                    true
                  )}
                </Text>
              </View>

              <View style={styles.detailsPrices}>
                {listNet.map((e, f) => {
                  return (
                    <View style={styles.row} key={f}>
                      <Text style={styles.sizeHalf}>{e?.position_desc}</Text>
                      <Text style={styles.specValue}>
                        {priceFormatter(e?.value?.price, true)}
                      </Text>
                    </View>
                  );
                })}
              </View>

              <View style={styles.detailsPrices}>
                {listAccNet?.map((g, h) => {
                  return (
                    <View style={styles.row} key={h}>
                      <Text style={styles.sizeHalf}>
                        {g?.family} - {g?.accessory}
                      </Text>
                      <Text style={styles.specValue}>
                        {priceFormatter(g?.value?.price, true)}
                      </Text>
                    </View>
                  );
                })}
              </View>

              <View style={styles.row}>
                <Text style={styles.sizeHalfBlack}>Total final price</Text>
                <Text style={styles.specValue}>
                  {priceFormatter(parseFloat(finalPrice), true)}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>

      <Page size="A4" orientation="portrait" style={styles.page}>
        <PDFConfiguration
          data={data}
          model_code={model_code}
          whichRole={whichRole}
          finalPrice={finalPrice}
          country={country}
        />
      </Page>
    </Document>
  );

  const [instance, updateInstance] = usePDF({ document: MyPDFInternal });

  useEffect(() => {
    return () => {
      updateInstance();
    };
  }, [updateInstance]);

  return (
    <React.Fragment>
      <IconButton classes={{ root: style.btnDownload }}>
        <a
          href={instance.url}
          download={`InternalData_${data?.product?.product_name}.pdf`}
          style={styles.downloadBtn}
        >
          {instance.loading ? (
            <CircularProgress classes={{ root: style.loadPDF }} size={20} />
          ) : (
            <Tooltip title="Internal PDF">
              <LocalAtmIcon />
            </Tooltip>
          )}
        </a>
      </IconButton>
    </React.Fragment>
  );
};

export default PDFInternal;
