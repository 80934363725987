import React, { useEffect, useState, useCallback } from 'react'
import { Backdrop, Box, Button, Container, Typography, TextField, Radio, RadioGroup, Modal, Dialog, FormControlLabel, FormLabel, FormControl, DialogTitle, DialogContent, IconButton, CircularProgress } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useAxios } from "../../../utils/useAxios";
import { UPDATE_SNACKBAR } from "../../../store/actions/appActions";
import { useDispatch } from "react-redux";
import { SendOutlined } from '@material-ui/icons';
import { CancelOutlined } from '@material-ui/icons';





const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: "24",
    p: "4",
    padding: "40px",
  },

  buttonInvio: {
    backgroundColor: "#00ccff",
    color: "#ffffff",
  },
  buttonAnnulla: {
    backgroundColor: "#df0024",
    color: "#ffffff",
  },

  buttonDisabled: {
    backgroundColor: "#f3f3f3",
    color: "#dadada",
  },
};




export default function RequestAssistanceForm({ handleClose = () => { }, openModal }) {
  const [sendingMail, setSendingMail] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const axios = useAxios();
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState(""); //oggetto della mail in base alla option specificata

  const [mailText, setMailText] = useState("");
  const [missing, setMissing] = useState(true);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "" || mailText === "") {
      setMissing(true);
    } else {
      setMissing(false);
    }
  };

  const handleText = (event) => {
    setMailText(event.target.value);
    if (selectedOption === "" || event.target.value === "") {
      setMissing(true);
    } else {
      setMissing(false);
    }
  };

  const updateSnackbar = (objData) =>
    dispatch({ type: UPDATE_SNACKBAR, value: objData });

  const handleSendEmail = () => {
    if (selectedOption === "" || mailText === "") {
      setSendingMail(false);
    } else {
      //setOpenBackdrop(true)


      setSendingMail(true);

      const body_mail = {
        category: selectedOption,
        text: mailText,
      };



      axios
        .post("notification/sendTicketMail", body_mail)
        .then((res) => {
          updateSnackbar({
            open: true,
            message: res ? "Request sent" : "Unable to send request",
            severity: res ? "success" : "error",
          });
          handleClose();
          setMissing(true);
          setMailText("");
          setSelectedOption("");
        })
        .catch((err) => {
          console.log(err);
          setMissing(true);
          setMailText("");
          setSelectedOption("");

          updateSnackbar({
            open: true,
            message: "Unable to send request",
            severity: "error",
          });
        })
        .finally(() => {
          //setOpenBackdrop(false);
        });
    }
  };

  const handleProvaOpenBackdrop = () => {
    setOpenBackdrop(true);
  };
  const handleProvaChiudiBackdrop = () => {
    setOpenBackdrop(false);
  };

  const topics = [
    { id: 1, value: "Technical Bug", topic: "A technical bug is occurred" },
    { id: 2, value: "Product Missing", topic: "A product is missing" },
    { id: 3, value: "Option Missing", topic: "An option is missing" },
    { id: 4, value: "Option Incorrect", topic: "An option is incorrect" },
    { id: 5, value: "Price Missing", topic: "A price is missing" },
    { id: 6, value: "Price Incorrect", topic: "A price is incorrect" },
    { id: 7, value: "Add/remove/change Customer", topic: "Customer Require" },
    {
      id: 8,
      value: "Add/remove/change Sales engineer",
      topic: "Sales Eng require",
    },
    {
      id: 9,
      value: "New functionality",
      topic: "A new fuctionality is required",
    },
    { id: 10, value: "Improvements", topic: "An improvement is required" },
  ];

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal} elevation={3}>
          <div style={{ align: "center" }}>
            <Typography
              style={{
                color: "#002b49",
                backgroundColor: "#e8f4fd",
                marginBottom: "30px",
                padding: "10px",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Contact AKonfig Team
            </Typography>
          </div>

          <FormControl
            style={{
              color: "#002b49",
              backgroundColor: "#ffff",
              marginBottom: "30px",
            }}
          >
            <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
            <RadioGroup
              style={{ marginBottom: "30px" }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={selectedOption}
              onChange={handleChange}
            >
              {topics.map(function (e) {
                return (
                  <FormControlLabel
                    key={e.id}
                    value={e.topic}
                    control={<Radio />}
                    label={e.value}
                  />
                );
              })}
            </RadioGroup>
            <div>
              <Typography
                style={{
                  color: "#ffff",
                  backgroundColor: "#002b49",
                  marginBottom: "30px",
                  padding: "10px",
                }}
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                Mail Object: {selectedOption}
              </Typography>
            </div>
            <TextField
              style={{
                color: "#002b49",
                backgroundColor: "#ffff",
                borderColor: "#002b49",
                border: "solid 1px",
              }}
              id="outlined-multiline-static"
              placeholder="Please specify clearly the matter of the request"
              multiline
              rows={12}
              value={mailText}
              onChange={handleText}
            />
            <Container align="right">
              <Button
                disabled={missing}
                style={missing ? styles.buttonDisabled : styles.buttonInvio}
                variant="contained"
                onClick={handleSendEmail}
              >
                <SendOutlined></SendOutlined>Invia Mail
              </Button>
              <Button
                style={styles.buttonAnnulla}
                variant="outlined"
                onClick={handleClose}
              >
                <CancelOutlined></CancelOutlined>Annulla
              </Button>
            </Container>
            {/* <div>
            <Button  variant="contained" onClick={handleProvaOpenBackdrop}>Apro</Button>
            <Button variant="outlined" onClick={handleProvaChiudiBackdrop} >Annulla</Button>
            <Backdrop
        sx={{ color: '#002b49', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={handleProvaChiudiBackdrop}
      >
        <CircularProgress color="primary" />
      </Backdrop></div> */}{" "}
          </FormControl>
        </Box>
      </Modal>
    </div>
  );
}