import { Box, Container, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {

    return (
        <Container fixed>
            <Box 
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'} 
            >
                <Typography variant="h6" color="primary" gutterBottom>Oops! It looks empty here</Typography>
                <Typography variant="body1" color="primary">You can <Link to="/configuration">create a new configuration</Link> to fill up this space</Typography>
            </Box>
        </Container>
    )

}

export default NotFound;