import { IconButton, List, ListItem, ListItemIcon, ListItemText, Menu } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import React, { useState } from 'react'
import OptionSymbol from '../OptionSymbol/OptionSymbol';

const DatasheetLegend = () => {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <>
            <IconButton color="secondary" onClick={handleClick}>
                <InfoOutlined />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <OptionSymbol type={0} />
                        </ListItemIcon>
                        <ListItemText primary={"Available"} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <OptionSymbol type={1} />
                        </ListItemIcon>
                        <ListItemText primary={"On request, delivery time could increase"} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <OptionSymbol type={2} />
                        </ListItemIcon>
                        <ListItemText primary={"On request"} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <OptionSymbol type={3} />
                        </ListItemIcon>
                        <ListItemText primary={"Not preferred option"} />
                    </ListItem>
                </List>
            </Menu>

        </>
    )
}

export default DatasheetLegend;