import React from 'react';
import { BrowserRouter } from 'react-router-dom'
import './App.css';
import NavMenu from './components/UI/NavMenu/NavMenu'
import { createTheme } from '@material-ui/core/styles';
import { createMuiTheme, CssBaseline } from '@material-ui/core';
import { ThemeProvider, Toolbar } from '@material-ui/core'
import NotificaSnackbar from './components/UI/NotificaSnackbar/NotificaSnackbar';
import Pages from './components/UI/Pages/Pages';

const theme = createTheme({
  palette: {
    primary: {
      main: '#002b49'
    },
    primary25: {
      main: '#bfcad1'
    },
    secondary: {
      main: '#00ccff',
      light: '#7fe5ff',
      superlight: '#bff2ff'
    },
    default: {
      main: '#5a7c91'
    },
    defaultLight: {
      main: '#a3bac8'
    },
    default25: {
      main: '#e8eef1'
    },
    accent: {
      main: '#df0024',
      light: '#ef7f91',
      superlight: '#f7bfc8'
    },
    white: {
      main: '#fff'
    },
    standard: {
      main: '#e5e5e5'
    }
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeightRegular: 500,
    body1: {
      fontWeight: 600,
      textTransform: 'none'
    },
    body2: {
      fontWeight: 600
    },
    subtitle1: {
      fontWeight: 500,
      textTransform: 'none'
    },
    h2: {
      fontWeight: 700,
      textTransform: 'uppercase'
    },
    h4: {
      fontWeight: 500
    },
    h5: {
      fontWeight: 500,
      textTransform: 'none'
    },
    h6: {
      fontWeight: 600,
      textTransform: 'none'
    }
  },
  overrides: {
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: '#f7bfc8',
          "&:hover": {
            backgroundColor: '#f7bfc8',
          }
        }
      }
    },
    MuiChip: {
      root: {
        borderRadius: 0
      }
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#fff',
        color: '#002b49'
      }
    },
    MuiTable: {
      root: {
        border: '1px solid #bfcad1'
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid #bfcad1'
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0
      }
    },
    MuiListItemText: {
      primary: {
        color: '#002b49'
      }
    },
    MuiIconButton: {
      colorInherit: {
        color: '#df0024'
      }
    },
    MuiButton: {
      colorInherit: {
        backgroundColor: '#df0024',
        fontFamily: "Montserrat, sans-serif",
        textTransform: "none",
        fontWeight: 600,
        color: "#fff",
        '&:hover': {
          backgroundColor: '#9c0019'
        }
      },
      containedPrimary: {
        fontFamily: "Montserrat, sans-serif",
        textTransform: "none",
        fontWeight: 600,
        color: "#fff"
      },
      containedSecondary: {
        fontFamily: "Montserrat, sans-serif",
        textTransform: "none",
        fontWeight: 600,
        color: "#fff"
      },
      textPrimary: {
        fontFamily: "Montserrat, sans-serif",
        textTransform: "none",
        fontWeight: 600,
        color: "#002b49"
      },
      textSecondary: {
        fontFamily: "Montserrat, sans-serif",
        textTransform: "none",
        fontWeight: 600,
        color: "#00ccff"
      }
    },
    MuiCardActions: {
      spacing: {
        margin: 0
      }
    }
  },
  props: {
    MuiButton: {
      disableElevation: true
    }
  },
  buttonSecondary: {
    fontFamily: "Montserrat, sans-serif",
    textTransform: "none",
    fontWeight: 600,
    color: "#fff"
  },
  borderRadius: '4px',
  configurationPaperTitle: {
    color: '#fff',
    fontFamily: "'Montserrat', sans-serif"
  },
  btnPrimary: {
    color: '#002b49',
    fontFamily: "'Montserrat', sans-serif",
    textTransform: 'capitalize',
    fontWeight: 600
  },
  btnPDF: {
    color: '#df0024',
    fontFamily: "'Montserrat', sans-serif",
    textTransform: 'capitalize',
    fontWeight: 600
  },
  configurationBtnSelect: {
    color: '#002b49',
    fontFamily: "'Montserrat', sans-serif",
    textTransform: 'capitalize',
    fontWeight: 600
  },
  btnCloseModal: {
    color: '#5a7c91',
    fontFamily: "'Montserrat', sans-serif",
    textTransform: 'capitalize',
    fontWeight: 600
  },
  priceDrawerTop: 152,
  fontMontserrat: "'Montserrat', sans-serif",
  actionsDrawer: {
    width: 80,
    flexShrink: 0,
  },
  characteristcsDrawer: {
    width: 240,
    flexShrink: 0,
  },
  priceListDrawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaperZIndex: 1000
});

const App = () => {

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <NavMenu />
          <Toolbar />
          <Pages />
        </BrowserRouter>
        <NotificaSnackbar />
      </ThemeProvider>
    </>
  );
}

export default App;