import React from 'react'

const ErrorBoundary = (props) => {

    const Emoji = React.memo(({ className, label, symbol }) =>
    <span className={className} role="img" aria-label={label}>
        {String.fromCodePoint('0x1F615')}
    </span>)

    const createText = () => {
        switch (props.error) {
            case 401: return 'Sorry, you are not authorized.'
            default: return <>Snap! Something went wrong <Emoji /></>
        }
    }

    return (
        <div>
            {props.error
                ?   <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: props.noMargins ? 0 : 24}}>
                        {createText()}
                    </div>
                :   <>{props.children}</>
            }
        </div>
    )
}

export default ErrorBoundary;