import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { priceFormatter } from "../../utils/Utils";

// creo lo stile del PDF
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  logo: {
    width: "20%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 5,
    paddingBottom: 5,
  },
  specstitle: {
    paddingRight: "20px",
    width: "30%",
    borderStyle: "solid 1pt",
    borderColor: "black",
  },
  specsdescription: {
    width: "70%",
    color: "#5a7c91",
    borderStyle: "solid 1pt",
    borderColor: "black",
  },
  today: {
    position: "absolute",
    right: 0,
    top: 0,
    fontSize: 12,
  },
  title: {
    marginTop: "8",
    marginBottom: "14",
    textAlign: "center",
    fontSize: "11",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: "8px",
  },
  flexSignature: {
    display: "flex",
    alignItems: "left",
    flexDirection: "row",
    marginBottom: "8px",
    borderTop: "solid 3px",
    color: "RGB(255,0,0)",
  },
  rowInformation: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    fontSize: 10,
  },
  rowTotalPrice: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "stretch",
    marginTop: "2px",
    marginBotton: "2px",
  },

  sizeHalfBlackTotalPrice: {
    width: "50%",
    fontWeight: "bold",
    fontSize: 12
  },
  specValueTotalPrice: {
    fontSize: 8,
    width: "70%",
  },
  subTitle: {
    marginTop: 14,
    fontSize: 12,
  },
  divider: {
    marginTop: 8,
  },
  sizeHalf: {
    width: "50%",
  },
  detailsWrapper: {
    fontSize: 10,
    marginBottom: "30px",
  },
  specValue: {
    fontSize: 8,
    width: "70%",
  },
  downloadBtn: {
    textDecoration: "none",
    color: "inherit",
    fontFamily: "inherit",
    height: 24,
  },
});



const PDFConfiguration = ({
  data,
  whichRole,
}) => {


  return (
    <>
      <View style={styles.rowInformation}>

      </View>


      <View style={styles.title}>
        <Text>TECHNICAL SPECIFICATION SHEET</Text>
      </View>
      <View style={styles.detailsWrapper}>
        {data?.specs?.map((s, i) => {
          return (
            <View style={styles.row} key={i}>
              <Text style={styles.specstitle}>{s?.position_desc}</Text>
              <Text style={styles.specsdescription}>
                {s?.value?.value} - {s?.value?.value_desc} -{" "}
                {s?.value?.value_details}
              </Text>
            </View>
          );
        })}
        {/* sono qua */}
        <View style={styles.row}>
          <Text style={styles.specstitle}>Special Request</Text>

          <Text style={styles.specsdescription}>{data?.specialSelected}</Text>
        </View>
      </View>

      <View style={styles.title}>
        <Text>ACCESSORIES</Text>
      </View>
      <View style={styles.detailsWrapper}>
        {data?.accessories?.map((a, x) => {
          const expr = a.family;
          let painting = "";
          switch (expr) {
            case "S4":
            case "S6":
              painting = "Single Layer";
              break;
            case "S5":
            case "S7":
              painting = "Double Layer";
              break;
            default:
              painting = "";
          }

          return (
            <View style={styles.row} key={x}>
              <Text style={styles.specstitle}>
                {a.family + " " + a.accessory + " " + painting}
              </Text>

              <Text style={styles.specsdescription}>{a?.value?.value}</Text>
            </View>
          );
        })}
      </View>
      {whichRole === "External" && (
        <View style={styles.rowTotalPrice}>
          <Text style={styles.sizeHalfBlackTotalPrice}>Total final price</Text>
          <Text style={styles.specValueTotalPrice}>
            {priceFormatter(data?.finalPrice, true)}
          </Text>
        </View>
      )}
    </>
  );
};
export default PDFConfiguration;
