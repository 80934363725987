import React, { useEffect } from 'react';
import { RESET_PRODUCT_SELECED } from '../../store/actions/actions';
import WelcomeBanner from './WelcomeBanner/WelcomeBanner';
import Functionalities from './Functionalities/Functionalities.jsx';
import { Box, Container } from '@material-ui/core';
import { useDispatch } from 'react-redux';




const HomePage = () => {




    const dispatch = useDispatch();


    useEffect(() => {

        dispatch({ type: RESET_PRODUCT_SELECED })
    }, []);


    return (
        <div>
            <WelcomeBanner />
            <Box mt={7}>
                <Container>

                    <Functionalities />
                </Container>
            </Box>
        </div>
    )

}

export default HomePage;