import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	DialogActions,
	TextField,
	CircularProgress,
	Box,
	Typography,
	makeStyles,
	IconButton,
	Container,
	AppBar,
	Toolbar,
	Hidden,
	useTheme,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	priceFormatterSummary,

	useMqXS,
} from "../../../../utils/Utils";
import { UPDATE_SNACKBAR } from "../../../../store/actions/appActions";
import { CloseOutlined, MailOutlined, SendOutlined } from "@material-ui/icons";
import { SET_CONFIGURATION_ID, SET_CONFIGURATION_NAME } from "../../../../store/actions/actions";
import { useAxios } from "../../../../utils/useAxios";
import { prepareConfiguration } from "../../../../utils/prepareConfiguration";

const useStyles = makeStyles(theme => ({
	DialogTitle: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		display: 'flex',
		alignItems: 'center',
		height: theme.spacing(8),
		[theme.breakpoints.down("sm")]: {
			padding: theme.spacing(2),
			height: theme.spacing(7),
		}
	},
	AppBar: {
		borderBottom: `1px solid ${theme.palette.standard.main}`,
	},
	DialogContent: {
		padding: 0,
		[theme.breakpoints.down("sm")]: {
			padding: theme.spacing(2)
		}
	},
	Container: {
		[theme.breakpoints.up("sm")]: {
			paddingTop: theme.spacing(2)
		}
	},
	DialogActions: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
}));

const RequestQuotationForm = ({ compact, params, archive, whichRole, configuration, modelCode }) => {


	const dispatch = useDispatch();
	const axios = useAxios();
	const mq_xs = useMqXS();
	const styles = useStyles();
	const theme = useTheme();

	const [notes, setNotes] = useState("");
	const [requestTitle, setRequestTitle] = useState("");
	const [sendingMail, setSendingMail] = useState(false);
	const [open, setOpen] = useState(false);

	const configuration_id = useSelector(state => {
		return state?.configuration?.id
	});









	const material_selected = useSelector(state => {
		return state?.productSelector?.materialSelected;
	});







	const error_price = useSelector(state => {
		return state?.configuration?.product?.price_error
	});


	const updateConfigurationName = (objData) => dispatch({ type: SET_CONFIGURATION_NAME, value: objData.value });

	const updateSnackbar = (objData) =>
		dispatch({ type: UPDATE_SNACKBAR, value: objData });

	const handleClickOpenDialog = () => {
		setOpen(true);
	};

	const specs_mail = configuration?.specs?.map((s) => {

		let desc = s?.value_desc?.replace("(", '').replace(')', '');
		let desc_details = s?.value_details?.replace("(", '').replace(')', '');

		return {
			product: configuration?.product?.name,
			position: s.position,
			position_desc: s.position_desc,
			value: s.value,
			value_desc: desc,
			value_details: desc_details,
			type: s.type
		};
	});

	const accessories_mail = configuration?.accessories?.map((s) => {
		return {
			family: s.family,
			accessory: s.accessory,
			value: s.value,
		};
	});



	const codes_verified = archive
		? configuration?.product?.material_desc === modelCode
		: material_selected.description === modelCode


	const material_code = archive ? configuration?.product?.material : material_selected.code;
	const material_desc = archive ? configuration?.product?.material_desc : material_selected.description;

	let codes_selected = 0;

	if (!material_selected.material_desc) {
		//se non seleziono product selector
		codes_selected = 0;
	} else {
		if (material_selected.material_desc === modelCode) {
			//se product selector è uguale al codice configurato
			codes_selected = 2;
		} else {
			codes_selected = 1; // se codice configurato da utente è diverso da product selector selezionato
		}
	}

	const handleChangeNotes = (e) => {
		setNotes(e.target.value);
	};

	const handleChangeRequestTitle = (e) => {
		setRequestTitle(e.target.value);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const resetFields = () => {
		setRequestTitle('');
		setNotes('');
	}

	const saveConfiguration = async () => {

		const configData = prepareConfiguration(whichRole, configuration, params, modelCode)



		if (configData) {
			await axios.put(configData?.url, configData.data)
				.then(response => {

					if (response.data.result) {

						updateConfigurationName({ value: configuration?.name });


					}

					updateSnackbar({ open: true, message: response.data.result ? 'Configuration saved' : 'Unable to save', severity: response.data.result ? 'success' : 'error' });
					handleClose();

				})
				.catch(error => {
					updateSnackbar({ open: true, message: 'Server error', severity: "error" });
				})
				.finally(() => {
					setSendingMail(false);
					handleClose();
					resetFields();
				});
		}




	}

	const handleClickSendQuotation = () => {

		// if (!configuration_id) {

		// 	saveConfiguration();

		//   } else {

		// 	setSendingMail(false);
		// 	handleClose();
		// 	resetFields();

		//   }

		setSendingMail(true);

		const config_mail = {
			model_code: modelCode,
			final_price: priceFormatterSummary(configuration?.finalPrice, true, true),
			material_code: codes_verified ? material_code : null,
			material_desc: codes_verified ? material_desc : null,
			request: requestTitle,
			notes: notes,
			special_request: configuration?.specialSelected
		}

		const body = {
			configuration_mail: config_mail,
			position_mail_list: specs_mail,
			accessory_mail_list: accessories_mail
		};

		axios
			.post("notification/sendMail", body)
			.then(res => {

				updateSnackbar({
					open: true,
					message: res ? "Request sent" : "Unable to send request",
					severity: res ? "success" : "error",
				});

			})
			.catch((err) => {

				updateSnackbar({ open: true, message: "Unable to send request", severity: "error" });

			})
			.finally(() => {

				if (!configuration_id) {

					saveConfiguration();

				} else {

					setSendingMail(false);
					handleClose();
					resetFields();

				}

			});
	};


	if (whichRole === "External") {
		return (
			<div style={{ marginLeft: '10px' }}>

				{compact
					? <Box color={theme.palette.accent.main}>
						<IconButton color="inherit" onClick={handleClickOpenDialog} disabled={error_price}>
							<MailOutlined />
						</IconButton>
					</Box>
					: <Button
						onClick={handleClickOpenDialog}
						color="inherit"
						variant="contained"
						size={mq_xs ? 'small' : 'medium'}
						startIcon={<MailOutlined color="inherit" />}
						disabled={error_price}
					>
						Request quotation
					</Button>
				}

				<Dialog
					onClose={handleClose}
					open={open}
					fullWidth
					fullScreen
					aria-labelledby="Dialog request quotation"
				>
					<DialogTitle
						disableTypography
						classes={{ root: styles.DialogTitle }}
					>
						<Typography
							variant={mq_xs ? "body1" : "h6"}
							color="inherit"
						>
							Request official quotation
						</Typography>
						<Box ml={'auto'}>
							<IconButton color="inherit" onClick={handleClose}>
								<CloseOutlined />
							</IconButton>
						</Box>
					</DialogTitle>
					<DialogContent
						dividers
						classes={{ root: styles.DialogContent }}
					>
						<Hidden smDown>
							<AppBar
								color="transparent"
								elevation={0}
								position="relative"
								classes={{ root: styles.AppBar }}
							>
								<Toolbar>
									<Container maxWidth="lg" disableGutters={mq_xs}>

										<Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>

											<Box mr={mq_xs ? 0 : 1}>
												<Button
													color="inherit"
													size={"medium"}
													variant="contained"
													disabled={sendingMail || requestTitle === ''}
													startIcon={!sendingMail ? <SendOutlined color="inherit" /> : null}
													onClick={handleClickSendQuotation}
												>
													{sendingMail ? (
														<Box display={'flex'} alignItems={'center'}>
															Sending request
															<Box ml={1} display={'flex'} alignItems={'center'}>
																<CircularProgress color="inherit" size={24} />
															</Box>
														</Box>
													) : (
														"Request official quotation"
													)
													}
												</Button>
											</Box>

										</Box>
									</Container>
								</Toolbar>
							</AppBar>
						</Hidden>

						<Container maxWidth="lg" disableGutters={mq_xs} classes={{ root: styles.Container }}>
							<Grid container spacing={mq_xs ? 2 : 3}>
								<Grid item md={6} xs={12}>
									<Typography
										component="p"
										variant="body2"
										color="initial"
									>
										Model code
									</Typography>
									<Typography
										component="p"
										variant="body1"
										color="primary"
									>
										{modelCode}
									</Typography>
								</Grid>
								<Grid item md={6} xs={12}>
									<Typography
										component="p"
										variant="body2"
										color="initial"
									>
										Final price
									</Typography>
									<Typography
										component="p"
										variant="body1"
										color="primary"
									>
										{priceFormatterSummary(configuration?.finalPrice, true, true)}
									</Typography>
								</Grid>
								{codes_verified
									? <>
										<Grid item md={6} xs={12}>
											<Typography
												component="p"
												variant="body2"
												color="initial"
											>
												Material code
											</Typography>
											<Typography
												component="p"
												variant="body1"
												color="primary"
											>
												{material_code}
											</Typography>
										</Grid>
										<Grid item md={6} xs={12}>
											<Typography
												component="p"
												variant="body2"
												color="initial"
											>
												Material description
											</Typography>
											<Typography
												component="p"
												variant="body1"
												color="primary"
											>
												{material_desc}
											</Typography>
										</Grid>
									</>
									: null
								}
								{codes_selected === 1
									? <Grid item xs={12}>
										<Alert severity="warning">
											<AlertTitle>
												Attention, configured code is different from the one you
												choose with product selector.
											</AlertTitle>
											Request will be sent for the code you configured
										</Alert>
									</Grid>
									: null
								}
								<Grid item xs={12}>
									<TextField
										value={requestTitle}
										onChange={handleChangeRequestTitle}
										id="requestTitle"
										fullWidth
										variant="outlined"
										required
										label="Title"
										size={mq_xs ? "small" : "medium"}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										value={notes}
										onChange={handleChangeNotes}
										id="request"
										fullWidth
										variant="outlined"
										placeholder="Please write your request here"
										multiline
										minRows={5}
										maxRows={10}
									/>
								</Grid>
							</Grid>
						</Container>
					</DialogContent>
					<Hidden smUp>
						<DialogActions
							classes={{ root: styles.DialogActions }}
						>

							<Button
								color="inherit"
								size={"small"}
								variant="contained"
								disabled={sendingMail || requestTitle === ''}
								startIcon={!sendingMail ? <SendOutlined color="inherit" /> : null}
								onClick={handleClickSendQuotation}
							>
								{sendingMail ? (
									<Box display={'flex'} alignItems={'center'}>
										Sending request
										<Box ml={1} display={'flex'} alignItems={'center'}>
											<CircularProgress color="inherit" size={24} />
										</Box>
									</Box>
								) : (
									"Request official quotation"
								)
								}
							</Button>
						</DialogActions>
					</Hidden>
				</Dialog>

			</div>
		);
	} else {
		return <></>
	}

};

export default RequestQuotationForm;
