
import { Grid, Typography, MenuItem, Menu, ListItemIcon } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { RESET_ACC_LIST, RESET_SPECS_LIST, SET_CONFIGURATION_ID, UPDATE_PRODUCT_SELECTED } from '../../../../store/actions/actions';
import { useRoleDesc } from '../../../../utils/useRoleDesc';
import ProductCard from './ProductCard/ProductCard';

const ProductsGrid = ({ item }) => {

    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const [optionList, setOptionList] = useState([]);
    const [size, setSize] = useState();
    const open = Boolean(anchorEl);
    const whichRole = useRoleDesc();



    // se ho dei filtri attivi allora filtro la lista, altrimenti faccio vedere tutto


    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleClick = (size) => {

        // seleziono il prodotto tramite il size
        // const productSelected = [...list].filter(p => p.rdre === rdre).pop();



        const objData = {
            ...item,
            size: size
        }

        dispatch({ type: UPDATE_PRODUCT_SELECTED, value: objData })
        setSize(size)

        // resetSpecsList();
        // resetAccList();
        // updateProductSelected({ value: objData });
        // resetConfigurationID({ value: null });
        // setRdreSelected(rdre);

        setAnchorEl(null);

        // selectProduct(productSelected.rdre, size);

    }

    const chooseSize = (event) => {



        setOptionList(item.size_list);
        setAnchorEl(event);

    }


    return (
        <>


            <ProductCard
                item={item}
                chooseSize={chooseSize}
                size={size}

            />


            <Menu
                id="list"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: 48 * 8,
                    }
                }}
                MenuListProps={{
                    style: {
                        paddingTop: '0'
                    }
                }}
            >
                {((whichRole === 'Internal' || whichRole === 'VIP' || whichRole === 'Admin') && optionList.find((item) => item.in_datasheet === false)) && <div style={{ borderBottom: '1px solid gray', position: 'sticky', top: '0', backgroundColor: 'white', zIndex: '10', padding: '0 17px' }}>
                    <p style={{ margin: '0', padding: '10px 0' }}>
                        Standard catalog
                    </p>
                </div>}
                {optionList.map((s, index) => {
                    if (s.in_datasheet && !s.in_phase_out) {
                        return (
                            <MenuItem key={index} onClick={() => handleClick(s.size)}>
                                {(s.rdre === item.rdre) && (s.selected
                                    ? <ListItemIcon>
                                        <Check color="primary" />
                                    </ListItemIcon>
                                    : <React.Fragment>
                                        {optionList.filter(item => item.selected).length === 0
                                            ? null
                                            : <ListItemIcon></ListItemIcon>
                                        }
                                    </React.Fragment>)
                                }
                                <Typography variant="body1" color="primary">
                                    {s.size}
                                </Typography>
                            </MenuItem>
                        )
                    } else {
                        return null
                    }
                }

                )}
                {((whichRole === 'Internal' || whichRole === 'VIP' || whichRole === 'Admin') && optionList.find((item) => item.in_datasheet === false && item.in_phase_out === false)) && <div style={{ borderBottom: '1px solid gray', borderTop: '1px solid gray', position: 'sticky', top: '-1px', backgroundColor: 'white', zIndex: '10', padding: '0 17px' }}>
                    <p >
                        Internal catalog
                    </p>
                </div>}
                {optionList.map((t, indexT) => {
                    if (!t.in_datasheet && !t.in_phase_out) {
                        return (
                            <MenuItem key={indexT} onClick={() => handleClick(t.size)}>
                                {(t.rdre === item.rdre) && (t.selected
                                    ? <ListItemIcon>
                                        <Check color="primary" />
                                    </ListItemIcon>
                                    : <React.Fragment>
                                        {optionList.filter(item => item.selected).length === 0
                                            ? null
                                            : <ListItemIcon></ListItemIcon>
                                        }
                                    </React.Fragment>)
                                }
                                <Typography onClick={() => { console.log('verifichiamo', t.size) }} variant="body1" color="primary">
                                    {t.size}
                                </Typography>
                            </MenuItem>
                        )
                    } else {
                        return null
                    }
                })}

                {(optionList.find((item) => item.in_phase_out === true)) && <div style={{ borderBottom: '1px solid gray', borderTop: '1px solid gray', position: 'sticky', top: '-1px', backgroundColor: 'white', zIndex: '10', padding: '0 17px' }}>
                    <p >
                        Phase Out
                    </p>
                </div>}
                {optionList.map((t, indexT) => {
                    if (t.in_phase_out) {
                        return (
                            <MenuItem key={indexT} onClick={() => handleClick(t.size)}>
                                {(t.rdre === item.rdre) && (t.selected
                                    ? <ListItemIcon>
                                        <Check color="primary" />
                                    </ListItemIcon>
                                    : <React.Fragment>
                                        {optionList.filter(item => item.selected).length === 0
                                            ? null
                                            : <ListItemIcon></ListItemIcon>
                                        }
                                    </React.Fragment>)
                                }
                                <Typography onClick={() => { console.log('verifichiamo', t.size) }} variant="body1" color="primary">
                                    {t.size}
                                </Typography>
                            </MenuItem>
                        )
                    } else {
                        return null
                    }
                })}
            </Menu>


        </>
    )

}

export default ProductsGrid;