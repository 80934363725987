import { Box, makeStyles, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux';
import { formatPercentage, formatPriceValue } from '../../../../../utils/Utils';

const useStyles = makeStyles(theme => ({
    FirstColumn: {
        width: '33.33%',
        borderRight: `1px solid ${theme.palette.primary25.main}`,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1)
        }
    },
    TableCell: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1)
        }
    }
}));



const CustomerInfoBelgium = ({ data }) => {

    const styles = useStyles();

    // const internal_sales_limit_discount = useSelector(state => {
    //     return state.configuration.discount.internal_sales_limit_discount;
    // });

    // const internal_sales_limit_price = useSelector(state => {
    //     return state.configuration.discount.internal_sales_limit_price;
    // });

    // const sales_engineer_limit_discount = useSelector(state => {
    //     return state.configuration.discount.sales_engineer_limit_discount;
    // });

    // const sales_engineer_limit_price = useSelector(state => {
    //     return state.configuration.discount.sales_engineer_limit_price;
    // });

    // const sales_manager_limit_discount = useSelector(state => {
    //     return state.configuration.discount.sales_manager_limit_discount;
    // });

    // const sales_manager_limit_price = useSelector(state => {
    //     return state.configuration.discount.sales_manager_limit_price;
    // });

    // const general_manager_limit_discount = useSelector(state => {
    //     return state.configuration.discount.general_manager_limit_discount;
    // });

    // const general_manager_limit_price = useSelector(state => {
    //     return state.configuration.discount.general_manager_limit_price;
    // });

    return (

        <Box mt={2}>

            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell classes={{ root: styles.FirstColumn }}>
                            <Typography variant="body1" color="primary">
                                Internal Sales
                            </Typography>
                        </TableCell>
                        <TableCell classes={{ root: styles.FirstColumn }}>
                            <Typography variant="body1" color="primary">
                                {formatPercentage(data?.internal_sales_limit_discount)}
                            </Typography>
                        </TableCell>
                        <TableCell classes={{ root: styles.TableCell }}>
                            <Typography variant="body1" color="primary">
                                {formatPriceValue(data?.internal_sales_limit_price)}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell classes={{ root: styles.FirstColumn }}>
                            <Typography variant="body1" color="primary">
                                Sales Engineer
                            </Typography>
                        </TableCell>
                        <TableCell classes={{ root: styles.FirstColumn }}>
                            <Typography variant="body1" color="primary">
                                {formatPercentage(data?.sales_engineer_limit_discount)}
                            </Typography>
                        </TableCell>
                        <TableCell classes={{ root: styles.TableCell }}>
                            <Typography variant="body1" color="primary">
                                {formatPriceValue(data?.sales_engineer_limit_price)}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell classes={{ root: styles.FirstColumn }}>
                            <Typography variant="body1" color="primary">
                                Sales Manager
                            </Typography>
                        </TableCell>
                        <TableCell classes={{ root: styles.FirstColumn }}>
                            <Typography variant="body1" color="primary">
                                {formatPercentage(data?.sales_manager_limit_discount)}
                            </Typography>
                        </TableCell>
                        <TableCell classes={{ root: styles.TableCell }}>
                            <Typography variant="body1" color="primary">
                                {formatPriceValue(data?.sales_manager_limit_price)}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell classes={{ root: styles.FirstColumn }}>
                            <Typography variant="body1" color="primary">
                                General Manager
                            </Typography>
                        </TableCell>
                        <TableCell classes={{ root: styles.FirstColumn }}>
                            <Typography variant="body1" color="primary">
                                {formatPercentage(data?.general_manager_limit_discount)}
                            </Typography>
                        </TableCell>
                        <TableCell classes={{ root: styles.TableCell }}>
                            <Typography variant="body1" color="primary">
                                {formatPriceValue(data?.general_manager_limit_price)}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>

    )
}

export default CustomerInfoBelgium