import React from "react";
import { Box, IconButton } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";

const handleDownload = (videoFile, fileName) => {
  if (videoFile) {
    const anchor = document.createElement("a");
    anchor.href = videoFile.default || videoFile;
    anchor.download = fileName;
    anchor.click();
  } else {
    console.error("Impossibile eseguire il download: file video non trovato.");
  }
};

const DownloadButton = ({ videoFile, fileName }) => {
  return (
    <Box mt={2}>
      <IconButton
        onClick={() => handleDownload(videoFile, fileName)}
        aria-label="Download"
      >
        <CloudDownload />
      </IconButton>
    </Box>
  );
};

export default DownloadButton;
