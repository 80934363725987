import { Box, Button, Hidden } from '@material-ui/core'
import { PlayArrowOutlined } from '@material-ui/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_ARCHIVE_ID } from '../../../store/actions/archive/actions';
import { useHistory } from 'react-router';

const ConfigureProductButton = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const product = useSelector(state => {
        return state.configuration.product;
    });


    const missing = !product.rdre || !product.size;


    const handleClick = () => {
        // section 1 -> configuration
        dispatch({ type: CLEAR_ARCHIVE_ID });
        // setCurrentSection({ value: 1 });
        history.push('/configuration/' + product.rdre + '/' + product.size)
    }

    return (
        <>
            <Hidden mdUp>
                {/* {mobile} */}
                <Button
                    disabled={missing}
                    color="inherit"
                    variant="contained"
                    size="small"
                    startIcon={<PlayArrowOutlined />}
                    onClick={handleClick}
                >
                    Configure product
                </Button>
            </Hidden>
            <Hidden smDown>
                {/* {desktop} */}
                <Box ml={'auto'} mr={1}>
                    <Button
                        disabled={missing}
                        color="inherit"
                        variant="contained"
                        startIcon={<PlayArrowOutlined />}
                        onClick={handleClick}
                    >
                        {!missing ? "Configure " + product.product_desc + product.operating_mode + " - " + product.series + " / Size: " + product.size : " Configure product"}
                    </Button>
                </Box>
            </Hidden>
        </>
    )
}

export default ConfigureProductButton