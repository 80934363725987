import React, { useEffect, useRef, useState } from "react";

import { Grid } from "@material-ui/core";
import { ArrowDropDownOutlined } from "@material-ui/icons";






const Accessory = ({ acc, activeIndex, setActiveIndex, index, setAccessorySelected, readonly, isMobile }) => {

    const menuRef = useRef(null);
    const [painting, setPainting] = useState('');

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            // Se il clic è avvenuto al di fuori del menu, chiudi il menu
            setActiveIndex(900);
        }
    };

    useEffect(() => {
        // Aggiungi un event listener per il clic al di fuori del menu
        document.addEventListener('mousedown', handleClickOutside);
        switch (acc.family) {
            case "S4":
            case "S6":

                setPainting("\u00B7 Single layer");
                break;
            case "S5":
            case "S7":

                setPainting("\u00B7 Double layer");
                break;
            default:
                setPainting("");

        }
        // Pulisci l'event listener quando il componente viene smontato
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, []);







    if (readonly && !acc.value) {
        return <></>
    }


    return (
        <>
            {!isMobile ? <>
                {acc.action !== 'hide' && <Grid container style={{ border: '1px solid #bfcad1', backgroundColor: 'white', margin: '0 0 -1.5px 0', width: 'auto' }} spacing={2}>
                    <Grid style={{ borderRight: '1px solid #bfcad1', padding: '8px', justifyContent: 'center', display: 'flex' }} item xs={1}>
                        <p> {acc.family} </p>
                    </Grid>
                    <Grid style={{ borderRight: '1px solid #bfcad1', padding: '8px 8px 8px 16px', display: 'flex', alignItems: 'center' }} item xs={3}>
                        <p style={{ margin: '0' }}> {acc.accessory} {painting}</p>
                    </Grid>
                    <Grid style={{ position: 'relative', display: 'flex', alignItems: 'center', padding: '8px 8px 8px 16px' }} item xs={8}>
                        <p style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', color: acc?.value?.value ? '' : 'red' }} onClick={() => {
                            if (!readonly) {
                                setActiveIndex(index + 200)
                            }
                        }} >{acc.value?.value ? acc?.value?.value : 'Choose an option'} {acc.value_list.length > 1 && <ArrowDropDownOutlined />}</p>
                        {(activeIndex === index + 200 && acc.value_list.length > 1) && <div className="menu" ref={menuRef} style={{ position: 'absolute', top: '-45px', left: '-45px', backgroundColor: 'white', border: '1px solid black', width: 'auto' }} >

                            {acc.value_list.map((value, index) => {

                                return (
                                    // <p onClick={() => { setActiveIndex(); setValueSelected(value.value + '-' + value.value_desc) }} key={index}>
                                    <p style={{ cursor: 'pointer' }} onClick={() => { setActiveIndex(); setAccessorySelected({ 'value': value, 'acc': acc }) }} key={index}>
                                        {value.value}
                                    </p>
                                )
                            })}
                        </div>}

                    </Grid>
                </Grid>}
            </> : acc.action !== 'hide' && <div className="mobile-conf">
                <div> <p> {acc.family}</p></div>
                <div>  <p style={{ margin: '0' }}>  {acc.accessory} {painting}</p></div>
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center', padding: '8px 8px 8px 16px' }} item xs={8}>
                    <p style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', color: acc?.value?.value ? '' : 'red' }} onClick={() => {
                        if (!readonly) {
                            setActiveIndex(index + 200)
                        }
                    }} >{acc.value?.value ? acc?.value?.value : 'Choose an option'} {acc.value_list.length > 1 && <ArrowDropDownOutlined />}</p>
                    {(activeIndex === index + 200 && acc.value_list.length > 1) && <div className="menu" ref={menuRef} style={{ position: 'absolute', top: '-45px', left: '-45px', backgroundColor: 'white', border: '1px solid black', width: 'auto' }} >

                        {acc.value_list.map((value, index) => {

                            return (
                                // <p onClick={() => { setActiveIndex(); setValueSelected(value.value + '-' + value.value_desc) }} key={index}>
                                <p style={{ cursor: 'pointer' }} onClick={() => { setActiveIndex(); setAccessorySelected({ 'value': value, 'acc': acc }) }} key={index}>
                                    {value.value}
                                </p>
                            )
                        })}
                    </div>}

                </div>
            </div>}
        </>
    );

};

export default Accessory