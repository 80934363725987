import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const AlertSpecialSelected = () => {


    return (
        <Box mx={2}>
            <Alert severity="warning">
                <Typography variant="h6" color="inherit">Base price can't be calculated</Typography>

                {/* <Typography variant="h6" color="inherit">No price can be calculated for out of catalog or internal catalog options</Typography> */}
            </Alert>
        </Box>
    )

}

export default AlertSpecialSelected;