import { IconButton } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import React from 'react';

const ConfirmMaterial = (props) => {

    const handleClick = () => {
        if (props.data) {
            props.confirmMaterial(props.data);
            return;
        }
        props.confirmMaterial();
    }

    return (
        <IconButton color="primary" onClick={handleClick}>
            <Done />
        </IconButton>
    )

}

export default ConfirmMaterial