import { AppBar, Box, Container, Hidden, IconButton, makeStyles, TextField, Toolbar } from '@material-ui/core';
import { ClearOutlined, SearchOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce, useFirstRender, useMqXS } from '../../../../utils/Utils';
import ArchiveFilters from '../../../../components/Archive/ArchiveFilters/ArchiveFilters';


const useStyles = makeStyles(theme => ({
    AppBar: {
        top: theme.spacing(8),
        [theme.breakpoints.down("xs")]: {
            top: theme.spacing(7)
        },
        backgroundColor: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.standard.main}`
    }
}));

const SearchBar = (props) => {

    const styles = useStyles();
    const mq_xs = useMqXS();

    const filters = useSelector(state => {
        return state.archive.filters;
    });

    const filter_my_config = useSelector(state => {
        return state.archive.filter_my_config;
    });

    const filter_apply = useSelector(state => {
        return state.archive.filter_apply;
    });

    const [value, setValue] = useState('');

    const handleChange = (e) => {
        setValue(e.target.value);
    }

    const resetSearch = () => {
        setValue('');
    }

    const debouncedSearchTerm = useDebounce(value, 300);
    const firstRender = useFirstRender();

    const filter_family = filters
        ? filters.filter(x => x.selected).map(x => {
            return {
                rdre: x.rdre,
                size_list: [...x.size_list].filter(s => s.selected).map(x => { return x.size })
            }
        })
        : [];

    // Effect for API call
    useEffect(
        () => {
            if (debouncedSearchTerm || !firstRender) {
                props.search(value, filter_my_config, filter_family);
            }
        },
        [debouncedSearchTerm, filter_my_config, filter_apply] // Only call effect if debounced search term changes
    );

    return (
        <AppBar
            position="fixed"
            elevation={0}
            classes={{ root: styles.AppBar }}
        >
            <Toolbar>
                <Container maxWidth="lg" disableGutters={mq_xs}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>

                        <Hidden smDown>

                            <ArchiveFilters />

                        </Hidden>

                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search"
                            size={"small"}
                            InputProps={{
                                startAdornment: (<SearchOutlined color="primary" />),
                                endAdornment: (value !== '' ? <IconButton color="primary" onClick={resetSearch}><ClearOutlined color="inherit" /></IconButton> : null)
                            }}
                            value={value}
                            onChange={handleChange}
                        />

                    </Box>
                </Container>
            </Toolbar>
        </AppBar>
    )

}

export default SearchBar;