import { Box } from "@material-ui/core";
import React from "react";
import DownloadButton from "../Download/DownloadButton";

// const handleDownload = (videoFile, fileName) => {
//   if (videoFile) {
//     const anchor = document.createElement("a");
//     anchor.href = videoFile.default || videoFile;
//     anchor.download = fileName;
//     anchor.click();
//   } else {
//     console.error("Impossibile eseguire il download: file video non trovato.");
//   }
// };

const VideoPlayer = ({ videoFiles, videoTitles }) => {
  return (
    <div
      style={{
        border: "1px solid #ccc", // Aggiungi un bordo di 2px solid con colore grigio chiaro
        borderRadius: "5px", // Aggiungi un bordo smussato con raggio di 5px
        width: "100%", // Imposta la larghezza al 100%
        height: "100%", // Imposta l'altezza al 100%
        padding: '15px'
      }}
    >
      {videoFiles.map((videoFile, index) => (
        <Box key={index} mt={3}>
          <h3>{videoTitles[index]}</h3> {/* Titolo personalizzato */}
          <video style={{ width: "100%" }} controls>
            <source src={videoFile.default || videoFile} type="video/mp4" />
            Il tuo browser non supporta il tag video.
          </video>
          <DownloadButton videoFile={videoFile} fileName={videoTitles[index]} />
        </Box>
      ))}
    </div>
  );
};

export default VideoPlayer;
