import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_MATERIAL_TO_SLOT,
  REMOVE_MATERIAL_FROM_SLOT,
  RESET_COMPARE_SLOTS,
  SET_MATERIAL_MODAL,
} from "../../../../../store/actions/ProductSelector/actions";
import {
  Checkbox,
  Hidden,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { useAxios } from "../../../../../utils/useAxios";

const CodeList = (props) => {


  const axios = useAxios();

  const storeCompareSlots = useSelector((state) => {
    return state.productSelector.compareProducts.slots;
  });

  const dispatch = useDispatch();

  const setMaterialmodal = (objData) =>
    dispatch({
      type: SET_MATERIAL_MODAL,
      code: objData.code,
      class: objData.class,
      description: objData.description,
      specsList: objData.specsList,
    });

  const addMaterialToSlot = (objData) =>
    dispatch({
      type: ADD_MATERIAL_TO_SLOT,
      listType: props.listType,
      value: objData.value,
    });
  const removeMaterialFromSlot = (objData) =>
    dispatch({ type: REMOVE_MATERIAL_FROM_SLOT, value: objData.value });
  const resetCompareSlots = () => dispatch({ type: RESET_COMPARE_SLOTS });

  const handleClickSelectMaterial = (code) => {
    axios.get("productselector/GetProductDetail", {
      params: { material: code },
    })
      .then((response) => {
        resetCompareSlots();
        setMaterialmodal({
          code: response.data.material,
          class: response.data.material_class,
          description: response.data.material_desc,
          specsList: response.data.spec_detail_list,
        });
        props.changeSection(1);
      });
  };

  const toggleMaterialCompare = (e, materialCode) => {
    if (storeCompareSlots.length === 4) {
      if (
        storeCompareSlots.filter((m) => m.code !== materialCode).length === 0
      ) {
        e.preventDefault();
        return;
      }
    }

    if (e.target.checked) {
      addMaterialToSlot({ listType: props.listType, value: materialCode });
    } else {
      removeMaterialFromSlot({ listType: props.listType, value: materialCode });
    }
  };

  return (
    <React.Fragment>
      <List>
        {props.list.map((i, index) => {
          return (
            <React.Fragment key={index}>
              <ListItem
                divider
                button
                onClick={() => handleClickSelectMaterial(i.material)}
              >
                <div style={{ display: 'flex', gap: '20px' }}>
                  <ListItemText
                    color="primary"
                    primary={i.material}
                    secondary={
                      i.qty_max === null &&
                        i.lead_time === null
                        ? i.material_desc
                        : i.material_desc +
                        " · Qty. Max:  " +
                        i.qty_max +
                        " · Lead Time:  " +
                        i.lead_time + " days"
                    }
                  />
                  {/*
                  <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-end' }}>
                    <p style={{ margin: '0 0 7px 0', color: '#002b49' }}><span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Price :</span>  {i.price ? i.price + '€' : ''} </p>
                    <p style={{ margin: '0 0 7px 0', color: '#002b49' }}><span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Status :</span> {i.status}</p>
                  </div>
                  */}

                </div>
                <ListItemSecondaryAction
                  onClick={(e) => toggleMaterialCompare(e, i.material)}
                >
                  <Hidden smDown implementation="css">
                    <Checkbox
                      disabled={
                        storeCompareSlots.length === 4
                          ? storeCompareSlots.filter(
                            (m) => m.code === i.material
                          ).length > 0
                            ? false
                            : true
                          : false
                      }
                      color="primary"
                      inputProps={{ "aria-label": "select for compare" }}
                    />
                  </Hidden>
                </ListItemSecondaryAction>
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>
    </React.Fragment>
  );
};

export default CodeList;
