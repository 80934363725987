import { Button } from '@material-ui/core'
import { DeleteOutlined } from '@material-ui/icons';
import React from 'react'
import { useDispatch } from 'react-redux';
import { RESET_FILTERS } from '../../../../store/actions/archive/actions';
import { useMqXS } from '../../../../utils/Utils'

const RemoveAllFiltersButton = () => {

    const mq_xs = useMqXS();
    const dispatch = useDispatch();

    const removeFilters = () => dispatch({type: RESET_FILTERS});

    const handleClick = () => {
        removeFilters();
    }

    return (
            <>
                <Button
                    color="secondary"
                    variant="contained"
                    size={mq_xs ? "small" : "medium"}
                    startIcon={<DeleteOutlined />}
                    onClick={handleClick}
                >
                    Remove all
                </Button>
            </>
    )
}

export default RemoveAllFiltersButton