import React, { useEffect, useState } from 'react';
import { Box, Container, Card, CardContent, Table, TableBody, TableRow, TableCell, Typography, TextField, makeStyles, InputAdornment, Icon, IconButton, Tooltip } from '@material-ui/core';
import SectionTitle from '../../../../../../../components/UI/SectionTitle/SectionTitle';
import { checkValue, formatPercentage, formatPriceValue, useCheckAuthorization, useMqXS } from '../../../../../../../utils/Utils';

import { convertValue, testFields, validationTypes } from '../../../../../../../utils/validationTypes';
import { useDispatch, useSelector } from 'react-redux';
// import { UPDATE_SALES_NET_PRICE, UPDATE_STANDARD_DISCOUNT } from '../../../../../store/actions/actions';
import { Alert } from '@material-ui/lab'
import { SET_CALCULATED_DISCOUNT } from '../../../../../../../store/actions/actions';




const useStyles = makeStyles(theme => ({
    FirstColumn: {
        width: '50%',
        borderRight: `1px solid ${theme.palette.primary25.main}`,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1)
        }
    },
    TableCell: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1)
        }
    }
}));

const PercentageBox = ({ readonly, country, data, totalPrice, type, calculatedDiscount, basePrice, isMobile }) => {


    const mq_xs = useMqXS();
    const styles = useStyles();
    const dispatch = useDispatch();



    const [inputDiscount, setInputDiscount] = useState(calculatedDiscount?.inputDiscount ? calculatedDiscount?.inputDiscount : true);
    const [inputValue, setInputValue] = useState();
    const [trigger, setTrigger] = useState(true);
    const [finalDiscount, setFinalDiscount] = useState(formatPercentage(calculatedDiscount?.final_discount))


    const sales_net_price = useSelector(state => {
        return state.configuration?.calculatedDiscount?.sales_net_price
    });


    const final_discount_grid = useSelector(state => {
        return state.configuration?.calculatedDiscount?.final_discount_grid
    });



    const handleChange = (e) => {

        const updatedObj = { ...calculatedDiscount }
        setTrigger(!trigger);
        const changed = e.target.value.replace(",", ".");
        setInputValue(changed);



        // const datatype = e.target.dataset ? e.target.dataset.valuetype : null;
        // const value = convertValue(e.target.value, datatype);
        const value = e.target.value;

        // // // // testFields mi restituisce il valore 'error' ? true / false
        // if (value && testFields(validationTypes.intMandatory, value)) return;

        // // se sto scrivendo il prezzo netto calcolo la perc
        if (inputDiscount) {

            const perc = ((1 - (value / (totalPrice + basePrice))) * 100).toFixed(2);
            updatedObj.sales_net_price = parseFloat(value);
            updatedObj.sales_manual_discount = parseFloat(perc);
            updatedObj.extra_discounted_price = Math.round(value * (1 - (updatedObj.extra_discount / 100)) * 100) / 100

        } else {// se sto scrivendo la perc calcolo il prezzo netto

            let val;

            if (type === 'standard') {
                val = Math.round((totalPrice + basePrice) * (1 - (value / 100)) * 100) / 100;
                updatedObj.sales_net_price = parseFloat(val)
                updatedObj.sales_manual_discount = parseFloat(value)
                updatedObj.extra_discounted_price = Math.round(val * (1 - (updatedObj.extra_discount / 100)) * 100) / 100

            }
        }

        updatedObj.final_discount = 1 - (updatedObj.sales_net_price / (totalPrice + basePrice));
        updatedObj.final_discount_cluster = (updatedObj.sales_net_price - (data?.cu_maximum_price || data?.cluster_manager_limit_price)) / (data?.cu_maximum_price || data?.cluster_manager_limit_price);
        updatedObj.final_discount_grid = (updatedObj.sales_net_price - (data?.customer_type_price || data?.customer_grid_price)) / (data?.customer_type_price || data?.customer_grid_price);
        updatedObj.extra_final_discount = 1 - (updatedObj.extra_discounted_price / (totalPrice + basePrice));
        updatedObj.extra_final_discount_cluster = (updatedObj.extra_discounted_price - (data?.cu_maximum_price || data?.cluster_manager_limit_price)) / (data?.cu_maximum_price || data?.cluster_manager_limit_price);
        updatedObj.extra_final_discount_grid = (updatedObj.extra_discounted_price - (data?.customer_type_price || data?.customer_grid_price)) / (data?.customer_type_price || data?.customer_grid_price);
        // if (type === 'standard') {
        //     setFinalDiscount(formatPercentage(1 - (updatedObj.sales_net_price / (totalPrice + basePrice))));
        //     setFinalExtraDiscount(formatPercentage(1 - (updatedObj.extra_discounted_price / (totalPrice + basePrice))));
        // }
        // if (type !== 'standard') {

        //     setFinalExtraDiscount(formatPercentage(1 - (updatedObj.extra_discounted_price / (totalPrice + basePrice))));

        // }
        dispatch({ type: SET_CALCULATED_DISCOUNT, value: updatedObj });
        setFinalDiscount(formatPercentage(1 - (updatedObj.sales_net_price / (totalPrice + basePrice))));



    }

    const handleEuroPerc = (value) => {

        setInputDiscount(value);
        // setInputValue(0);
        const updatedObj = { ...calculatedDiscount };
        updatedObj.inputDiscount = value;

        if (!value) {
            // updatedObj.sales_net_price = totalPrice + basePrice
            updatedObj.sales_net_price = null
            updatedObj.sales_manual_discount = null
            setInputValue(null);

        } else {

            updatedObj.sales_net_price = 0
            updatedObj.sales_manual_discount = 100

        }
        updatedObj.final_discount = 1 - (updatedObj.sales_net_price / (totalPrice + basePrice));
        updatedObj.final_discount_cluster = (updatedObj.sales_net_price - (data?.cu_maximum_price || data?.cluster_manager_limit_price)) / (data?.cu_maximum_price || data?.cluster_manager_limit_price);
        updatedObj.final_discount_grid = (updatedObj.sales_net_price - (data?.customer_type_price || data?.customer_grid_price)) / (data?.customer_type_price || data?.customer_grid_price);
        updatedObj.extra_final_discount = 1 - (updatedObj.extra_discounted_price / (totalPrice + basePrice));
        updatedObj.extra_final_discount_cluster = (updatedObj.extra_discounted_price - (data?.cu_maximum_price || data?.cluster_manager_limit_price)) / (data?.cu_maximum_price || data?.cluster_manager_limit_price);
        updatedObj.extra_final_discount_grid = (updatedObj.extra_discounted_price - (data?.customer_type_price || data?.customer_grid_price)) / (data?.customer_type_price || data?.customer_grid_price);

        dispatch({ type: SET_CALCULATED_DISCOUNT, value: updatedObj });

    }


    // quando cambia il net_price -> ricalcolo lo standard discount
    // useEffect(() => {
    //     const updatedObj = { ...calculatedDiscount }
    //     updatedObj.final_discount = 1 - (updatedObj.sales_net_price / (totalPrice + basePrice));
    //     updatedObj.final_discount_cluster = (updatedObj.sales_net_price - (data?.cu_maximum_price || data?.cluster_manager_limit_price)) / (data?.cu_maximum_price || data?.cluster_manager_limit_price);
    //     updatedObj.final_discount_grid = updatedObj.sales_net_price - (data?.customer_type_price / data?.customer_type_price);
    //     updatedObj.extra_final_discount = 1 - (updatedObj.extra_discounted_price / (totalPrice + basePrice));
    //     updatedObj.extra_final_discount_cluster = (updatedObj.extra_discounted_price - (data?.cu_maximum_price || data?.cluster_manager_limit_price)) / (data?.cu_maximum_price || data?.cluster_manager_limit_price);
    //     updatedObj.extra_final_discount_grid = (updatedObj.extra_discounted_price - (data?.customer_type_price || data?.customer_grid_price)) / (data?.customer_type_price || data?.customer_grid_price);


    //     dispatch({ type: SET_CALCULATED_DISCOUNT, value: updatedObj });
    //     setFinalDiscount(formatPercentage(1 - (updatedObj.sales_net_price / (totalPrice + basePrice))));
    //     setFinalExtraDiscount(formatPercentage(1 - (updatedObj.extra_discounted_price / (totalPrice + basePrice))));
    //     console.log('okokoko2', updatedObj.extra_discounted_price, formatPercentage(1 - (updatedObj.extra_discounted_price / (totalPrice + basePrice))));



    // }, [data, basePrice, trigger]);




    useEffect(() => {

        if (type === 'standard') {
            const updatedObj = { ...calculatedDiscount };

            if (!inputValue) {
                updatedObj.sales_manual_discount = null
            }

            dispatch({ type: SET_CALCULATED_DISCOUNT, value: updatedObj });
        }


    }, [inputValue]);



    useEffect(() => {


        if (inputDiscount) {
            if (calculatedDiscount?.sales_net_price && calculatedDiscount?.sales_net_price !== 0) {
                setInputValue(calculatedDiscount?.sales_net_price)

            }
        } else {
            if (calculatedDiscount?.sales_manual_discount && calculatedDiscount?.sales_manual_discount !== 0) {
                setInputValue(calculatedDiscount?.sales_manual_discount,)
            }
        }

    }, []);




    return (
        <>
            <Container disableGutters={!mq_xs}>

                <Box py={2} px={mq_xs ? 0 : 2}>


                    <SectionTitle title={'Standard discount'} />

                    <Card elevation={0} style={{ border: '2px solid #d1dce3', borderRadius: 0 }}>
                        <CardContent>

                            <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'space-between'}>
                                <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'} alignItems={'center'} mb={isMobile ? 2 : 0}>
                                    <TextField
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <Icon color="primary">
                                                    {!inputDiscount ?
                                                        <span className="material-icons-outlined">percent</span>
                                                        : <span className="material-icons-outlined">euro</span>
                                                    }
                                                </Icon>

                                            </InputAdornment>,
                                        }}
                                        inputProps={{
                                            "data-valuetype": "number",
                                        }}
                                        label={!inputDiscount ? "Sales Discount" : "Sales Net Price"}
                                        variant="outlined"
                                        name="sales_net_price"
                                        type="text"
                                        size={mq_xs ? 'small' : 'medium'}
                                        required={type === 'standard'}
                                        error={!inputValue}
                                        value={inputValue}
                                        onChange={handleChange}
                                        readOnly={readonly}
                                        disabled={readonly}
                                    />
                                    {(type === 'standard' && !readonly) && <div style={{ display: 'flex' }}>
                                        <Box ml={1}>
                                            <Tooltip title="Show sales manual discount">
                                                <IconButton onClick={() => { handleEuroPerc(false) }}>
                                                    <Icon color="primary">
                                                        <i className="material-icons-outlined">percent</i>
                                                    </Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                        <Box ml={1}>
                                            <Tooltip title="Show sales net price">
                                                <IconButton onClick={() => { handleEuroPerc(true) }}>
                                                    <Icon color="primary">
                                                        <i className="material-icons-outlined">euro</i>
                                                    </Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </div>}
                                </Box>
                                <Alert severity="info">
                                    <Typography variant="body2" color="inherit">
                                        {(sales_net_price) > (data?.sdd - 0.01) ? 'Grid discount' : 'Agreed net price'}
                                        {/* {auth_level === 3 ? 'Agreed net price' : 'Grid discount'} */}
                                    </Typography>
                                </Alert>

                            </Box>

                            <Box mt={2}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell classes={{ root: styles.FirstColumn }} >
                                                <Typography variant="body1" color="primary">
                                                    {' Sales Net Price'}

                                                </Typography>
                                            </TableCell>
                                            <TableCell classes={{ root: styles.TableCell }}>
                                                <Typography variant="body1" color="primary">
                                                    {formatPriceValue(calculatedDiscount?.sales_net_price)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell classes={{ root: styles.FirstColumn }}>
                                                <Typography variant="body1" color="primary">
                                                    Final discount
                                                </Typography>
                                            </TableCell>
                                            <TableCell classes={{ root: styles.TableCell }}>
                                                <Typography variant="body1" color="primary">

                                                    {finalDiscount}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        {country !== 'BE'
                                            ? <>
                                                <TableRow>
                                                    <TableCell classes={{ root: styles.FirstColumn }}>
                                                        <Typography variant="body1" color="primary">
                                                            Final discount VS {country === 'IT' ? 'Grid discount' : 'Customer type limit'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell classes={{ root: styles.TableCell }}>
                                                        <Typography variant="body1" color="primary">
                                                            {formatPercentage(calculatedDiscount?.final_discount_grid)}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell classes={{ root: styles.FirstColumn }}>
                                                        <Typography variant="body1" color="primary">
                                                            Final discount VS {country === 'IT' ? 'Cluster manager' : 'CU maximum'} limit
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell classes={{ root: styles.TableCell }}>
                                                        <Typography variant="body1" color="primary">
                                                            {formatPercentage(calculatedDiscount?.final_discount_cluster)}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                            : null
                                        }
                                    </TableBody>
                                </Table>
                            </Box>

                        </CardContent>
                    </Card>

                </Box>

            </Container>
        </>
    )

}

export default PercentageBox;