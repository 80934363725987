import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { BuildOutlined, NewReleasesOutlined } from '@material-ui/icons';
import React from 'react';

const SpecsList = (props) => {

   

    const list = props.data
        ?   props.allSpecs
            ?   props.data
            :   props.data.filter(s => s.is_important)
        : null

    return (
        <React.Fragment> 
            
            <List >
                {list
                    ? list.map((s, i) => {
                        return (
                            <>
                            
                            <React.Fragment key={i}>
                                                         
                                <ListItem  divider>
                                    {s.is_important
                                        ?   <ListItemIcon>
                                                <NewReleasesOutlined color="secondary" />
                                            </ListItemIcon>
                                        :   null
                                    }
                                    {s.is_configuration
                                        ?   <ListItemIcon>
                                                <BuildOutlined color="primary" />
                                            </ListItemIcon>
                                        :   null
                                    }
                                    <ListItemText
                                        inset={s.is_configuration || s.is_important ? false : true}
                                        primary={s.spec_label}
                                        secondary={s.spec_value} 
                                    />
                                </ListItem>
                            </React.Fragment></>
                        )
                    })
                    : null
                }
            </List>
        </React.Fragment>
    );

}

export default SpecsList;