import React from 'react';
import { LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    bar: {
        backgroundColor: '#002b49'
    },
    bg: {
        backgroundColor: '#bfcad1'
    },
    barLight: {
        backgroundColor: theme.palette.common.white
    },
    bgLight: {
        backgroundColor: theme.palette.standard.light
    }

}));

const LoadingBar = (props) => {

    const style = useStyles();

    return (
        <LinearProgress variant="indeterminate" classes={{bar: props.light ? style.barLight : style.bar, indeterminate: props.light ? style.bgLight : style.bg}}/>
    )

}

export default LoadingBar;