import React, { useState } from 'react';
import { Box, Container, Card, CardContent } from '@material-ui/core';
import SectionTitle from '../../../../../../../components/UI/SectionTitle/SectionTitle';
import { useMqXS } from '../../../../../../../utils/Utils';
import { makeStyles, Table, TableBody, TableRow, TableCell, Typography, Checkbox, TextField, Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux';
import { SET_APPLICATION_INFO, SET_SPECIAL_APPLICATION } from '../../../../../../../store/actions/actions';
import { useGeneratedModelCode, useCheckAuthorization } from '../../../../../../../utils/Utils';
import { testFields, convertValue, validationTypes } from '../../../../../../../utils/validationTypes';



const useStyles = makeStyles(theme => ({
    FirstColumn: {
        width: "33.333%",
        borderRight: `1px solid ${theme.palette.primary25.main}`,
        [theme.breakpoints.down('sm')]: {
            width: '50%',
            padding: theme.spacing(1)
        }
    },
    TableCell: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1)
        }
    }
}));

const ProductApplicationBox = ({ readonly, validation }) => {

    const mq_xs = useMqXS()
    const styles = useStyles();
    const dispatch = useDispatch();
    const applicationInfo = useSelector(state => {
        return state.configuration.applicationInfo;
    });

    const [localApplicationInfo, setLocalApplicationInfo] = useState(applicationInfo || {})

    const existing_code = useSelector(state => {
        return state?.configuration?.discount?.existing_code
    });


    const material_selected = useSelector(state => {
        return state.productSelector.materialSelected.description;
    });

    const model_code = useGeneratedModelCode();

    // const auth_code = useCheckAuthorization();
    const auth_code = 0;


    const updateProductApplication = (name, value) => dispatch({ type: SET_APPLICATION_INFO, key: name, value: value });


    const handleChange = (e) => {

        const datatype = e.target.dataset ? e.target.dataset.valuetype : null;
        const value = e.target.value //convertValue(e.target.value, datatype);
        let copyObj = { ...localApplicationInfo }
        copyObj[e.target.name] = value;
        updateProductApplication(e.target.name, parseInt(value, 10));
        setLocalApplicationInfo(copyObj)

        // switch (e.target.name) {
        //     case 'min_order_qty':
        //     case 'est_annual_qty':

        //         // testFields mi restituisce il valore 'error' ? true / false
        //         if (value && testFields(validationTypes.intOptional, value)) return;

        //         copyObj[e.target.name] = value;
        //         updateProductApplication(e.target.name, value);
        //         setLocalApplicationInfo(copyObj)



        //         break;

        //     default:

        //         updateProductApplication(e.target.name, e.target.value);
        //         copyObj[e.target.name] = e.target.value;
        //         setLocalApplicationInfo(copyObj)

        //         break;
        // }
    }






    const handleClick = (e) => {
        let copyObj = { ...localApplicationInfo }
        updateProductApplication(e.target.name, e.target.checked);
        copyObj[e.target.name] = e.target.checked;
        setLocalApplicationInfo(copyObj)


    }

    return (
        <Container disableGutters={!mq_xs}>
            <Box py={2} px={mq_xs ? 0 : 2}>

                <SectionTitle title={mq_xs
                    ? "Product info"
                    : "Product application & additional info"} />

                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell
                                classes={{ root: styles.FirstColumn }}
                                component="th"
                                scope="row"
                            >
                                <Typography color="primary" variant="body1">
                                    Military
                                </Typography>
                            </TableCell>
                            <TableCell classes={{ root: styles.TableCell }}>
                                <Checkbox
                                    color="primary"
                                    name="is_military"
                                    checked={localApplicationInfo?.is_military}
                                    onChange={handleClick}
                                    disabled={readonly}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                classes={{ root: styles.FirstColumn }}
                                component="th"
                                scope="row"
                            >
                                <Typography color="primary" variant="body1">
                                    Nuclear
                                </Typography>
                            </TableCell>
                            <TableCell classes={{ root: styles.TableCell }}>
                                <Checkbox
                                    color="primary"
                                    name="is_nuclear"
                                    checked={localApplicationInfo?.is_nuclear}
                                    onChange={handleClick}
                                    disabled={readonly}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                classes={{ root: styles.FirstColumn }}
                                component="th"
                                scope="row"
                            >
                                <Typography color="primary" variant="body1">
                                    Aerospace
                                </Typography>
                            </TableCell>
                            <TableCell classes={{ root: styles.TableCell }}>
                                <Checkbox
                                    color="primary"
                                    name="is_aerospace"
                                    checked={localApplicationInfo?.is_aerospace}
                                    onChange={handleClick}
                                    disabled={readonly}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Box mt={2}>

                    <Grid container spacing={4}>

                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                inputProps={{
                                    "data-valuetype": "number",
                                }}
                                label="Minimum order quantity"
                                variant="outlined"
                                name="min_order_qty"
                                fullWidth
                                type="number"
                                size={mq_xs ? 'small' : 'medium'}
                                value={localApplicationInfo?.min_order_qty ?? ''}
                                onChange={handleChange}
                                readOnly={readonly}
                                disabled={readonly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                inputProps={{
                                    "data-valuetype": "number",
                                }}
                                label="Estimated annual quantity"
                                variant="outlined"
                                name="est_annual_qty"
                                required={validation?.required}
                                fullWidth
                                type="number"
                                size={mq_xs ? 'small' : 'medium'}
                                value={localApplicationInfo?.est_annual_qty ?? ''}
                                error={validation?.required && !localApplicationInfo?.est_annual_qty}
                                onChange={handleChange}
                                readOnly={readonly}
                                disabled={readonly}
                            />
                        </Grid>
                    </Grid>

                </Box>

            </Box>
        </Container>
    )

}

export default ProductApplicationBox;