import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Hidden, IconButton, makeStyles, Typography, Menu, MenuItem, ListItemIcon } from '@material-ui/core';
import { CloseOutlined, SearchOutlined } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MATERIAL_MODAL, SET_MATERIAL_SELECTED, RESET_COMPARE_SLOTS } from '../../store/actions/ProductSelector/actions';
import ProductDetails from './Sections/ProductDetails/ProductDetails';
import ProductsLists from './Sections/ProductsLists/ProductsLists';
import ProductCompare from './Sections/ProductCompare/ProductCompare';
import ProductDetailsMobileActions from './Sections/ProductDetails/MobileActions/ProductDetailsMobileActions';
import { useMqXS } from '../../utils/Utils';
import { useAxios } from '../../utils/useAxios';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(8),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
            height: theme.spacing(7),
        }
    },
    dialogContent: {
        padding: 0
    },
    DialogActions: {
        padding: 0,
        display: 'inline-block'
    },
    divider: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const ProductSelector = ({ modelCode }) => {

    const styles = useStyles();
    const axios = useAxios();
    const mq_xs = useMqXS();


    const storeProductSelectorMaterial = useSelector(state => {
        return state.productSelector.material
    });

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenuPS = Boolean(anchorEl);

    const [allSpecs, setAllSpecs] = useState(false);
    const [currentSection, setCurrentSection] = useState(0);

    const [listType, setListType] = useState('standard');
    const [standard, setStandard] = useState(0);
    const [goto, setGoto] = useState(0);
    const [customer, setCustomer] = useState(0);



    const dispatch = useDispatch();

    const setMaterialModal = (objData) => dispatch(
        {
            type: SET_MATERIAL_MODAL,
            code: objData.code,
            class: objData.class,
            description: objData.description,
            specsList: objData.specsList
        }
    );
    const setMaterialSelected = (objData) => dispatch({ type: SET_MATERIAL_SELECTED, code: objData.code, description: objData.description });

    const handleOpenMenuPS = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleCloseMenuPS = () => {
        setAnchorEl(null);
    }

    const handleClose = () => {
        setOpen(false);
        handleClickBackToList();
    }

    const handleClickBackToList = () => {
        setCurrentSection(0);
        setMaterialModal(
            {
                code: null,
                class: null,
                description: null,
                specsList: null
            }
        );
    }

    const handleClickAllSpecs = () => {
        setAllSpecs(!allSpecs);
    }

    const handleClickSelectMaterial = () => {
        setMaterialSelected({ code: storeProductSelectorMaterial.code, description: storeProductSelectorMaterial.description });
        handleClose();
    }

    const handleOpen = (type) => {
        setListType(type);
        setOpen(true);
        handleCloseMenuPS();
    }

    const changeSection = (section) => {

        setCurrentSection(section);

    }

    const changeList = (type) => {
        setListType(type);
    }

    useEffect(() => {

        if (modelCode) {

            const body = {
                material_desc: modelCode
            }

            axios.get("productselector/GetProductTypeCount", { params: body })
                .then((response) => {

                    setStandard(response.data.count_standard);
                    setGoto(response.data.count_go_to);
                    setCustomer(response.data.count_customer);

                })
                .catch(error => {
                    console.log(error);
                });

        }

    }, [modelCode]);

    return (
        <React.Fragment>
            {mq_xs
                ? <IconButton
                    color="secondary"
                    onClick={handleOpenMenuPS}
                >
                    <SearchOutlined />
                </IconButton>
                : <Box display={'flex'} alignItems={'center'}>
                    <Box mr={2}>
                        <Button
                            disabled={standard === 0}
                            color="secondary"
                            variant="contained"
                            onClick={() => handleOpen('standard')}
                        >
                            {standard} Standard
                        </Button>
                    </Box>
                    <Box mr={2}>
                        <Button
                            disabled={goto === 0}
                            color="secondary"
                            variant="contained"
                            onClick={() => handleOpen('goTo')}
                        >
                            {goto} Go To
                        </Button>
                    </Box>
                    <Box mr={1}>
                        <Button
                            disabled={customer === 0}
                            color="secondary"
                            variant="contained"
                            onClick={() => handleOpen('customer')}
                        >
                            {customer} Customer
                        </Button>
                    </Box>
                </Box>
            }

            <Menu
                id="menu-product-selector"
                anchorEl={anchorEl}
                open={openMenuPS}
                onClose={handleCloseMenuPS}
                PaperProps={{
                    style: {
                        maxHeight: 48 * 8
                    }
                }}
            >
                <MenuItem
                    disabled={standard === 0}
                    onClick={() => handleOpen('standard')}
                >
                    <ListItemIcon>
                        <Typography variant='body1' color="primary">{standard}</Typography>
                    </ListItemIcon>
                    <Typography variant='body1' color="primary">Standard</Typography>
                </MenuItem>
                <MenuItem
                    disabled={goto === 0}
                    onClick={() => handleOpen('goTo')}
                >
                    <ListItemIcon>
                        <Typography variant='body1' color="primary">{goto}</Typography>
                    </ListItemIcon>
                    <Typography variant='body1' color="primary">Go To</Typography>
                </MenuItem>
                <MenuItem
                    disabled={customer === 0}
                    onClick={() => handleOpen('customer')}
                >
                    <ListItemIcon>
                        <Typography variant='body1' color="primary">{customer}</Typography>
                    </ListItemIcon>
                    <Typography variant='body1' color="primary">Customer</Typography>
                </MenuItem>
            </Menu>

            <Dialog
                maxWidth="md"
                fullWidth
                fullScreen
                open={open}
                onClose={handleClose}
            >
                <DialogTitle classes={{ root: styles.dialogTitle }} disableTypography>
                    <Typography color="inherit" variant="h6">Product Selector</Typography>
                    <Box ml={'auto'}>
                        <IconButton color="inherit" onClick={handleClose}>
                            <CloseOutlined onClick={() => { dispatch({ type: RESET_COMPARE_SLOTS }) }} />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent classes={{ root: styles.dialogContent }} dividers>

                    {currentSection === 0
                        ? <ProductsLists
                            modelCode={modelCode}
                            changeSection={changeSection}
                            standard={standard}
                            goto={goto}
                            customer={customer}
                            listType={listType}
                            changeList={changeList}
                        />
                        : null
                    }
                    {currentSection === 1
                        ? <ProductDetails
                            listType={listType}
                            backToList={handleClickBackToList}
                            allSpecs={allSpecs}
                            toggleAllSpecs={handleClickAllSpecs}
                            selectMaterial={handleClickSelectMaterial}
                            materialPDFData={storeProductSelectorMaterial}
                        />
                        : null
                    }
                    {currentSection === 2
                        ? <ProductCompare
                            allSpecs={allSpecs}
                            toggleAllSpecs={handleClickAllSpecs}
                            backToList={handleClickBackToList}
                            closeModal={handleClose}
                        />
                        : null
                    }
                </DialogContent>
                <Hidden smUp>
                    {currentSection === 1
                        ?
                        <DialogActions classes={{ root: styles.DialogActions }}>
                            <ProductDetailsMobileActions
                                backToList={handleClickBackToList}
                                allSpecs={allSpecs}
                                toggleAllSpecs={handleClickAllSpecs}
                                selectMaterial={handleClickSelectMaterial}
                                materialPDFData={storeProductSelectorMaterial}
                            />
                        </DialogActions>
                        : null
                    }

                </Hidden>
            </Dialog>
        </React.Fragment>
    )

}

export default ProductSelector;